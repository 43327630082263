/* eslint-disable @typescript-eslint/no-use-before-define */
import forIn from "lodash/forIn";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";
import { TFunction } from "i18next";

import isArray from "lodash/isArray";
import * as yup from "yup";
import { DurationUnit } from "luxon";
import {
  GroupName,
  IProperty,
  Kind,
  SchemaFieldType,
  SubGroupName,
} from "./models";
import { convertDuration } from "../../../utils/utility";

export type ServerValidationConfig = {
  type: string;
  default?: any;
  minimum?: number;
  maximum?: number;
  minItems?: number;
  properties?: Record<string, ServerValidationConfig>;
  items?: ServerValidationConfig;
};

type YupValidationSpec = {
  type: any;
  params: any[];
};

export const getSchemaFieldType = (type: SchemaFieldType): SchemaFieldType => {
  // Bypass non-null wrapper
  if (type && type.kind === Kind.NON_NULL) {
    return getSchemaFieldType(type.ofType as SchemaFieldType);
  }

  return type;
};

export const getSettingsByGroup = (
  properties: IProperty[],
): Map<GroupName, Map<SubGroupName, IProperty[]>> => {
  const settings = new Map<GroupName, Map<SubGroupName, IProperty[]>>();
  properties.forEach((p: IProperty) => {
    const { group, subGroup } = p;

    const groupKey = group || "";
    const subGroupKey = subGroup || "";
    if (!settings.has(groupKey)) {
      settings.set(groupKey, new Map<SubGroupName, IProperty[]>());
    }

    const groupSettings = settings.get(groupKey);
    if (groupSettings && !groupSettings.has(subGroupKey)) {
      groupSettings.set(subGroupKey, []);
    }

    const subGroupSettings = groupSettings?.get(subGroupKey);
    if (subGroupSettings) {
      subGroupSettings.push(p);
    }
  });

  return settings;
};

export const getFieldsByInputObjectName = (
  properties: IProperty[],
  name: string,
) => {
  return properties.filter(
    // TODO: remove i.show !== "FALSE"
    // temporary solution to hide aos config settings on schedule profile page
    (i) => i.inputObjectName === name && i.show !== "FALSE",
  );
};

export const getFieldsByNames = (properties: IProperty[], names: string[]) => {
  const nameSet = new Set(names);
  return properties.filter((i) => nameSet.has(i.key));
};

const parseProperty = (
  t: TFunction,
  key: string,
  value: ServerValidationConfig,
  propertyList: Set<string>,
  transformDataRules: Record<string, TransformDataRule>,
  ignoreMinMaxRule = false,
  parent?: string,
) => {
  const validations: YupValidationSpec[] = [];
  const { type, minimum, maximum, properties: objectProperties, items } = value;

  const id = parent == null ? camelCase(key) : key;
  const path = parent == null ? id : `${parent}.${key}`;

  const label = startCase(key);

  switch (type) {
    case "object": {
      if (objectProperties) {
        const nestedProperties = parseSchemaProperties(
          t,
          objectProperties,
          propertyList,
          transformDataRules,
          ignoreMinMaxRule,
          path,
        );
        return yup.object(nestedProperties.schemaObject);
      }
      break;
    }
    case "array": {
      let arrayOf;
      if (items) {
        const { properties: nestedProperties, type: nestedType } = items;
        if (nestedType === "object") {
          // array of objects

          arrayOf = yup.object();
          if (nestedProperties) {
            const nestedSchemaProperties = parseSchemaProperties(
              t,
              nestedProperties,
              propertyList,
              transformDataRules,
              ignoreMinMaxRule,
              path,
            );
            arrayOf = arrayOf.shape(nestedSchemaProperties.schemaObject);
          }
        } else {
          // array of primitive types
          arrayOf = parseProperty(
            t,
            id,
            items,
            propertyList,
            transformDataRules,
            ignoreMinMaxRule,
            parent,
          );
        }

        validations.push({
          type: "of",
          params: [arrayOf],
        });
      }
      break;
    }
    default: {
      const transformDataRule = transformDataRules[path];
      let from: DurationUnit | undefined;
      let to: DurationUnit | undefined;

      validations.push({
        type: "label",
        params: [label],
      });

      if (transformDataRule) {
        const { maxReference } = transformDataRule;
        from = transformDataRule.from;
        to = transformDataRule.to;

        if (maxReference) {
          validations.push({
            type: "maxIfSet",
            params: [
              maxReference,
              (validationSchema: any) => {
                const { label: schemaLabel, max } = validationSchema;
                return t("translation:form.validations.moreThanOrEqualTo", {
                  property: schemaLabel || label,
                  minValue: convertDuration(max, from, to),
                });
              },
            ],
          });
        }
      }

      if (!ignoreMinMaxRule && minimum != null) {
        validations.push({
          type: "min",
          params: [
            minimum,
            (validationSchema: any) => {
              const { label: schemaLabel, min } = validationSchema;
              return t("translation:form.validations.moreThanOrEqualTo", {
                property: schemaLabel || label,
                minValue: convertDuration(min, from, to),
              });
            },
          ],
        });
      }

      if (!ignoreMinMaxRule && maximum != null) {
        validations.push({
          type: "max",
          params: [
            maximum,
            (validationSchema: any) => {
              const { label: schemaLabel, max } = validationSchema;
              return t("translation:form.validations.lessThanOrEqualTo", {
                property: schemaLabel || label,
                maxValue: convertDuration(max, from, to),
              });
            },
          ],
        });
      }
    }
  }

  return createYupSchema({
    id,
    validationType: type,
    validations,
    t,
  });
};

export type SchemaResult = {
  schemaObject: Record<string, any>;
  defaults: Record<string, any>;
};

export type TransformDataRule = {
  from?: DurationUnit;
  to?: DurationUnit;
  maxReference?: yup.Ref;
};

export const parseSchemaProperties = (
  t: TFunction,
  schemaProperties: Record<string, ServerValidationConfig>,
  propertyList: Set<string>,
  transformDataRules: Record<string, TransformDataRule> = {},
  ignoreMinMaxRule = false,
  parent?: string,
): SchemaResult => {
  // add extra rules from schema
  const schemaObject: Record<string, any> = {};
  const defaults: Record<string, any> = {};
  forIn(
    schemaProperties || {},
    (value: ServerValidationConfig, key: string) => {
      const id = parent == null ? camelCase(key) : key;
      if (parent != null || propertyList.has(id)) {
        const validator = parseProperty(
          t,
          key,
          value,
          propertyList,
          transformDataRules,
          ignoreMinMaxRule,
          parent,
        );
        if (validator != null) {
          schemaObject[id] = validator;
          const { default: defaultValue } = value;

          // only parse default value at top level (not nested)
          if (parent == null || defaultValue != null) {
            if (propertyList.has(id)) {
              defaults[id] = defaultValue;
            }
          }
        }
      }
    },
  );

  return { schemaObject, defaults };
};

const createYupSchema = (config: {
  id: string;
  validationType: string | string[];
  validations: YupValidationSpec[];
  t: TFunction;
}) => {
  const { validationType, validations = [], t } = config;
  let validationTypeString;

  const nullType = "null";
  let nullIndex = 0;

  // if there are more than one type, assume ie. ["array", null]
  if (isArray(validationType)) {
    const validationTypes = validationType as string[];
    nullIndex = validationTypes.findIndex((i) => i === nullType);

    if (nullIndex !== -1) {
      // property is nullable
      validations.push({
        type: "nullable",
        params: [],
      });
    }

    // get the first non-null type (ignore the rest)
    validationTypeString = validationTypes.find((i) => i !== nullType) || "";
  } else {
    validationTypeString = validationType;
    validations.push({
      type: "typeError",
      params: [
        (validationSchema: any) => {
          const { label, path } = validationSchema;
          return t("translation:form.validations.invalidType", {
            property: label || path,
          });
        },
      ],
    });

    if (validationTypeString !== "array") {
      validations.push({
        type: "required",
        params: [
          (validationSchema: any) => {
            const { label, path } = validationSchema;
            return t("translation:form.validations.required", {
              property: label || path,
            });
          },
        ],
      });
    }
  }

  const validatorFunction = (yup as any)[validationTypeString];
  if (!validatorFunction) {
    return null;
  }

  let validator = validatorFunction();

  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });

  return validator;
};

export function isValidYupJson(val: any, nullable = true) {
  // Backend defaults json fields to null sometimes (shown as {} in the UI, so you don't necessarily know if it's null or {} when eyeballing it)
  if (val == null) {
    return nullable;
  }

  return typeof val === "object";
}

export function isValidSingleLevelJson(
  val: any,
  keyType: "number" | "string" | "boolean" | undefined = undefined,
  keysNullable = true,
  nullable = true,
) {
  if (val == null) {
    return nullable;
  }
  return (
    val != null &&
    Object.values(val).every(
      // eslint-disable-next-line valid-typeof
      (objValue) => {
        if (objValue == null && keysNullable) {
          return true;
        }
        switch (keyType) {
          case "number":
            return typeof objValue === "number";
          case "string":
            return typeof objValue === "string";
          case "boolean":
            return typeof objValue === "boolean";
          default:
            return typeof objValue !== "object";
        }
      },
    )
  );
}
