/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type FixedShiftRestrictionEnum = "not_applicable" | "optional" | "required" | "%future added value";
export type EmploymentTypeConfigUpdateInput = {
    code?: string | null | undefined;
    deleted?: boolean | null | undefined;
    fixedShiftRestriction?: FixedShiftRestrictionEnum | null | undefined;
    gmhEnabled?: boolean | null | undefined;
    gmhMinimumDurationError?: unknown | null | undefined;
    gmhMinimumDurationWarning?: unknown | null | undefined;
    isDefault?: boolean | null | undefined;
    name?: string | null | undefined;
    shortName?: string | null | undefined;
    timeOffComplianceEnabled?: boolean | null | undefined;
};
export type EmploymentTypesQueries_UpdateEmploymentTypeConfig_MutationVariables = {
    businessId: string;
    id: string;
    input: EmploymentTypeConfigUpdateInput;
};
export type EmploymentTypesQueries_UpdateEmploymentTypeConfig_MutationResponse = {
    readonly updateEmploymentTypeConfig: {
        readonly id: string;
        readonly code: string | null;
        readonly deleted: boolean;
        readonly employmentTypeCode: string;
        readonly fixedShiftRestriction: FixedShiftRestrictionEnum;
        readonly gmhEnabled: boolean;
        readonly gmhMinimumDurationError: unknown | null;
        readonly gmhMinimumDurationWarning: unknown | null;
        readonly isDefault: boolean;
        readonly name: string;
        readonly shortName: string;
        readonly smallId: number;
        readonly timeClockName: string;
        readonly timeOffComplianceEnabled: boolean;
    };
};
export type EmploymentTypesQueries_UpdateEmploymentTypeConfig_Mutation = {
    readonly response: EmploymentTypesQueries_UpdateEmploymentTypeConfig_MutationResponse;
    readonly variables: EmploymentTypesQueries_UpdateEmploymentTypeConfig_MutationVariables;
};



/*
mutation EmploymentTypesQueries_UpdateEmploymentTypeConfig_Mutation(
  $businessId: ID!
  $id: ID!
  $input: EmploymentTypeConfigUpdateInput!
) {
  updateEmploymentTypeConfig(businessId: $businessId, id: $id, input: $input) {
    id
    code
    deleted
    employmentTypeCode
    fixedShiftRestriction
    gmhEnabled
    gmhMinimumDurationError
    gmhMinimumDurationWarning
    isDefault
    name
    shortName
    smallId
    timeClockName
    timeOffComplianceEnabled
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmploymentTypeConfig",
    "kind": "LinkedField",
    "name": "updateEmploymentTypeConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentTypeCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fixedShiftRestriction",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gmhEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gmhMinimumDurationError",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gmhMinimumDurationWarning",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDefault",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "smallId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeClockName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeOffComplianceEnabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentTypesQueries_UpdateEmploymentTypeConfig_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentTypesQueries_UpdateEmploymentTypeConfig_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fa0ac2c2b17ec6305d342960f48b0e43",
    "id": null,
    "metadata": {},
    "name": "EmploymentTypesQueries_UpdateEmploymentTypeConfig_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmploymentTypesQueries_UpdateEmploymentTypeConfig_Mutation(\n  $businessId: ID!\n  $id: ID!\n  $input: EmploymentTypeConfigUpdateInput!\n) {\n  updateEmploymentTypeConfig(businessId: $businessId, id: $id, input: $input) {\n    id\n    code\n    deleted\n    employmentTypeCode\n    fixedShiftRestriction\n    gmhEnabled\n    gmhMinimumDurationError\n    gmhMinimumDurationWarning\n    isDefault\n    name\n    shortName\n    smallId\n    timeClockName\n    timeOffComplianceEnabled\n  }\n}\n"
  }
};
})();
(node as any).hash = '9f4203ef83c76198b5e0c25d6f2a7bb5';
export default node;
