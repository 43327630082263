import { Environment, fetchQuery, graphql } from "react-relay";
import { Id } from "../../../data/models/common";
import {
  EmploymentService_EmploymentsSearch_Query,
  EmploymentService_EmploymentsSearch_QueryResponse,
  EmploymentSort,
} from "./__generated__/EmploymentService_EmploymentsSearch_Query.graphql";

const employmentsSearch = graphql`
  query EmploymentService_EmploymentsSearch_Query(
    $businessId: ID!
    $search: String
    $pageSize: Int
    $sort: [EmploymentSort!]
  ) {
    employments(
      businessId: $businessId
      search: $search
      first: $pageSize
      sort: $sort
    ) {
      edges {
        node {
          id
          computedName
        }
      }
    }
  }
`;

type EdgeType =
  EmploymentService_EmploymentsSearch_QueryResponse["employments"]["edges"];
export type EmploymentSearchResult = EdgeType[number]["node"];

export class EmploymentService {
  /**
   * search for employment by name
   */
  public static async searchEmployment(
    environment: Environment,
    businessId: Id,
    search: string,
    pageSize = 100,
    sort: EmploymentSort[] = [{ field: "firstName", order: "asc" }],
  ): Promise<Map<Id, EmploymentSearchResult> | null> {
    const result = await fetchQuery<EmploymentService_EmploymentsSearch_Query>(
      environment,
      employmentsSearch,
      {
        businessId,
        search,
        pageSize,
        sort,
      },
    ).toPromise();

    if (!result || !result.employments || !result.employments.edges) {
      return null;
    }

    const map = new Map();
    result.employments.edges.forEach((i) => {
      if (i && i.node) {
        map.set(i.node.id, i.node);
      }
    });

    return map;
  }
}
