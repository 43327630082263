/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type KpiComponentsQueries_GetKpiTargets_InternalQueryVariables = {
    businessId: string;
};
export type KpiComponentsQueries_GetKpiTargets_InternalQueryResponse = {
    readonly setValues: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly code: string;
            readonly createdAt: string;
            readonly description: string;
            readonly details: unknown | null;
            readonly displayOrder: number;
            readonly setName: {
                readonly createdAt: string;
                readonly description: string;
                readonly id: string;
                readonly name: string;
                readonly updatedAt: string;
            };
            readonly setNameId: string;
            readonly updatedAt: string;
        }>;
    };
};
export type KpiComponentsQueries_GetKpiTargets_InternalQuery = {
    readonly response: KpiComponentsQueries_GetKpiTargets_InternalQueryResponse;
    readonly variables: KpiComponentsQueries_GetKpiTargets_InternalQueryVariables;
};



/*
query KpiComponentsQueries_GetKpiTargets_InternalQuery(
  $businessId: ID!
) {
  setValues(businessId: $businessId) {
    nodes {
      id
      code
      createdAt
      description
      details
      displayOrder
      setName {
        createdAt
        description
        id
        name
        updatedAt
      }
      setNameId
      updatedAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      }
    ],
    "concreteType": "SetValueConnection",
    "kind": "LinkedField",
    "name": "setValues",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SetValue",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "details",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SetName",
            "kind": "LinkedField",
            "name": "setName",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "setNameId",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KpiComponentsQueries_GetKpiTargets_InternalQuery",
    "selections": (v5/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KpiComponentsQueries_GetKpiTargets_InternalQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "5059c1defcc2001e376e9085497a2244",
    "id": null,
    "metadata": {},
    "name": "KpiComponentsQueries_GetKpiTargets_InternalQuery",
    "operationKind": "query",
    "text": "query KpiComponentsQueries_GetKpiTargets_InternalQuery(\n  $businessId: ID!\n) {\n  setValues(businessId: $businessId) {\n    nodes {\n      id\n      code\n      createdAt\n      description\n      details\n      displayOrder\n      setName {\n        createdAt\n        description\n        id\n        name\n        updatedAt\n      }\n      setNameId\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9e86117b8c63aa934d35b8048320fec4';
export default node;
