/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentFeedbackTypeEnum = "commendation" | "disciplinary_action" | "%future added value";
export type FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutationVariables = {
    id: string;
    businessId: string;
};
export type FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutationResponse = {
    readonly deactivateEmploymentFeedbackReason: {
        readonly description: string;
        readonly defaultMessage: string | null;
        readonly deleted: boolean;
        readonly feedbackType: EmploymentFeedbackTypeEnum;
        readonly id: string;
    };
};
export type FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutation = {
    readonly response: FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutationResponse;
    readonly variables: FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutationVariables;
};



/*
mutation FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutation(
  $id: ID!
  $businessId: ID!
) {
  deactivateEmploymentFeedbackReason(id: $id, businessId: $businessId) {
    description
    defaultMessage
    deleted
    feedbackType
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "EmploymentFeedbackReason",
    "kind": "LinkedField",
    "name": "deactivateEmploymentFeedbackReason",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultMessage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feedbackType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2dbc5f3d405bf15c0422758230772360",
    "id": null,
    "metadata": {},
    "name": "FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutation",
    "operationKind": "mutation",
    "text": "mutation FeedbackReasonsQueries_DeactivateEmploymentFeedbackReasonMutation(\n  $id: ID!\n  $businessId: ID!\n) {\n  deactivateEmploymentFeedbackReason(id: $id, businessId: $businessId) {\n    description\n    defaultMessage\n    deleted\n    feedbackType\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '4ce83bfe831b535e10de97f9971dad6f';
export default node;
