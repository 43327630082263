import React, { useState } from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { PreloadedQuery, useMutation, usePreloadedQuery } from "react-relay";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../common/Portal/HeaderPortal";
import {
  LayoutContextType,
  useScheduleBusinessContextCheck,
} from "../../../../hooks/useScheduleBusinessContextCheck";
import {
  CreateBusinessBreakTypeMutation,
  CreateScheduleBreakTypeMutation,
  DeactivateBreakTypeMutation,
  GetSingleBusinessBreakTypeQuery,
  GetSingleScheduleBreakTypeQuery,
  ReactivateBreakTypeMutation,
  RemoveScheduleBreakTypeOverrideMutation,
  UpdateBusinessBreakTypeMutation,
  UpdateScheduleBreakTypeMutation,
} from "../BreakTypeQueries";
import {
  BreakType,
  EmploymentTypeConfig,
  LegacyBreakTypeEnum,
  ScheduleBreakType,
} from "../../../../data/generated/stack_internal_schema";
import FormLayout from "../../../common/Form/FormLayout";
import {
  BooleanFieldType,
  ComponentRule,
  IProperty,
  legacyBreakTypeOptions,
} from "../../../common/Form/models";
import DynamicInputGroup from "../../../common/Form/DynamicInputGroup";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../../common/Form/formUtilities";
import properties from "../../../../data/csv-settings/break-type-settings.json";
import FormLayoutFooter from "../../../common/Form/FormLayoutFooter";
import MultiSelectList, {
  MultiSelectType,
} from "../../../common/Form/MultiSelectList";
import Field from "../../../common/Form/Field";
import DynamicSelect from "../../../common/Form/DynamicSelect";
import Switch from "../../../common/Form/Switch";
import { useModal } from "../../../../contexts/ModalContext";
import { ConfirmationModal } from "../../../common/ConfirmationModal";
import { PaidThresholdRadioSelect } from "./PaidThresholdRadioSelect";
import { TimeRangeInput } from "../../../common/Form/TimeRangeInput";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import { GetEmploymentTypeConfigsQuery } from "../../EmploymentTypes/EmploymentTypesQueries";
import { EmploymentTypesQueries_GetEmploymentTypeConfigs_Query } from "../../EmploymentTypes/__generated__/EmploymentTypesQueries_GetEmploymentTypeConfigs_Query.graphql";
import Checkbox from "../../../common/Form/Checkbox";
import SelectionGroup from "../../../common/Form/SelectionGroup";

enum FormUpdateMethod {
  OverridingBreakType,
  UpdateBreakType,
  UpdateScheduleBreakType,
  CreateBreakType,
}

type Props = {
  breakTypeId: string | undefined;
  scheduleBreakTypeId: string | undefined;
  queryReferences: {
    breakTypes: PreloadedQuery<any>;
    scheduleBreakTypes: PreloadedQuery<any>;
    employmentTypeConfigs: PreloadedQuery<any>;
  };
};

export default function BreakTypesProfileForm({
  breakTypeId,
  scheduleBreakTypeId,
  queryReferences,
}: Props) {
  const { t } = useTranslation("break-types");
  const router = useAppRouter();
  const { showModal, hideModal } = useModal();
  const {
    type: contextType,
    businessId,
    scheduleId,
  } = useScheduleBusinessContextCheck();

  const breakTypesRootUrl = router.getGoBackUrl("/break_types");
  const isScheduleContext = contextType === LayoutContextType.Schedule;

  const [validationRules] = useState(
    yup.object({
      name: yup.string().required().label(t("form.property.name")),
      breakTypeCode: yup
        .string()
        .required()
        .label(t("form.property.breakTypeCode")),
      breakScreenOrdering: yup
        .number()
        .required()
        .label(t("form.property.breakScreenOrdering")),
      legacyBreakType: yup
        .string()
        .oneOf([
          LegacyBreakTypeEnum.Meal,
          LegacyBreakTypeEnum.Rest,
          LegacyBreakTypeEnum.Unknown,
        ])
        .required()
        .label(t("form.property.legacyBreakType")),

      // In the schedule context - paidThreshold can be null since null = business default
      paidThreshold: isScheduleContext
        ? yup
            .number()
            .min(0)
            .max(1440)
            .nullable(true)
            .label(t("form.property.paidThreshold"))
        : yup
            .number()
            .min(0)
            .max(1440)
            .required()
            .label(t("form.property.paidThreshold")),
    }),
  );

  const [breakType, formUpdateMethod, { breakType: businessBreakType }] =
    useBreakTypeData(
      queryReferences,
      breakTypeId,
      scheduleBreakTypeId,
      isScheduleContext,
    );

  const [employmentTypeConfigs] = useEmploymentTypeConfigsData(
    queryReferences.employmentTypeConfigs,
  );

  const [
    saveBreakType,
    { activateBreakType, deactivateBreakType, deleteScheduleBreakType },
  ] = useBreakTypeMutations(formUpdateMethod);

  const componentRules = useFormComponentRules(
    breakType,
    businessBreakType,
    isScheduleContext,
    formUpdateMethod,
    employmentTypeConfigs as EmploymentTypeConfig[],
  );

  const onSaveClick = (
    data: Partial<BreakType | ScheduleBreakType>,
    errorHandler: (error: Error) => void,
  ) => {
    // If we want to unset the availableRange (start & end time), we need to pass availableRange as null
    const removeAvailableRange =
      data.availableRange != null &&
      (!data.availableRange.rangeStartTime ||
        !data.availableRange.rangeEndTime);

    saveBreakType({
      variables: {
        businessId,
        ...((formUpdateMethod === FormUpdateMethod.UpdateBreakType ||
          formUpdateMethod === FormUpdateMethod.UpdateScheduleBreakType) && {
          id: breakType.id,
        }),
        input: {
          ...data,
          ...(removeAvailableRange && {
            availableRange: null,
          }),
          ...(formUpdateMethod === FormUpdateMethod.OverridingBreakType && {
            scheduleId,
            breakTypeId,
          }),
        },
      },

      onCompleted() {
        toast(
          formUpdateMethod === FormUpdateMethod.CreateBreakType
            ? t("toast.created")
            : t("toast.updated"),
        );
        router.replace(breakTypesRootUrl);
      },
      onError(error: Error) {
        errorHandler(error);
      },
    });
  };

  const onActivateDeactivateClick = (activate: boolean) => {
    const i18nKey = activate ? "activate" : "deactivate";
    const activationMutation = activate
      ? activateBreakType
      : deactivateBreakType;

    showModal(
      <ConfirmationModal
        onClose={hideModal}
        okClicked={() => {
          activationMutation({
            variables: {
              businessId,
              id: breakType.id,
            },
            onCompleted() {
              toast(t(`toast.${i18nKey}`));
              hideModal();
              router.replace(breakTypesRootUrl);
            },
            onError(error: Error) {
              alert(error);
            },
          });
        }}
        variant={activate ? "primary" : "danger"}
        title={t(`activateDeactivateModal.${i18nKey}.title`)}
        okText={t(`activateDeactivateModal.${i18nKey}.ok_button`)}
      >
        <Trans
          i18nKey={`break-types:activateDeactivateModal.${i18nKey}.body`}
          values={{ breakTypeName: breakType.name }}
          components={{ bold: <strong /> }}
        />
      </ConfirmationModal>,
    );
  };

  const onSetToDefaultClick = () => {
    showModal(
      <ConfirmationModal
        onClose={hideModal}
        okClicked={() => {
          deleteScheduleBreakType({
            variables: {
              businessId,
              id: breakType.id,
            },
            onCompleted() {
              toast(t("toast.setToDefault"));
              hideModal();
              router.replace(breakTypesRootUrl);
            },
            onError(error: Error) {
              alert(error);
            },
          });
        }}
        title={t("resetToDefaultModal.title")}
        okText={t("resetToDefaultModal.okText")}
      >
        <p>{t("resetToDefaultModal.body")}</p>
      </ConfirmationModal>,
    );
  };

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <Link to={breakTypesRootUrl}>
              <span>{t("nav.breakTypes")}</span>
            </Link>,
            <span>
              {breakTypeId ? breakType.name : t("form.create_title")}
            </span>,
          ]}
        />
      </HeaderPortal>

      <FormLayout<BreakType | ScheduleBreakType>
        isCreate={formUpdateMethod === FormUpdateMethod.CreateBreakType}
        base={breakType}
        onSave={onSaveClick}
        propertyList={properties as any}
        validationRules={validationRules}
        componentRules={componentRules}
      >
        {isScheduleContext && (
          <Row>
            <Field
              label={t("form.enableBreakType")}
              hideLabel
              md={6}
              lg={5}
              fieldKey="hidden"
              schemaFieldType={BooleanFieldType}
              component={Switch}
              componentProps={{
                boldLabels: true,
                // Slightly confusing, but we display this field as "Enable Break Type", but in the backend the flag is called 'hidden'
                toBoolean: (v: boolean) => !v,
                fromBoolean: (v: boolean) => !v,
              }}
            />
          </Row>
        )}
        <DynamicInputGroup
          fields={getSettingsByGroup(
            getFieldsByInputObjectName(
              properties as unknown as IProperty[],
              "BreakTypeCreateInput",
            ),
          )}
        />

        <FormLayoutFooter
          isCreate={formUpdateMethod === FormUpdateMethod.CreateBreakType}
          rightActionButtons={
            formUpdateMethod === FormUpdateMethod.UpdateScheduleBreakType
              ? {
                  before: true,
                  buttons: (
                    <Button
                      variant="secondary"
                      onClick={onSetToDefaultClick}
                      className="mr-2"
                    >
                      {t("form.setToDefault")}
                    </Button>
                  ),
                }
              : undefined
          }
          leftActionButtons={
            formUpdateMethod === FormUpdateMethod.UpdateBreakType
              ? {
                  before: true,
                  buttons: (
                    <Button
                      variant={breakType.deleted ? "primary" : "danger"}
                      onClick={() =>
                        onActivateDeactivateClick(breakType.deleted)
                      }
                      className="mr-2"
                    >
                      {breakType.deleted
                        ? t("form.activate")
                        : t("form.deactivate")}
                    </Button>
                  ),
                }
              : undefined
          }
        />
      </FormLayout>
    </>
  );
}

function useFormComponentRules(
  breakTypeData: ScheduleBreakType,
  businessBreakType: BreakType,
  isScheduleContext: boolean,
  formUpdateMethod: FormUpdateMethod,
  employmentTypeConfigsData: EmploymentTypeConfig[],
) {
  const { t } = useTranslation("break-types");
  // Determine which fields should be disabled based on the page context (schedule or business)
  const disabledComponentRules: Record<string, ComponentRule> = Object.keys(
    breakTypeData ?? {},
  ).reduce((acc, breakTypePropertyKey) => {
    let disabled = false;

    if (isScheduleContext) {
      // In schedule context, only these fields can be changed
      disabled = ![
        "hidden",
        "canOverrideClockedPaidBreak",
        "paidThreshold",
      ].includes(breakTypePropertyKey);
    } else if (formUpdateMethod !== FormUpdateMethod.CreateBreakType) {
      // Cannot be changed if updating (new break types only)
      disabled = ["breakTypeCode"].includes(breakTypePropertyKey);
    }

    return {
      ...acc,
      [breakTypePropertyKey]: {
        disabled,
      },
    };
  }, {});

  const employmentTypesOptions = employmentTypeConfigsData.map((config) => {
    return { label: config.name, value: config.employmentTypeCode };
  });

  const componentRules: Record<string, ComponentRule> = {
    ...disabledComponentRules,
    employmentTypeCodes: {
      ...disabledComponentRules.employmentTypes,
      component: MultiSelectList,
      componentProps: {
        allOptions: employmentTypesOptions,
        selectableOptions: employmentTypesOptions,
        menuPlacement: "top",
        multiStyle: MultiSelectType.Pill,
        placeholder: t("form.employmentTypesPlaceholder"),
      },
    },
    legacyBreakType: {
      disabled:
        isScheduleContext ||
        breakTypeData.legacyBreakType === LegacyBreakTypeEnum.Unknown,
      component: DynamicSelect,
      componentProps: {
        options:
          // Disable field if value is "Unknown" and remove from the dropdown options if it isn't (we don't want users to select it)
          breakTypeData?.legacyBreakType === LegacyBreakTypeEnum.Unknown
            ? legacyBreakTypeOptions
            : legacyBreakTypeOptions.filter(
                (legacyBreakType) =>
                  legacyBreakType.value !== LegacyBreakTypeEnum.Unknown,
              ),
      },
    },
    description: {
      ...disabledComponentRules.description,
      md: 12,
      xs: 12,
      lg: 6,
      componentProps: {
        xs: 12,
        md: 12,
        lg: 12,
      },
    },

    availableRange: {
      ...disabledComponentRules.availableRange,
      component: TimeRangeInput,
      componentProps: {
        startFieldKey: "availableRange.rangeStartTime",
        endFieldKey: "availableRange.rangeEndTime",
      },
      md: 12,
      xs: 12,
      lg: 3,
    },
    paidThreshold: {
      component: PaidThresholdRadioSelect,
      componentProps: {
        businessValue: businessBreakType?.paidThreshold,
        showDefaultOption: isScheduleContext,
      },
    },

    // At schedule level, render this as a radio group (as the default can be null)
    canOverrideClockedPaidBreak: {
      ...(isScheduleContext
        ? {
            component: SelectionGroup,
            componentProps: {
              options: [
                {
                  label: t("form.canOverrideClockedPaidBreak.default", {
                    defaultMessage:
                      businessBreakType?.canOverrideClockedPaidBreak
                        ? t("form.canOverrideClockedPaidBreak.always")
                        : t("form.canOverrideClockedPaidBreak.never"),
                  }),
                  value: null,
                },
                {
                  label: t("form.canOverrideClockedPaidBreak.always"),
                  value: true,
                },
                {
                  label: t("form.canOverrideClockedPaidBreak.never"),
                  value: false,
                },
              ],
              formCheckType: "radio",
            },
          }
        : { component: Checkbox, hideLabel: true }),
    },
  };

  return componentRules;
}

function useEmploymentTypeConfigsData(queryReference: PreloadedQuery<any>) {
  const query =
    usePreloadedQuery<EmploymentTypesQueries_GetEmploymentTypeConfigs_Query>(
      GetEmploymentTypeConfigsQuery,
      queryReference,
    );
  const employmentTypeConfigsData = query.employmentTypeConfigs.nodes;
  return [employmentTypeConfigsData] as const;
}

function useBreakTypeData(
  queryReferences: {
    breakTypes: PreloadedQuery<any>;
    scheduleBreakTypes: PreloadedQuery<any>;
  },
  breakTypeId: string | undefined,
  scheduleBreakTypeId: string | undefined,
  isScheduleContext: boolean,
) {
  // Both business/schedule break types will need to be fetched at the schedule context
  // If a business break type has not been overridden, then no scheduleBreakTypes for that id
  // will be returned
  const { breakTypes } = usePreloadedQuery(
    GetSingleBusinessBreakTypeQuery,
    queryReferences.breakTypes,
  );
  const { scheduleBreakTypes } = usePreloadedQuery(
    GetSingleScheduleBreakTypeQuery,
    queryReferences.scheduleBreakTypes,
  );

  const isCreate = breakTypeId == null;
  const scheduleBreakType = scheduleBreakTypes?.nodes?.[0];
  const breakType = breakTypes?.nodes?.[0];

  const data = (scheduleBreakType ?? breakType) as ScheduleBreakType;

  // Depending on the state of the break type, certain mutations need to be used, so track these variations using an enum
  const breakTypeUpdateMethod = (() => {
    if (isScheduleContext) {
      if (!scheduleBreakType) {
        // Create a a schedule level break type for the the first time
        return FormUpdateMethod.OverridingBreakType;
      }

      // Update an already existing schedule level break type
      return FormUpdateMethod.UpdateScheduleBreakType;
    }

    // Create a business level break type
    if (isCreate) {
      return FormUpdateMethod.CreateBreakType;
    }

    // Update a business level break type
    return FormUpdateMethod.UpdateBreakType;
  })();

  const initialBreakTypeData = (
    data
      ? { ...data }
      : {
          schedulable: true,
          internalAccess: true,
          canOverrideClockedPaidBreak: false,
          ...(isScheduleContext && { hidden: false }),
        }
  ) as ScheduleBreakType;

  // When in the schedule context, schedulePaidThreshold will be null if it hasn't been overridden/changed for the
  // schedule break type
  if (isScheduleContext) {
    initialBreakTypeData.paidThreshold =
      scheduleBreakType?.schedulePaidThreshold ?? null;
    initialBreakTypeData.canOverrideClockedPaidBreak =
      scheduleBreakType?.scheduleCanOverrideClockedPaidBreak ?? null;
  }

  return [
    initialBreakTypeData,
    breakTypeUpdateMethod,
    { scheduleBreakType, breakType },
  ] as const;
}

function useBreakTypeMutations(formUpdateMethod: FormUpdateMethod) {
  const [createBreakTypeMutation] = useMutation(
    CreateBusinessBreakTypeMutation,
  );
  const [updateBreakTypeMutation] = useMutation(
    UpdateBusinessBreakTypeMutation,
  );
  const [createScheduleBreakTypeOverride] = useMutation(
    CreateScheduleBreakTypeMutation,
  );
  const [updateScheduleBreakType] = useMutation(
    UpdateScheduleBreakTypeMutation,
  );

  const [deactivateBreakType] = useMutation(DeactivateBreakTypeMutation);
  const [activateBreakType] = useMutation(ReactivateBreakTypeMutation);
  const [deleteScheduleBreakType] = useMutation(
    RemoveScheduleBreakTypeOverrideMutation,
  );

  const activationMutations = {
    deactivateBreakType,
    activateBreakType,
    deleteScheduleBreakType,
  };

  switch (formUpdateMethod) {
    case FormUpdateMethod.OverridingBreakType:
      return [createScheduleBreakTypeOverride, activationMutations] as const;
    case FormUpdateMethod.UpdateScheduleBreakType:
      return [updateScheduleBreakType, activationMutations] as const;
    case FormUpdateMethod.CreateBreakType:
      return [createBreakTypeMutation, activationMutations] as const;
    default:
    case FormUpdateMethod.UpdateBreakType:
      return [updateBreakTypeMutation, activationMutations] as const;
  }
}
