/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CorporateUpdateInput = {
    name?: string | null | undefined;
    stackIds?: Array<string> | null | undefined;
};
export type CorporatesQueries_UpdateCorporateMutation_InternalQueryVariables = {
    id: string;
    input: CorporateUpdateInput;
};
export type CorporatesQueries_UpdateCorporateMutation_InternalQueryResponse = {
    readonly updateCorporate: {
        readonly id: string;
        readonly name: string;
        readonly ssoAuthMethods: ReadonlyArray<{
            readonly id: string;
            readonly authType: string;
            readonly code: string;
            readonly corporateId: string;
            readonly createdAt: string;
            readonly displayName: string;
            readonly employmentIdAttributes: ReadonlyArray<string>;
            readonly employmentAttrToSearch: string;
            readonly metadataConfig: string | null;
            readonly metadataIssuer: string | null;
            readonly metadataOverrides: unknown | null;
            readonly metadataSyncedAt: string | null;
            readonly metadataUrl: string | null;
            readonly updatedAt: string;
        }>;
        readonly corporateStacks: ReadonlyArray<{
            readonly id: string;
            readonly stack: {
                readonly id: string;
                readonly stackCode: string;
            };
        }>;
        readonly createdAt: string;
        readonly updatedAt: string;
        readonly deleted: boolean;
    };
};
export type CorporatesQueries_UpdateCorporateMutation_InternalQuery = {
    readonly response: CorporatesQueries_UpdateCorporateMutation_InternalQueryResponse;
    readonly variables: CorporatesQueries_UpdateCorporateMutation_InternalQueryVariables;
};



/*
mutation CorporatesQueries_UpdateCorporateMutation_InternalQuery(
  $id: ID!
  $input: CorporateUpdateInput!
) {
  updateCorporate(id: $id, input: $input) {
    id
    name
    ssoAuthMethods {
      id
      authType
      code
      corporateId
      createdAt
      displayName
      employmentIdAttributes
      employmentAttrToSearch
      metadataConfig
      metadataIssuer
      metadataOverrides
      metadataSyncedAt
      metadataUrl
      updatedAt
    }
    corporateStacks {
      id
      stack {
        id
        stackCode
      }
    }
    createdAt
    updatedAt
    deleted
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Corporate",
    "kind": "LinkedField",
    "name": "updateCorporate",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SSOAuthMethod",
        "kind": "LinkedField",
        "name": "ssoAuthMethods",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "authType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "corporateId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentIdAttributes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentAttrToSearch",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metadataConfig",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metadataIssuer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metadataOverrides",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metadataSyncedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metadataUrl",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CorporateStack",
        "kind": "LinkedField",
        "name": "corporateStacks",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Stack",
            "kind": "LinkedField",
            "name": "stack",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stackCode",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesQueries_UpdateCorporateMutation_InternalQuery",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CorporatesQueries_UpdateCorporateMutation_InternalQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "1d6d4b0d1f66c8be54f289378a0f64aa",
    "id": null,
    "metadata": {},
    "name": "CorporatesQueries_UpdateCorporateMutation_InternalQuery",
    "operationKind": "mutation",
    "text": "mutation CorporatesQueries_UpdateCorporateMutation_InternalQuery(\n  $id: ID!\n  $input: CorporateUpdateInput!\n) {\n  updateCorporate(id: $id, input: $input) {\n    id\n    name\n    ssoAuthMethods {\n      id\n      authType\n      code\n      corporateId\n      createdAt\n      displayName\n      employmentIdAttributes\n      employmentAttrToSearch\n      metadataConfig\n      metadataIssuer\n      metadataOverrides\n      metadataSyncedAt\n      metadataUrl\n      updatedAt\n    }\n    corporateStacks {\n      id\n      stack {\n        id\n        stackCode\n      }\n    }\n    createdAt\n    updatedAt\n    deleted\n  }\n}\n"
  }
};
})();
(node as any).hash = '26a35d5f42a3bfc8fd6e465af8b6e427';
export default node;
