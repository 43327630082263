import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, RouteComponentProps } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
import BusinessProfile from "./BusinessProfile";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import Employments from "../Employment/Employments";
import Schedules from "../Schedule/Schedules";
import AOSTemplates from "../AOSTemplates/AOSTemplates";
import BreakTypes from "../BreakTypes/BreakTypes";
import BreakTypesProfile from "../BreakTypes/BreakTypesProfile/BreakTypesProfile";
import SkillLevels from "../SkillLevels/SkillLevels";
import TimeOffTypesProfile from "../TimeOffTypes/TimeOffTypesProfile/TimeOffTypesProfile";
import TimeOffTypes from "../TimeOffTypes/TimeOffTypes";
import PeopleSettingsProfile from "./PeopleSettings/PeopleSettingsProfile";
import AuditLogs from "../AuditLogs/AuditLogs";
import MetadataTypes from "./MetadataTypes/MetadataTypes";
import MetadataTypesProfile from "./MetadataTypes/MetadataTypesProfile/MetadataTypesProfile";
import MetadataLayout from "./MetadataLayout/MetadataLayout";
import { useAppRouter } from "../../../hooks/useAppRouter";
import EmploymentTypes from "../EmploymentTypes/EmploymentTypes";
import EmploymentTypesProfile from "../EmploymentTypes/EmploymentTypesProfile/EmploymentTypesProfile";
import PerformanceSettings from "../Employment/PerformanceSettings";
import FeedbackReasonsSettings from "./FeedbackReasons/FeedbackReasons";
import KpiComponents from "./KpiComponents/KpiComponents";
import KpiComponentsProfile from "./KpiComponents/KpiComponentsProfile/KpiComponentsProfile";
import { ModalProvider } from "../../../contexts/ModalContext";
import JobTitles from "../JobTitles/JobTitles";

interface MatchParams {
  business_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

const BusinessLayout: FunctionComponent<Props> = ({ match }: Props) => {
  const { t } = useTranslation("businesses");
  const router = useAppRouter();

  const { business, appContext } = useBusinessContext();
  const isNewBusiness = business.id == null;

  const tabBarOptions = useBusinessTabBarOptions(isNewBusiness);
  const showFeedbackReasonsLink =
    business.disciplinaryActionEnabled || business.commendationEnabled;
  const showJobTitleLink = business.jobTitlesEnabled;

  return (
    <>
      {appContext.stack && (
        <>
          <HeaderPortal as="span">
            {isNewBusiness && (
              <>
                <Link to={`/stack/${appContext.stack.id}/businesses`}>
                  <span>{t("layout.title")}</span>
                </Link>
                <span className="ml-2 mr-2">&gt;</span>
                <span>
                  {business.id ? business.businessName : t("table.new")}
                </span>
              </>
            )}

            {!isNewBusiness && (
              <>
                <Link to={`/stack/${appContext.stack.id}/businesses`}>
                  <span>{business.businessName}</span>
                </Link>
              </>
            )}

            <span id="sub-header-portal" />
          </HeaderPortal>
        </>
      )}

      {!tabBarOptions.hideTabBar && (
        <header className="sub-header-container">
          {tabBarOptions?.onlyShowSettings && (
            <Nav variant="tabs">
              <LinkContainer className="active" exact to="#">
                <Nav.Link>{t("layout.nav.settings")}</Nav.Link>
              </LinkContainer>
            </Nav>
          )}
          {!tabBarOptions?.onlyShowSettings && (
            <Nav variant="tabs">
              <NavDropdown
                title={t("layout.nav.settings")}
                id="nav-dropdown"
                className={
                  router.pathname.includes("/settings") ||
                  router.pathname.includes("/business/create") ||
                  router.pathname.endsWith("/profile")
                    ? "active"
                    : ""
                }
              >
                <LinkContainer exact to={`${match.url}`}>
                  <NavDropdown.Item>{t("layout.nav.general")}</NavDropdown.Item>
                </LinkContainer>
                {!isNewBusiness && (
                  <>
                    <LinkContainer
                      exact
                      to={`${match.url}/settings/people_settings`}
                    >
                      <NavDropdown.Item>
                        {t("layout.nav.peopleSettings")}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Divider />
                    <LinkContainer
                      exact
                      to={`${match.url}/settings/break_types`}
                    >
                      <NavDropdown.Item>
                        {t("layout.nav.breakTypes")}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      exact
                      to={`${match.url}/settings/kpi_components`}
                    >
                      <NavDropdown.Item>
                        {t("layout.nav.kpiComponents")}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      exact
                      to={`${match.url}/settings/skill_levels`}
                    >
                      <NavDropdown.Item>
                        {t("layout.nav.skillLevels")}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      exact
                      to={`${match.url}/settings/time_off_types`}
                    >
                      <NavDropdown.Item>
                        {t("layout.nav.timeOffTypes")}
                      </NavDropdown.Item>
                    </LinkContainer>

                    {showJobTitleLink ? (
                      <LinkContainer
                        exact
                        to={`${match.url}/settings/job_titles`}
                      >
                        <NavDropdown.Item>
                          {t("layout.nav.jobTitles")}
                        </NavDropdown.Item>
                      </LinkContainer>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </NavDropdown>

              {business.id && (
                <>
                  <NavDropdown
                    title={t("layout.nav.employees")}
                    id="nav-dropdown"
                    className={
                      router.pathname.includes("/employees") ? "active" : ""
                    }
                  >
                    <LinkContainer exact to={`${match.url}/employees`}>
                      <NavDropdown.Item>
                        {t("layout.nav.people")}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Divider />
                    <LinkContainer
                      exact
                      to={`${match.url}/employees/performance_settings`}
                    >
                      <NavDropdown.Item>
                        {t("layout.nav.performanceSettings")}
                      </NavDropdown.Item>
                    </LinkContainer>
                    {showFeedbackReasonsLink ? (
                      <LinkContainer
                        exact
                        to={`${match.url}/employees/feedback_reasons`}
                      >
                        <NavDropdown.Item>
                          {t("layout.nav.feedbackReasons")}
                        </NavDropdown.Item>
                      </LinkContainer>
                    ) : (
                      <></>
                    )}
                    <NavDropdown.Divider />
                    <LinkContainer
                      exact
                      to={`${match.url}/employees/employment_types`}
                    >
                      <NavDropdown.Item>
                        {t("layout.nav.employmentTypes")}
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>

                  <LinkContainer exact to={`${match.url}/schedules`}>
                    <Nav.Link>{t("layout.nav.schedules")}</Nav.Link>
                  </LinkContainer>
                  <LinkContainer exact to={`${match.url}/aos_templates`}>
                    <Nav.Link>{t("layout.nav.aosTemplates")}</Nav.Link>
                  </LinkContainer>
                  <NavDropdown
                    title={t("layout.nav.metadata")}
                    id="nav-dropdown"
                    className={
                      router.pathname.includes("/metadata") ? "active" : ""
                    }
                  >
                    <LinkContainer
                      exact
                      to={`${match.url}/metadata/metadata_types`}
                    >
                      <NavDropdown.Item>
                        {t("layout.nav.metadataTypes")}
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      exact
                      to={`${match.url}/metadata/metadata_layout`}
                    >
                      <NavDropdown.Item>
                        {t("layout.nav.metadataLayout")}
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                  <LinkContainer exact to={`${match.url}/audit_logs`}>
                    <Nav.Link>{t("layout.nav.auditLogs")}</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          )}
        </header>
      )}
      <div className="mt-3">
        <ModalProvider>
          <Route exact path={`${match.path}`} component={BusinessProfile} />
          <Route
            exact
            path={`${match.path}/employees`}
            component={Employments}
          />
          <Route exact path={`${match.path}/schedules`} component={Schedules} />
          <Route
            exact
            path={`${match.path}/settings/break_types`}
            component={BreakTypes}
          />
          <Route
            exact
            path={`${match.path}/settings/job_titles`}
            component={JobTitles}
          />
          <Route
            exact
            path={`${match.path}/employees/employment_types`}
            component={EmploymentTypes}
          />
          <Route
            exact
            path={[
              `${match.path}/employees/employment_types/create`,
              `${match.path}/employees/employment_types/edit/:employment_type_id`,
            ]}
            component={EmploymentTypesProfile}
          />
          <Route
            exact
            path={[
              `${match.path}/settings/break_types/create`,
              `${match.path}/settings/break_types/edit/:break_type_id`,
            ]}
            component={BreakTypesProfile}
          />
          <Route
            exact
            path={`${match.path}/settings/people_settings`}
            component={PeopleSettingsProfile}
          />
          <Route
            exact
            path={`${match.path}/settings/time_off_types`}
            component={TimeOffTypes}
          />
          <Route
            exact
            path={[
              `${match.path}/settings/time_off_types/create`,
              `${match.path}/settings/time_off_types/edit/:time_off_type_id`,
            ]}
            component={TimeOffTypesProfile}
          />

          <Route
            exact
            path={`${match.path}/settings/kpi_components`}
            component={KpiComponents}
          />
          <Route
            exact
            path={[`${match.path}/settings/kpi_components/:screen_code`]}
            component={KpiComponentsProfile}
          />

          <Route
            exact
            path={`${match.path}/metadata/metadata_types`}
            component={MetadataTypes}
          />
          <Route
            exact
            path={[
              `${match.path}/metadata/metadata_types/create`,
              `${match.path}/metadata/metadata_types/edit/:metadata_type_id`,
            ]}
            component={MetadataTypesProfile}
          />
          <Route
            path={`${match.path}/metadata/metadata_layout`}
            component={MetadataLayout}
          />
          <Route
            exact
            path={`${match.path}/aos_templates`}
            component={AOSTemplates}
          />
          <Route
            exact
            path={`${match.path}/settings/skill_levels`}
            component={SkillLevels}
          />
          <Route
            exact
            path={`${match.path}/audit_logs`}
            component={AuditLogs}
          />
          <Route
            exact
            path={`${match.path}/employees/performance_settings`}
            component={PerformanceSettings}
          />
          <Route
            path={`${match.path}/employees/feedback_reasons`}
            component={FeedbackReasonsSettings}
          />
        </ModalProvider>
      </div>
      <footer />
    </>
  );
};

function useBusinessTabBarOptions(isNewBusiness: boolean) {
  const router = useAppRouter();

  const defaultOptions = {
    onlyShowSettings: false,
    hideTabBar: false,
  };

  // LK-15395: Code debt, this could still be cleaned up a bit more - just got rid of the
  // ActiveSubRouteLocation usages which were overly complex for what it was actually doing. These
  // routes are just specified since they require some custom handling for the tab bar either
  // hiding specific items or the entire tab bar
  const routes = [
    {
      path: "/metadata_types",
      onlyShowSettings: true,
    },
    {
      path: "/time_off_types",
      onlyShowSettings: true,
    },
    {
      path: "/kpi_components",
      hideTabBar: true,
    },
    {
      path: "/break_types",
      onlyShowSettings: true,
    },
  ];

  const selectedRouteConfig = routes.find((x) =>
    router.pathname.includes(x.path),
  );

  if (selectedRouteConfig && !isNewBusiness) {
    if (!router.pathname.endsWith(selectedRouteConfig.path)) {
      return {
        onlyShowSettings:
          selectedRouteConfig.onlyShowSettings ??
          defaultOptions.onlyShowSettings,
        hideTabBar: selectedRouteConfig.hideTabBar ?? defaultOptions.hideTabBar,
      };
    }
  }

  return defaultOptions;
}

export default BusinessLayout;
