import React, { ComponentClass, FunctionComponent } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { FormikContext, FormikContextType } from "formik";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import isNumber from "lodash/isNumber";
import isFunction from "lodash/isFunction";
import isUndefined from "lodash/isUndefined";
import { FormControlProps } from "react-bootstrap";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import {
  SchemaFieldType,
  Kind,
  Type,
  IProperty,
  GroupName,
  SubGroupName,
  FieldProperties,
  FormGroupProperties,
} from "./models";
import {
  getFieldsByInputObjectName,
  getSchemaFieldType,
  getSettingsByGroup,
} from "./formUtilities";
import JsonEditor from "./JsonEditor";
// eslint-disable-next-line import/no-cycle
import List from "./List";
// eslint-disable-next-line import/no-cycle
import DynamicInputGroup from "./DynamicInputGroup";
import FormGroup from "./FormGroup";
import { isEmptyString, stringToNumber } from "../../../utils/utility";
import {
  DynamicFormContext,
  DynamicFormContextProps,
  useDynamicFormContext,
} from "../../../contexts/DynamicFormContext";
import { InvalidClassName } from "../../../data/models/common";
import Checkbox from "./Checkbox";

const StyledNumberInputGroup = styled(InputGroup)`
  max-width: 120px;
`;

type Props = FormControlProps &
  FormGroupProperties &
  FieldProperties & {
    fieldKey: string;
    fields?: Map<GroupName, Map<SubGroupName, IProperty[]>>;
    schemaFieldType?: SchemaFieldType;
    // custom component definition from ComponentRule
    component?: ComponentClass<any, any> | FunctionComponent<any>; // react component
    componentProps?: Record<string, any>;
    onValueChanged?: (
      newValue: any,
      formikContext: FormikContextType<any>,
    ) => any;
    disabled?: boolean;
    isExternal?: boolean;

    // Useful for components that are objects that have nested properties that are rendered in a custom way
    isRenderedByParentComponent?: boolean;
    dataListOptions?: string[];
  };

const fieldControlKey = "field-control";

export default function Field(props: Props) {
  const { t } = useTranslation();
  const { validationRules } = useDynamicFormContext();
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fields,
    fieldKey: key,
    label,
    schemaFieldType: type,
    component,
    componentProps,
    xs,
    md,
    lg,
    postfix,
    hide,
    hideLabel,
    hideError,
    hideDescription,
    description,
    onValueChanged,
    formControlType,
    horizontal,
    tooltipText,
    isExternal,
    boldLabel,
    dataListOptions = [],
    isRenderedByParentComponent,
    formGroupClassName,
    ...rest
  } = props;

  function preventAlphaCharacters(evt: React.KeyboardEvent<HTMLInputElement>) {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number
    // LK-8991: Number field will allow the letter 'e' (which we don't really need)
    return ["e", "E", "+"].includes(evt.key) && evt.preventDefault();
  }

  function isRequiredField() {
    const validation =
      validationRules?.fields[props.fieldKey] != null
        ? yup.reach(validationRules, props.fieldKey).describe()
        : null;

    // Using the yupSchema description object, we can check which field validations are going to be applied
    return (validation?.tests ?? []).some(
      (schemaTestObject) => schemaTestObject.name === "required",
    );
  }

  const columnSizeProps = {
    xs: xs != null ? xs : 12,
    md: md != null ? md : 6,
    lg: lg != null ? lg : 3,
  };

  const formGroupProps: FormGroupProperties = {
    label,
    hide,
    hideLabel,
    hideError,
    hideDescription,
    description,
    horizontal,
    tooltipText,
    isRequired: isRequiredField(),
    isExternal,
    boldLabel,
    formGroupClassName,
  };
  const fieldKey = componentProps?.getValueKey || key;

  const handleValueChange = (
    newValue: any,
    formikContext: FormikContextType<any>,
  ) => {
    const helpers = formikContext.getFieldHelpers(fieldKey);
    helpers.setValue(newValue);
    if (isFunction(onValueChanged)) {
      onValueChanged(newValue, formikContext);
    }
  };

  return (
    <FormikContext.Consumer>
      {(formikContext: FormikContextType<any>) => {
        const formikFields = formikContext.getFieldProps(fieldKey);
        const meta = formikContext.getFieldMeta(fieldKey);

        const { error } = meta;
        const { value, ...inputProps } = formikFields;

        if (isRenderedByParentComponent) {
          return null;
        }

        if (component != null && typeof component !== "undefined") {
          // custom component registered
          let componentType = type ? getSchemaFieldType(type) : undefined;
          if (component === List && type) {
            componentType = getSchemaFieldType(type.ofType as SchemaFieldType);
          }

          return (
            <FormGroup
              {...formGroupProps}
              {...columnSizeProps}
              fieldKey={fieldKey}
            >
              <div className={horizontal ? "col" : ""}>
                {React.createElement(component, {
                  ...componentProps,
                  key: fieldControlKey,
                  name: fieldControlKey,
                  fieldKey,
                  value,
                  type: componentType,
                  label,
                  // add invalid class when error
                  className: meta.error
                    ? `${InvalidClassName} ${props.className}`
                    : props.className,
                  onChange: (v: any) => {
                    handleValueChange(v, formikContext);
                  },
                  disabled: props.disabled,
                })}
              </div>
            </FormGroup>
          );
        }

        if (!type || hide) {
          return null;
        }
        const { kind, name: fieldType } = getSchemaFieldType(type);
        switch (kind) {
          case Kind.SCALAR: {
            switch (fieldType) {
              case Type.ID:
              case Type.String:
                return (
                  <FormGroup
                    {...formGroupProps}
                    {...columnSizeProps}
                    fieldKey={fieldKey}
                  >
                    <InputGroup
                      size={props.size}
                      as={horizontal ? Col : undefined}
                    >
                      <Form.Control
                        key={fieldControlKey}
                        type="text"
                        list={
                          dataListOptions?.length > 0
                            ? `${fieldKey}-data-list`
                            : undefined
                        }
                        isInvalid={error != null}
                        value={value || ""}
                        {...inputProps}
                        {...omit(rest, "emptyValue")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          let v = e.target.value;

                          // Instead of sending an empty string, we might need to send null to the backend (or another default value)
                          if ("emptyValue" in rest && isEmptyString(v)) {
                            v = rest.emptyValue;
                          }

                          handleValueChange(v, formikContext);
                        }}
                      />
                      {(dataListOptions ?? []).length > 0 && (
                        <datalist id={`${fieldKey}-data-list`}>
                          {dataListOptions.map((listOption) => (
                            // eslint-disable-next-line jsx-a11y/control-has-associated-label
                            <option value={listOption} key={listOption} />
                          ))}
                        </datalist>
                      )}
                      {postfix ? (
                        <InputGroup.Append>
                          <InputGroup.Text id="inputGroupAppend">
                            {postfix}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      ) : null}
                    </InputGroup>
                  </FormGroup>
                );
              case Type.JSON:
                return (
                  <FormGroup
                    {...formGroupProps}
                    xs={xs ?? 8}
                    md={md ?? 8}
                    lg={lg ?? 8}
                    fieldKey={fieldKey}
                  >
                    <JsonEditor
                      key={fieldControlKey}
                      value={value ?? props?.componentProps?.defaultValue ?? {}}
                      name={fieldKey}
                      mode="code"
                      disabled={props.disabled}
                      onChange={(v) => {
                        handleValueChange(v, formikContext);
                      }}
                    />
                  </FormGroup>
                );
              case Type.Int:
              case Type.Float:
              case Type.Hours:
              case Type.Minutes:
              case Type.Seconds: {
                return (
                  <FormGroup
                    {...formGroupProps}
                    {...columnSizeProps}
                    fieldKey={fieldKey}
                  >
                    <StyledNumberInputGroup
                      as={horizontal ? Col : undefined}
                      size={props.size}
                    >
                      <Form.Control
                        key={fieldControlKey}
                        type={formControlType || "number"}
                        isInvalid={error != null}
                        onKeyDown={
                          formControlType ? undefined : preventAlphaCharacters
                        }
                        value={
                          !isUndefined(value) && isNumber(value) ? value : ""
                        }
                        {...inputProps}
                        {...rest}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const v = e.target.value;
                          const numValue = stringToNumber(v);
                          handleValueChange(numValue, formikContext);
                        }}
                      />
                      {postfix ? (
                        <InputGroup.Append>
                          <InputGroup.Text id="inputGroupAppend">
                            {postfix}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      ) : null}
                    </StyledNumberInputGroup>
                  </FormGroup>
                );
              }
              case Type.BigInt: {
                return (
                  <FormGroup
                    {...formGroupProps}
                    {...columnSizeProps}
                    fieldKey={fieldKey}
                  >
                    <StyledNumberInputGroup
                      as={horizontal ? Col : undefined}
                      size={props.size}
                    >
                      <Form.Control
                        key={fieldControlKey}
                        type="number"
                        onKeyDown={preventAlphaCharacters}
                        isInvalid={error != null}
                        value={value || ""}
                        {...inputProps}
                        {...rest}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const v = e.target.value;
                          handleValueChange(v, formikContext);
                        }}
                      />
                      {postfix ? (
                        <InputGroup.Append>
                          <InputGroup.Text id="inputGroupAppend">
                            {postfix}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      ) : null}
                    </StyledNumberInputGroup>
                  </FormGroup>
                );
              }
              case Type.Boolean:
                return (
                  <FormGroup
                    {...formGroupProps}
                    {...columnSizeProps}
                    hideLabel
                    fieldKey={fieldKey}
                    isExternal={false}
                  >
                    <Checkbox
                      {...inputProps}
                      {...rest}
                      key={fieldControlKey}
                      error={error}
                      value={value}
                      label={label}
                      fieldKey={fieldKey}
                      tooltipText={tooltipText}
                      isExternal={formGroupProps.isExternal}
                      onChange={(newValue: boolean) => {
                        handleValueChange(newValue, formikContext);
                      }}
                    />
                  </FormGroup>
                );

              default: {
                // unknown data type and component, display read-only field
                return (
                  <div>
                    {t("property.unknownScalar", {
                      kind,
                      fieldType,
                    })}
                  </div>
                );
              }
            }
          }
          case Kind.LIST: {
            return (
              <FormGroup
                {...formGroupProps}
                {...columnSizeProps}
                fieldKey={fieldKey}
              >
                <List<any>
                  fieldKey={fieldKey}
                  type={getSchemaFieldType(type.ofType as SchemaFieldType)}
                  fields={fields}
                  value={value}
                  disabled={props.disabled}
                />
              </FormGroup>
            );
          }
          case Kind.INPUT_OBJECT: {
            // nested object
            return (
              <FormGroup
                {...formGroupProps}
                xs={xs ?? 12}
                md={md ?? 12}
                lg={lg ?? 12}
                fieldKey={fieldKey}
                className={label == null ? "mb-0" : ""}
              >
                <DynamicFormContext.Consumer>
                  {(dynamicFormContext: DynamicFormContextProps) => (
                    <DynamicInputGroup
                      hideError={hideError}
                      hideLabel={hideLabel}
                      hideDescription={hideDescription}
                      hideGroupName
                      fields={getSettingsByGroup(
                        getFieldsByInputObjectName(
                          dynamicFormContext.propertyList as unknown as IProperty[],
                          fieldType,
                        ),
                      )}
                      fieldKey={fieldKey}
                      disabled={props.disabled}
                    />
                  )}
                </DynamicFormContext.Consumer>
              </FormGroup>
            );
          }
          default: {
            return (
              <div>
                Unknown non-scalar data {kind} for {fieldControlKey}
              </div>
            );
          }
        }
      }}
    </FormikContext.Consumer>
  );
}
