import React, { useCallback, useEffect, useState } from "react";
import { Environment, fetchQuery, graphql } from "react-relay";
import Card from "react-bootstrap/Card";
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLError } from "graphql";
import { Alert } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik } from "formik";

import UpdateRulesOnScheduleMutation, {
  UpdateRulesOnScheduleMutationInput,
} from "../../mutations/UpdateRulesOnScheduleMutation";
import { UpdateRulesOnScheduleMutationResponse } from "../../mutations/__generated__/UpdateRulesOnScheduleMutation.graphql";
import RemoveRulesOnScheduleMutation from "../../mutations/RemoveRulesOnScheduleMutation";
import { RemoveRulesOnScheduleMutationResponse } from "../../mutations/__generated__/RemoveRulesOnScheduleMutation.graphql";
import { RuleSetFetch, RuleSetFetchError, ScheduleRulesInfo } from "./models";
import RulesConfigurationForm, {
  RULES_CONFIG_VALIDATION,
} from "./RulesConfigurationForm";
import OkCancelModal from "../../../common/OkCancelModal";
import { useBusinessContext } from "../../../../contexts/BusinessContext";
import Loader from "../../../common/Loader";
import HeaderPortal from "../../../common/Portal/HeaderPortal";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import { useModal } from "../../../../contexts/ModalContext";

const scheduleRulesQuery = graphql`
  query RulesPage_Query($businessId: ID!, $scheduleId: ID!) {
    schedules(businessId: $businessId, ids: [$scheduleId]) {
      edges {
        node {
          ruleSetName
          ruleSetVersion
          ruleSetSubscriptionStatus
          ruleSetMeta
        }
      }
    }
  }
`;

const allRulesQuery = graphql`
  query RulesPage_AllRules_Query {
    ruleSets {
      name
      description
      contents {
        version
        level
      }
    }
  }
`;

type Props = RouteComponentProps<MatchParams>;

interface MatchParams {
  business_id: string;
  schedule_id: string;
}

function RulesPage(props: Props) {
  const businessContext = useBusinessContext();
  const { showModal } = useModal();

  const {
    params: { business_id: businessId, schedule_id: scheduleId },
  } = useAppRouter<MatchParams>();
  const [ruleSets, setRuleSets] = useState<RuleSetFetch>({
    loaded: false,
    error: null,
    loading: false,
    data: [],
  });
  const [scheduleRulesInfo, setScheduleRulesInfo] = useState<
    ScheduleRulesInfo | undefined
  >(undefined);

  const fetchConfiguredRules = useCallback(() => {
    fetchQuery(businessContext.environment, scheduleRulesQuery, {
      businessId,
      scheduleId,
    })
      .toPromise()
      .then((data: any) => {
        // access the graphql response
        const schedule = data.schedules.edges[0].node;
        setScheduleRulesInfo(schedule);
      });
  }, [businessContext.environment, businessId, scheduleId]);

  const fetchAllRules = (environment: Environment) => {
    if (ruleSets.loaded) {
      return;
    }
    setRuleSets({
      loaded: false,
      loading: true,
      error: null,
      data: [],
    });

    fetchQuery(environment, allRulesQuery, {})
      .toPromise()
      .then((data: any) => {
        setRuleSets({
          loaded: true,
          loading: false,
          error: null,
          data: data.ruleSets,
        });
      })
      .catch((err: any) => {
        const { source } = err;
        let errors: Array<RuleSetFetchError>;
        if (source && source.errors && source.errors.length > 0) {
          const rawErrors = source.errors as Array<GraphQLError>;
          errors = rawErrors.map((raw) => {
            return {
              message: raw.message,
              extensions: raw.extensions,
              original: raw,
            };
          });
        } else {
          errors = [{ message: "Server error", original: err }];
        }
        setRuleSets({
          loaded: false,
          loading: false,
          error: errors,
          data: [],
        });
      });
  };

  const handleSave = (values: ScheduleRulesInfo) => {
    if (values.ruleSetName == null || values.ruleSetVersion == null) {
      return;
    }
    const input: UpdateRulesOnScheduleMutationInput = {
      scheduleId,
      businessId,
      ruleSetName: values.ruleSetName,
      ruleSetVersion: values.ruleSetVersion,
      ruleSetSubscriptionStatus: values.ruleSetSubscriptionStatus,
    };
    UpdateRulesOnScheduleMutation(businessContext.environment, input, onSaved);
  };

  const onSaved = (response: UpdateRulesOnScheduleMutationResponse) => {
    const schedule: ScheduleRulesInfo = {
      ruleSetName: response.updateRulesOnSchedule.ruleSetName,
      ruleSetVersion: response.updateRulesOnSchedule.ruleSetVersion,
      ruleSetSubscriptionStatus:
        response.updateRulesOnSchedule.ruleSetSubscriptionStatus,
      ruleSetMeta: response.updateRulesOnSchedule.ruleSetMeta as Record<
        string,
        any
      >,
    };
    setScheduleRulesInfo(schedule);
    toast("Saved successfully");
  };

  const handleRemoveRules = () => {
    showModal(
      <OkCancelModal
        title="Are you sure?"
        okLabel="Remove rules"
        onOk={removeRules}
      >
        <div>
          <span>Are you sure you want to remove rules from this schedule?</span>
        </div>
      </OkCancelModal>,
    );
  };

  const removeRules = () => {
    RemoveRulesOnScheduleMutation(
      businessContext.environment,
      {
        scheduleId,
        businessId,
      },
      onRulesRemoval,
    );
  };

  const onRulesRemoval = (response: RemoveRulesOnScheduleMutationResponse) => {
    const schedule: ScheduleRulesInfo = {
      ruleSetName: response.removeRulesOnSchedule.ruleSetName,
      ruleSetVersion: response.removeRulesOnSchedule.ruleSetVersion,
      ruleSetSubscriptionStatus:
        response.removeRulesOnSchedule.ruleSetSubscriptionStatus,
      ruleSetMeta: response.removeRulesOnSchedule.ruleSetMeta as Record<
        string,
        any
      >,
    };
    setScheduleRulesInfo(schedule);
    toast("Rules removed successfully");
  };

  // Initial load
  useEffect(() => {
    fetchConfiguredRules();
  }, [fetchConfiguredRules]);

  if (scheduleRulesInfo === undefined) {
    return <Loader />;
  }

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <span className="ml-2 mr-2">&gt;</span>
        <span>Rules</span>
      </HeaderPortal>

      <Card body>
        <h1>Rules</h1>
        {ruleSets.error && (
          <Alert variant="danger">
            Error loading rules:
            <ul>
              {ruleSets.error.map((error, errorIndex) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`error-${errorIndex}`}>
                    {error.message}:{" "}
                    {error.extensions ? JSON.stringify(error.extensions) : ""}
                  </li>
                );
              })}
            </ul>
          </Alert>
        )}

        <Formik
          onSubmit={handleSave}
          initialValues={scheduleRulesInfo}
          enableReinitialize
          validationSchema={RULES_CONFIG_VALIDATION}
        >
          <RulesConfigurationForm
            fetchAllRules={() => fetchAllRules(businessContext.environment)}
            ruleSets={ruleSets}
            removeRules={() => handleRemoveRules()}
          />
        </Formik>
      </Card>
    </>
  );
}

export default RulesPage;
