import React from "react";
import {
  DateObject,
  DateTime,
  Duration,
  DurationObject,
  DurationUnit,
  ToRelativeOptions,
} from "luxon";
import reduce from "lodash/reduce";
import isPlainObject from "lodash/isPlainObject";
import isEqual from "lodash/isEqual";
import isDate from "lodash/isDate";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import isNaN from "lodash/isNaN";
import { FormikHelpers } from "formik";
import { keys } from "lodash";
import semver from "semver";
import i18next from "i18next";
import ServerError, { ServerErrorCode, ValidationError } from "./server-error";
import { transformStackDomain } from "../environment";
import { GraphQLDateFormat } from "../data/models/common";

export const stringToNumber = (
  v: string,
  defaultValue: string | number | null = null,
): string | number | null => {
  let numValue = defaultValue;

  if (v != null && v !== "" && !isNaN(v)) {
    // if value contain anything other than 0-9, "." or "," do not attempt to parse it
    if (v.match(/[^0-9.,-]+/)) {
      // invalid value should fall through so that it can be caught by validation
      return v;
    }

    if (v.match(/[0-9]\.$/)) {
      // invalid value should fall through so that it can be caught by validation
      return v;
    }

    const n = Number(v);
    numValue = isNaN(n) ? numValue : n;
  }
  return numValue;
};

export const differenceWith = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  deep = false,
) => {
  const r = reduce(
    obj1,
    (result, value, key) => {
      const obj2Value = (obj2 as any)[key];
      if (isPlainObject(value)) {
        if (deep) {
          const diff = differenceWith(value, obj2Value);
          if (diff != null) {
            (result as any)[key] = diff;
          }
        } else if (JSON.stringify(value) !== JSON.stringify(obj2Value)) {
          (result as any)[key] = value;
        }
      } else if (!isEqual(value, obj2Value)) {
        (result as any)[key] = value;
      }
      return result;
    },
    {},
  );

  const hasDifference = keys(r).length > 0;
  return hasDifference ? r : null;
};

export const convertToCamelCase = (name: string) => {
  // change from dashed-case or underscore_case to camelCase
  const regex = /[_-]([a-z])/gi;
  return name.replace(regex, ($0, $1) => {
    return $1.toUpperCase();
  });
};

export const concatenateStrings = (
  arr: string[],
  separator = ", ",
  lastItemSeparator = ` ${i18next.t("translation:form.and")} `,
) => {
  // Doesn't seem like i18next will let use a different array separator for the last element
  return arr.reduce((acc, curr, index, array) => {
    const symbol = index < array.length - 1 ? separator : lastItemSeparator;
    const prefix = acc === "" ? "" : acc + symbol;
    return prefix + curr;
  }, "");
};

export const getHandleServerValidationErrorFn = (
  helpers: FormikHelpers<any>,
  options: {
    excludeLabelInError: boolean;
  } = {
    excludeLabelInError: false,
  },
) => {
  return (err: Error) => {
    helpers.setSubmitting(false);
    const { source } = (err as any) || {};
    if (!source) {
      return;
    }

    const serverError = new ServerError(source);
    const { code, message } = serverError;
    switch (code) {
      case ServerErrorCode.ValidationError: {
        (serverError.invalidParams || []).forEach((v: ValidationError[]) => {
          const details = v.map(
            (validationError: ValidationError) =>
              `${options.excludeLabelInError ? "" : validationError.label} ${
                validationError.detail
              }`,
          );

          // add error to property
          helpers.setFieldError(
            convertToCamelCase(v[0].parameter),
            details.join(", ").replace("[", "").replace("]", ""),
          );
        });
        return;
      }
      case ServerErrorCode.GeneralError:
      default: {
        alert(message);
      }
    }
  };
};

export const valueToDateTime = (
  rawValue?: string | number | Date,
  valueFormat?: string,
  timezone = "utc",
) => {
  if (rawValue == null) {
    return rawValue;
  }

  const dateTimeOptions: DateObject = {
    zone: timezone,
  };

  if (valueFormat != null) {
    if (isNumber(rawValue)) {
      switch (valueFormat) {
        case "HH": {
          dateTimeOptions.hour = rawValue;
          return DateTime.fromObject(dateTimeOptions);
        }
        case "x":
        default: {
          // javascript use milliseconds
          return DateTime.fromMillis(rawValue * 1000, dateTimeOptions);
        }
      }
    }

    if (isString(rawValue)) {
      return DateTime.fromFormat(
        rawValue as string,
        valueFormat,
        dateTimeOptions,
      );
    }
  }

  if (isDate(rawValue)) {
    return DateTime.fromJSDate(rawValue, dateTimeOptions);
  }

  return DateTime.fromISO(rawValue.toString(), dateTimeOptions);
};

const formatDateOptionsDefault = {
  timezone: "utc",
  defaultValue: "",
};

export type formatDateOptions = {
  // value: string | number | Date;
  fromFormat?: string;
  toFormat: string | Intl.DateTimeFormatOptions;
  timezone?: string;
  defaultValue?: string;
  showRelative?: boolean;
};

export type DateToRelativeOptions = ToRelativeOptions & {
  fromFormat?: string;
  timezone?: string;
  defaultValue?: string;
};

export const formatDate = (
  value: string | number | Date | null | undefined,
  options: formatDateOptions,
): string | undefined => {
  options = { ...formatDateOptionsDefault, ...options };
  const { defaultValue, fromFormat, toFormat, timezone, showRelative } =
    options;
  if (value == null) {
    return defaultValue;
  }
  const dateTime = valueToDateTime(value, fromFormat, timezone);
  if (!dateTime?.isValid) {
    return defaultValue;
  }

  const formattedDate = isString(toFormat)
    ? dateTime.toFormat(toFormat)
    : dateTime.toLocaleString(toFormat as any);

  if (showRelative) {
    return `${formattedDate} (${dateTime.toRelative({})})`;
  }

  return formattedDate;
};

export const toRelative = (
  value: string | number | Date,
  options: DateToRelativeOptions = {},
): string => {
  options = { ...formatDateOptionsDefault, ...options };
  const { defaultValue, fromFormat, timezone, ...toRelativeOptions } = options;
  const defaultReturnValue = defaultValue || "";
  if (value == null) {
    return defaultReturnValue;
  }
  const dateTime = valueToDateTime(value, fromFormat, timezone);
  if (!dateTime || !dateTime.isValid) {
    return defaultReturnValue;
  }

  return dateTime?.toRelative(toRelativeOptions) || defaultReturnValue;
};

const FormatCurrencyOptionsDefault = {
  symbol: "$",
  digits: 2,
};

export type FormatCurrencyOptions = {
  // value: number | string | null;
  symbol?: string;
  digits?: number;
};

export const formatCurrency = (
  value: number | string | null,
  options: FormatCurrencyOptions = {},
): string | null => {
  options = { ...FormatCurrencyOptionsDefault, ...options };
  const { symbol, digits } = options;

  return Number.isSafeInteger(value)
    ? `${symbol}${Number(value).toFixed(digits)}`
    : null;
};

export const isEmptyChildren = (children: any): boolean => {
  return React.Children.count(children) === 0;
};

export const modulo = (n: number, m: number): number => {
  return ((n % m) + m) % m;
};

export const convertDuration = (
  value: string | number | null | undefined,
  from?: DurationUnit,
  to?: DurationUnit,
): string | number | null => {
  if (value == null) {
    return null;
  }

  if (from == null || to == null || from === to) {
    return value;
  }

  if (isString(value)) {
    // no conversion needed
    return value;
  }

  const durationObject = {};
  (durationObject as DurationObject)[from] = value;
  const duration = Duration.fromObject(durationObject);

  return duration.as(to);
};

export async function supportedAPIVersion(
  stackDomain: string,
  minVersion: string,
) {
  try {
    const url = `${transformStackDomain(stackDomain)}api/version`;
    const res = await fetch(url);
    const response = await res.json();
    const {
      meta: { version },
    } = response;

    return semver.gte(minVersion, version);
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function endOfDay(date: Date, parseDate = false) {
  const newDate = parseDate ? new Date(date) : date;
  newDate.setUTCHours(23, 59, 59, 999);
  return newDate;
}

export function startOfDay(date: Date, parseDate = false) {
  const newDate = parseDate ? new Date(date) : date;
  newDate.setUTCHours(0, 0, 0, 0);
  return newDate;
}

export const getDateBasedOnDayStart = (
  dayStart: string,
  timeZone: string | undefined | null,
  date: string | null | undefined,
) => {
  if (!date) {
    return null;
  }
  const dayStartDateTime = DateTime.fromFormat(
    dayStart,
    GraphQLDateFormat,
  ).toUTC();

  const hour = dayStartDateTime.get("hour");
  const minute = dayStartDateTime.get("minute");

  const dateTime = DateTime.fromJSDate(new Date(date));

  // If there is no timezone, apply 00+00 offset (UTC)
  return dateTime.setZone(timeZone ?? "utc").set({
    hour,
    minute,
  });
};

export const isEmptyString = (str: string) => {
  return str.trim() === "";
};
