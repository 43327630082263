import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLazyLoadQuery, useMutation } from "react-relay";
import * as yup from "yup";
import { FormikContextType, FormikHelpers } from "formik";
import Loader from "../../common/Loader";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../common/Portal/HeaderPortal";
import properties from "../../../data/csv-settings/performance-settings.json";
import DynamicInputGroup from "../../common/Form/DynamicInputGroup";
import FormLayout from "../../common/Form/FormLayout";
import FormLayoutFooter from "../../common/Form/FormLayoutFooter";
import { getSettingsByGroup } from "../../common/Form/formUtilities";
import Switch from "../../common/Form/Switch";
import { IProperty, repeatMonthsOptions } from "../../common/Form/models";
import {
  GetBusinessPerformanceSettingsQuery,
  UpdateBusinessPerformanceSettingsMutation,
} from "./PerformanceSettingsQueries";
import { BusinessId } from "../../../data/models/common";
import {
  PerformanceSettingsQueries_PerformanceSettingsQuery,
  PerformanceSettingsQueries_PerformanceSettingsQueryResponse,
} from "./__generated__/PerformanceSettingsQueries_PerformanceSettingsQuery.graphql";
import { getHandleServerValidationErrorFn } from "../../../utils/utility";
import Checkbox from "../../common/Form/Checkbox";
import { useAppRouter } from "../../../hooks/useAppRouter";
import DynamicSelect from "../../common/Form/DynamicSelect";

// Hacky types to avoid false nullability in graphql typings
type PerformanceSettingsQueryResponses = Exclude<
  PerformanceSettingsQueries_PerformanceSettingsQueryResponse["businesses"]["edges"],
  null
>;
type PerformanceSettingsQueryResponse = Exclude<
  PerformanceSettingsQueryResponses[number],
  null
>;
export type PerformanceSettingResponse = Exclude<
  PerformanceSettingsQueryResponse["node"],
  null
>;

export default function PerformanceSettings() {
  const { t } = useTranslation("translation");
  const {
    params: { business_id },
  } = useAppRouter<{ business_id: string }>();
  const business = useBusinessPerformanceSettingsQuery(business_id);
  const filteredProperties = properties;

  const componentRules = {
    employmentPerformanceReviewDetail: {
      hideLabel: true,
      hideDescription: true,
    },
    approvalRequests: {
      hideLabel: true,
      hideDescription: true,
    },
    "approvalRequests.employmentPerformanceReview": {
      hideLabel: true,
      hideDescription: true,
    },
    "approvalRequests.employmentPerformanceReview.autoApprovalEnabled": {
      disabled: (values: PerformanceSettingResponse) =>
        !values.employmentPerformanceReviewEnabled,
    },
    "employmentPerformanceReviewDetail.autoPublish": {
      disabled: (values: PerformanceSettingResponse) =>
        !values.employmentPerformanceReviewEnabled,
    },
    "employmentPerformanceReviewDetail.adjustJtc": {
      disabled: (values: PerformanceSettingResponse) =>
        !values.employmentPerformanceReviewEnabled,
    },
    "employmentPerformanceReviewDetail.reviewDueReminderDays": {
      disabled: (values: PerformanceSettingResponse) =>
        !values.employmentPerformanceReviewEnabled,
    },
    "employmentPerformanceReviewDetail.regularReviewStartDate": {
      disabled: (values: PerformanceSettingResponse) =>
        !values.employmentPerformanceReviewEnabled,
    },
    "employmentPerformanceReviewDetail.initialReviewEnabled": {
      disabled: (values: PerformanceSettingResponse) =>
        !values.employmentPerformanceReviewEnabled,
    },
    "employmentPerformanceReviewDetail.initialReviewPeriodMonths": {
      disabled: (values: PerformanceSettingResponse) =>
        !values.employmentPerformanceReviewEnabled,
    },
    "employmentPerformanceReviewDetail.adhocReviewEnabled": {
      disabled: (values: PerformanceSettingResponse) =>
        !values.employmentPerformanceReviewEnabled,
    },
    "employmentPerformanceReviewDetail.regularReviewPeriodMonths": {
      component: DynamicSelect,
      disabled: (values: PerformanceSettingResponse) =>
        !values.employmentPerformanceReviewEnabled,
      componentProps: {
        options: repeatMonthsOptions,
        defaultValue: null,
      },
    },
    employmentPerformanceReviewEnabled: {
      component: Switch,
      onValueChanged: (
        value: boolean,
        formikContext: FormikContextType<FormData>,
      ) => {
        if (!value) {
          formikContext.setFieldValue(
            "approvalRequests.employmentPerformanceReview.autoApprovalEnabled",
            false,
          );
          formikContext.setFieldValue(
            "employmentPerformanceReviewDetail.adjustJtc",
            false,
          );
        }
      },
      label: undefined,
      hideLabel: true,
      defaultValue: false,
      componentProps: {
        boldLabels: true,
        onLabel: t("switch.enable"),
        offLabel: t("switch.disable"),
      },
    },
    commendationEnabled: {
      component: Switch,
      label: undefined,
      hideLabel: true,
      defaultValue: false,
      componentProps: {
        boldLabels: true,
        onLabel: t("switch.enable"),
        offLabel: t("switch.disable"),
      },
    },
    disciplinaryActionEnabled: {
      component: Switch,
      label: undefined,
      hideLabel: true,
      defaultValue: true,
      componentProps: {
        boldLabels: true,
        onLabel: t("switch.enable"),
        offLabel: t("switch.disable"),
      },
    },
    approvalNeededForCommendation: {
      component: Checkbox,
      hideLabel: true,
      componentProps: {
        toBoolean: (v: boolean) => !v,
        fromBoolean: (v: boolean) => !v,
      },
      disabled: (values: PerformanceSettingResponse) =>
        !values.commendationEnabled,
    },
    commendationEmploymentNotifications: {
      disabled: (values: PerformanceSettingResponse) =>
        !values.commendationEnabled,
    },
    approvalNeededForDisciplinaryAction: {
      component: Checkbox,
      hideLabel: true,
      componentProps: {
        toBoolean: (v: boolean) => !v,
        fromBoolean: (v: boolean) => !v,
      },
      disabled: (values: PerformanceSettingResponse) =>
        !values.disciplinaryActionEnabled,
    },
    disciplinaryActionEmploymentNotifications: {
      disabled: (values: PerformanceSettingResponse) =>
        !values.disciplinaryActionEnabled,
    },
  };

  const validationRules = yup.object().shape(
    {
      feedbackCommentEnabled: yup
        .boolean()
        .label(t("feedback-reasons:settings.reason"))
        .when("feedbackReasonEnabled", {
          is: (val) => val !== true,
          then: yup
            .boolean()
            .test(
              "one_must_be_true",
              t("feedback-reasons:reason_comment_error"),
              (val) => val === true,
            ),
        }),
      employmentPerformanceReviewDetail: yup
        .object()
        .when("employmentPerformanceReviewEnabled", {
          is: true,
          then: yup
            .object({
              reviewDueReminderDays: yup.number().integer().min(0).max(30),
              regularReviewPeriodMonths: yup.number().integer().min(3).max(12),
              adjustJtc: yup
                .boolean()
                .test(
                  "must_be_true",
                  t("employment-performance-reviews:edit_rate_disabled"),
                  (val) => {
                    if (val === false) {
                      return true;
                    }
                    return business.employmentRateEditEnabled;
                  },
                ),
              initialReviewPeriodMonths: yup
                .number()
                .integer()
                .max(
                  yup.ref("regularReviewPeriodMonths"),
                  t(
                    "employment-performance-reviews:initial_period_greater_error",
                  ),
                ),
            })
            .label("employmentPerformanceReviewEnabled"),
        }),
      approvalRequests: yup.object().shape({
        employmentPerformanceReview: yup.object().shape({
          autoApprovalEnabled: yup
            .boolean()
            .label(t("feedback-reasons:settings.comments"))
            .test(
              "must_be_true",
              t("employment-performance-reviews:auto_approval_error"),
              (val) => {
                if (val === false) {
                  return true;
                }
                return business.approvalRequests.employmentRateChange
                  .autoApprovalEnabled;
              },
            ),
        }),
      }),
      feedbackReasonEnabled: yup
        .boolean()
        .label(t("feedback-reasons:settings.comments"))
        .when("feedbackCommentEnabled", {
          is: (val) => val !== true,
          then: yup
            .boolean()
            .test(
              "one_must_be_true",
              t("feedback-reasons:reason_comment_error"),
              (val) => val === true,
            ),
        }),
    },
    [["feedbackReasonEnabled", "feedbackCommentEnabled"]],
  );

  const [updatePerformanceSettingsMutation] = useMutation(
    UpdateBusinessPerformanceSettingsMutation,
  );

  const onSave = (
    changes: Partial<PerformanceSettingResponse>,
    errorHandler: (error: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: PerformanceSettingResponse,
    helpers?: FormikHelpers<PerformanceSettingResponse>,
  ) => {
    updatePerformanceSettingsMutation({
      variables: {
        id: business.id,
        input: changes,
      },
      onCompleted(response: any) {
        toast(t("form.notifications.saved_successfully"));
      },
      onError(error: Error) {
        if (!helpers) {
          return;
        }
        getHandleServerValidationErrorFn(helpers, {
          excludeLabelInError: true,
        })(error);
      },
    });
  };

  return (
    <>
      <HeaderPortal as="span">
        <HeaderPortalBreadcrumbs
          breadcrumbs={[
            <span>{t("businesses:layout.nav.performanceSettings")}</span>,
          ]}
        />
      </HeaderPortal>
      <Suspense fallback={<Loader />}>
        <FormLayout<PerformanceSettingResponse>
          isCreate={false}
          base={business}
          propertyList={[]}
          componentRules={componentRules}
          validationRules={validationRules}
          onSave={onSave}
        >
          <Card body className="mb-5">
            <DynamicInputGroup
              fields={getSettingsByGroup(filteredProperties as IProperty[])}
            />
          </Card>
          <FormLayoutFooter />
        </FormLayout>
      </Suspense>
    </>
  );
}

export function useBusinessPerformanceSettingsQuery(
  businessId: BusinessId = "",
): PerformanceSettingResponse {
  const query =
    useLazyLoadQuery<PerformanceSettingsQueries_PerformanceSettingsQuery>(
      GetBusinessPerformanceSettingsQuery,
      {
        businessId,
      },
      { fetchPolicy: "network-only" },
    );

  return query.businesses.edges[0]?.node ?? ({} as PerformanceSettingResponse);
}
