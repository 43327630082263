/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type StackContextLayout_QueryVariables = {
    businessId: string;
};
export type StackContextLayout_QueryResponse = {
    readonly businesses: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly businessName: string;
            readonly aggregateToStartDay: boolean;
            readonly aosGroups: ReadonlyArray<{
                readonly group: number | null;
                readonly name: string | null;
            }> | null;
            readonly aosConfigSchema: unknown;
            readonly dataLakeId: string | null;
            readonly dynamicFieldsLayout: {
                readonly views: ReadonlyArray<{
                    readonly name: string;
                    readonly groups: ReadonlyArray<string>;
                }>;
                readonly groups: ReadonlyArray<{
                    readonly name: string;
                    readonly label: string;
                    readonly fields: ReadonlyArray<{
                        readonly metadataTypeName: string;
                        readonly width: number;
                    }>;
                }>;
            };
            readonly analyticsEnabled: boolean;
            readonly contractsUseEmploymentType: boolean;
            readonly defaultScheduleDayStartTime: string;
            readonly shiftCanOverlapLeaveRequest: boolean;
            readonly shiftCanOverlapUnavailability: boolean;
            readonly disciplinaryActionEnabled: boolean;
            readonly commendationEnabled: boolean;
            readonly jobTitlesEnabled: boolean;
        }>;
    };
};
export type StackContextLayout_Query = {
    readonly response: StackContextLayout_QueryResponse;
    readonly variables: StackContextLayout_QueryVariables;
};



/*
query StackContextLayout_Query(
  $businessId: ID!
) {
  businesses(ids: [$businessId]) {
    nodes {
      id
      businessName
      aggregateToStartDay
      aosGroups {
        group
        name
      }
      aosConfigSchema
      dataLakeId
      dynamicFieldsLayout {
        views {
          name
          groups
        }
        groups {
          name
          label
          fields {
            metadataTypeName
            width
          }
        }
      }
      analyticsEnabled
      contractsUseEmploymentType
      defaultScheduleDayStartTime
      shiftCanOverlapLeaveRequest
      shiftCanOverlapUnavailability
      disciplinaryActionEnabled
      commendationEnabled
      jobTitlesEnabled
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "businessId"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "concreteType": "BusinessConnection",
    "kind": "LinkedField",
    "name": "businesses",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Business",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aggregateToStartDay",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AosGroup",
            "kind": "LinkedField",
            "name": "aosGroups",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "group",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosConfigSchema",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataLakeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DynamicFieldsLayout",
            "kind": "LinkedField",
            "name": "dynamicFieldsLayout",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DynamicFieldsLayoutView",
                "kind": "LinkedField",
                "name": "views",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "groups",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DynamicFieldsLayoutGroup",
                "kind": "LinkedField",
                "name": "groups",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DynamicFieldsLayoutGroupField",
                    "kind": "LinkedField",
                    "name": "fields",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "metadataTypeName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "analyticsEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contractsUseEmploymentType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultScheduleDayStartTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftCanOverlapLeaveRequest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftCanOverlapUnavailability",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disciplinaryActionEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commendationEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitlesEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StackContextLayout_Query",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StackContextLayout_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a9d20ef16a274a65ef25968c8d31745d",
    "id": null,
    "metadata": {},
    "name": "StackContextLayout_Query",
    "operationKind": "query",
    "text": "query StackContextLayout_Query(\n  $businessId: ID!\n) {\n  businesses(ids: [$businessId]) {\n    nodes {\n      id\n      businessName\n      aggregateToStartDay\n      aosGroups {\n        group\n        name\n      }\n      aosConfigSchema\n      dataLakeId\n      dynamicFieldsLayout {\n        views {\n          name\n          groups\n        }\n        groups {\n          name\n          label\n          fields {\n            metadataTypeName\n            width\n          }\n        }\n      }\n      analyticsEnabled\n      contractsUseEmploymentType\n      defaultScheduleDayStartTime\n      shiftCanOverlapLeaveRequest\n      shiftCanOverlapUnavailability\n      disciplinaryActionEnabled\n      commendationEnabled\n      jobTitlesEnabled\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '15d1c6cbb38f3234f2046c1cb19b3629';
export default node;
