/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EmploymentFeedbackTypeEnum = "commendation" | "disciplinary_action" | "%future added value";
export type FeedbackReasonsQueries_EmploymentFeedbackReasonFragment = {
    readonly id: string;
    readonly feedbackType: EmploymentFeedbackTypeEnum;
    readonly description: string;
    readonly defaultMessage: string | null;
    readonly deleted: boolean;
    readonly " $refType": "FeedbackReasonsQueries_EmploymentFeedbackReasonFragment";
};
export type FeedbackReasonsQueries_EmploymentFeedbackReasonFragment$data = FeedbackReasonsQueries_EmploymentFeedbackReasonFragment;
export type FeedbackReasonsQueries_EmploymentFeedbackReasonFragment$key = {
    readonly " $data"?: FeedbackReasonsQueries_EmploymentFeedbackReasonFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"FeedbackReasonsQueries_EmploymentFeedbackReasonFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackReasonsQueries_EmploymentFeedbackReasonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedbackType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deleted",
      "storageKey": null
    }
  ],
  "type": "EmploymentFeedbackReason",
  "abstractKey": null
};
(node as any).hash = '1a83af31d205cffc0e0d78600c57e899';
export default node;
