import { graphql, useLazyLoadQuery } from "react-relay";
import { fetchQuery } from "relay-runtime";
import {
  CorporatesQueries_GetCorporates_InternalQuery,
  CorporatesQueries_GetCorporates_InternalQueryResponse,
  CorporatesQueries_GetCorporates_InternalQueryVariables,
} from "./__generated__/CorporatesQueries_GetCorporates_InternalQuery.graphql";
import { CorporatesQueries_GetCorporatesById_InternalQuery } from "./__generated__/CorporatesQueries_GetCorporatesById_InternalQuery.graphql";
import {
  CorporatesQueries_GetCorporatesByName_InternalQuery,
  CorporatesQueries_GetCorporatesByName_InternalQueryResponse,
} from "./__generated__/CorporatesQueries_GetCorporatesByName_InternalQuery.graphql";
import { idmInternalEnvironment } from "../../../../environment";

type NodeType =
  {} & CorporatesQueries_GetCorporates_InternalQueryResponse["corporates"]["nodes"];
export type Corporate = Exclude<NodeType[number], null>;

export const UserCorporatesQuery = graphql`
  query CorporatesQueries_ListQuery(
    $emailIncludes: String
    $corporateIds: [ID!]
    $pageSize: Int!
    $after: String
  ) {
    ...CorporatesQueries_ListFragment
  }
`;

export const UserCorporateFragment = graphql`
  fragment CorporatesQueries_ListFragment on InternalQuery
  @refetchable(queryName: "UserCorporatesPaginationQuery") {
    userCorporates(
      corporateIds: $corporateIds
      emailIncludes: $emailIncludes
      first: $pageSize
      after: $after
    ) @connection(key: "CorporatesTable_userCorporates") {
      edges {
        node {
          id
          user {
            id
            email
          }
          personKey
          lastAccessedAt
          business {
            businessName
          }
          corporate {
            id
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GetCorporatesQuery = graphql`
  query CorporatesQueries_GetCorporates_InternalQuery(
    $stackId: ID
    $nameIncludes: String
  ) {
    corporates(stackId: $stackId, nameIncludes: $nameIncludes) {
      nodes {
        id
        name
        ssoAuthMethods {
          id
          authType
          code
          corporateId
          createdAt
          displayName
          employmentIdAttributes
          employmentAttrToSearch
          metadataConfig
          metadataIssuer
          metadataOverrides
          metadataSyncedAt
          metadataUrl
          updatedAt
        }
        corporateStacks {
          id
          stack {
            id
            stackCode
          }
        }
        createdAt
        updatedAt
        deleted
      }
    }
  }
`;

export const GetCorporatesByIdQuery = graphql`
  query CorporatesQueries_GetCorporatesById_InternalQuery(
    $ids: [ID!]!
    $skip: Boolean!
  ) {
    corporates(ids: $ids) @skip(if: $skip) {
      nodes {
        id
        name
        ssoAuthMethods {
          id
          authType
          code
          corporateId
          createdAt
          displayName
          employmentIdAttributes
          employmentAttrToSearch
          metadataConfig
          metadataIssuer
          metadataOverrides
          metadataSyncedAt
          metadataUrl
          updatedAt
        }
        corporateStacks {
          id
          stack {
            id
            stackCode
          }
        }
        createdAt
        updatedAt
        deleted
      }
    }
  }
`;

export const GetCorporateStacksQuery = graphql`
  query CorporatesQueries_GetCorporateStacks_InternalQuery {
    corporateStacks {
      nodes {
        id
        stackId
        corporateId
        stack {
          stackCode
        }
      }
    }
  }
`;

export const DeleteCorporateMutation = graphql`
  mutation CorporatesQueries_DeleteCorporateMutation_InternalQuery($id: ID!) {
    deleteCorporate(id: $id)
  }
`;

export const UpdateCorporateMutation = graphql`
  mutation CorporatesQueries_UpdateCorporateMutation_InternalQuery(
    $id: ID!
    $input: CorporateUpdateInput!
  ) {
    updateCorporate(id: $id, input: $input) {
      id
      name
      ssoAuthMethods {
        id
        authType
        code
        corporateId
        createdAt
        displayName
        employmentIdAttributes
        employmentAttrToSearch
        metadataConfig
        metadataIssuer
        metadataOverrides
        metadataSyncedAt
        metadataUrl
        updatedAt
      }
      corporateStacks {
        id
        stack {
          id
          stackCode
        }
      }
      createdAt
      updatedAt
      deleted
    }
  }
`;

export const CreateCorporateMutation = graphql`
  mutation CorporatesQueries_CreateCorporateMutationn_InternalQuery(
    $input: CorporateCreateInput!
  ) {
    createCorporate(input: $input) {
      id
      name
      ssoAuthMethods {
        id
        authType
        code
        corporateId
        createdAt
        displayName
        employmentIdAttributes
        employmentAttrToSearch
        metadataConfig
        metadataIssuer
        metadataOverrides
        metadataSyncedAt
        metadataUrl
        updatedAt
      }
      corporateStacks {
        id
        stack {
          id
          stackCode
        }
      }
      createdAt
      updatedAt
      deleted
    }
  }
`;

export const GetCorporatesByNameQuery = graphql`
  query CorporatesQueries_GetCorporatesByName_InternalQuery(
    $nameIncludes: String
    $excludeDeleted: Boolean
  ) {
    corporates(nameIncludes: $nameIncludes, excludeDeleted: $excludeDeleted) {
      nodes {
        id
        name
      }
    }
  }
`;
type GetCorporatesByName_ResponseType =
  {} & CorporatesQueries_GetCorporatesByName_InternalQueryResponse["corporates"];
type GetCorporatesByName_NodeType =
  {} & GetCorporatesByName_ResponseType["nodes"];
export type GetCorporatesByName_Corporate =
  {} & GetCorporatesByName_NodeType[number];

export async function searchCorporatesByName(
  search: string,
  excludeDeleted = true,
) {
  const result =
    await fetchQuery<CorporatesQueries_GetCorporatesByName_InternalQuery>(
      idmInternalEnvironment,
      GetCorporatesByNameQuery,
      {
        nameIncludes: search,
        excludeDeleted,
      },
      {
        fetchPolicy: "store-or-network",
      },
    ).toPromise();

  return result?.corporates?.nodes ?? [];
}

export function useCorporatesLazyLoad(
  corporateVariables?: Partial<CorporatesQueries_GetCorporates_InternalQueryVariables>,
  networkPolicy = false,
) {
  const corporatesQueryData =
    useLazyLoadQuery<CorporatesQueries_GetCorporates_InternalQuery>(
      GetCorporatesQuery,
      corporateVariables ?? {},
      {
        ...(networkPolicy && { fetchPolicy: "network-only" }),
      },
    );

  const corporates = (corporatesQueryData?.corporates?.nodes ??
    []) as Corporate[];

  return [corporates] as const;
}

export function useCorporateById(
  // Will skip the query if corporateId is null/undefined
  corporateId: string | undefined | null,
  networkPolicy = true,
) {
  const corporatesQueryData =
    useLazyLoadQuery<CorporatesQueries_GetCorporatesById_InternalQuery>(
      GetCorporatesByIdQuery,
      {
        ids: [corporateId ?? ""],
        skip: corporateId == null,
      } ?? {},
      {
        ...(networkPolicy && { fetchPolicy: "network-only" }),
      },
    );

  return corporatesQueryData?.corporates?.nodes?.[0] ?? null;
}
