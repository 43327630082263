/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MetadataTypeDataTypeEnum = "boolean" | "date" | "number" | "object" | "string" | "timestamp" | "%future added value";
export type MetadataTypeObjectClassEnum = "Employment" | "%future added value";
export type MetadataTypesQueries_MetaDataTypesQueryVariables = {
    businessId: string;
    search?: string | null | undefined;
};
export type MetadataTypesQueries_MetaDataTypesQueryResponse = {
    readonly metadataTypes: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly displayName: string | null;
            readonly name: string;
            readonly dataType: MetadataTypeDataTypeEnum;
            readonly encrypted: boolean;
            readonly external: boolean;
            readonly internalAccess: boolean;
            readonly isTimeboxed: boolean;
            readonly maximum: number | null;
            readonly minimum: number | null;
            readonly validValues: unknown | null;
            readonly obfuscatedNumber: number | null;
            readonly objectClass: MetadataTypeObjectClassEnum;
            readonly required: boolean;
            readonly description: string | null;
            readonly updatedAt: string;
            readonly createdAt: string;
        }>;
    };
};
export type MetadataTypesQueries_MetaDataTypesQuery = {
    readonly response: MetadataTypesQueries_MetaDataTypesQueryResponse;
    readonly variables: MetadataTypesQueries_MetaDataTypesQueryVariables;
};



/*
query MetadataTypesQueries_MetaDataTypesQuery(
  $businessId: ID!
  $search: String
) {
  metadataTypes(businessId: $businessId, search: $search) {
    nodes {
      id
      displayName
      name
      dataType
      encrypted
      external
      internalAccess
      isTimeboxed
      maximum
      minimum
      validValues
      obfuscatedNumber
      objectClass
      required
      description
      updatedAt
      createdAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "MetadataTypeConnection",
    "kind": "LinkedField",
    "name": "metadataTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetadataType",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "encrypted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "external",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalAccess",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isTimeboxed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maximum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minimum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validValues",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "obfuscatedNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "objectClass",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "required",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetadataTypesQueries_MetaDataTypesQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetadataTypesQueries_MetaDataTypesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "405c1e8614c2af93eb0499b33808c3b6",
    "id": null,
    "metadata": {},
    "name": "MetadataTypesQueries_MetaDataTypesQuery",
    "operationKind": "query",
    "text": "query MetadataTypesQueries_MetaDataTypesQuery(\n  $businessId: ID!\n  $search: String\n) {\n  metadataTypes(businessId: $businessId, search: $search) {\n    nodes {\n      id\n      displayName\n      name\n      dataType\n      encrypted\n      external\n      internalAccess\n      isTimeboxed\n      maximum\n      minimum\n      validValues\n      obfuscatedNumber\n      objectClass\n      required\n      description\n      updatedAt\n      createdAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b7b140f93f3071ed1b2dde5a03ef1815';
export default node;
