import React, { useCallback, useState } from "react";
import {
  FetchPolicy,
  graphql,
  RelayEnvironmentProvider,
  useLazyLoadQuery,
  useMutation,
} from "react-relay";
import { RouteComponentProps, Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { toast } from "react-toastify";
import {
  SkillLevels_InternalQuery,
  SkillLevels_InternalQueryResponse,
} from "./__generated__/SkillLevels_InternalQuery.graphql";
import SetAsDefaultModal from "./Modals/SetAsDefaultModal";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import { useModal } from "../../../contexts/ModalContext";
import {
  RankChangeEnum,
  SkillLevelAssignmentActionEnum,
} from "../../../data/generated/stack_internal_schema";
import ServerError from "../../../utils/server-error";
import Loader from "../../common/Loader";
import { toRelative } from "../../../utils/utility";
import HeaderPortal from "../../common/Portal/HeaderPortal";
import { SkillLevels_ChangeSkillLevelRankMutation } from "./__generated__/SkillLevels_ChangeSkillLevelRankMutation.graphql";

export type SkillLevel =
  SkillLevels_InternalQueryResponse["skillLevels"]["nodes"][number];

const SkillLevelsQuery = graphql`
  query SkillLevels_InternalQuery($businessId: ID!) {
    skillLevels(businessId: $businessId) {
      nodes {
        id
        name
        level
        aosLevel
        assignmentAction
        isDefault
        createdAt
        updatedAt
      }
    }
  }
`;

function SkillLevelsTable(props: {
  refresh: Function;
  queryOptions: any;
  variables: any;
  t: TFunction;
  businessId: string;
  stackId: string;
}) {
  const { refresh, queryOptions, variables, t, businessId, stackId } = props;
  const data = useLazyLoadQuery<SkillLevels_InternalQuery>(
    SkillLevelsQuery,
    variables,
    queryOptions,
  );

  const { environment } = useBusinessContext();
  const { showModal, hideModal } = useModal();
  const [updateRank] =
    useMutation<SkillLevels_ChangeSkillLevelRankMutation>(graphql`
      mutation SkillLevels_ChangeSkillLevelRankMutation(
        $businessId: ID!
        $id: ID!
        $input: RankChangeEnum!
      ) {
        changeSkillLevelRank(businessId: $businessId, id: $id, input: $input) {
          nodes {
            id
          }
        }
      }
    `);

  const showSetAsDefaultConfirmation = (item: SkillLevel) => {
    if (!item || !environment) {
      return;
    }

    showModal(
      <RelayEnvironmentProvider environment={environment}>
        <SetAsDefaultModal
          onClose={hideModal}
          businessId={businessId}
          id={item.id}
          skillLevel={item}
          onSuccess={() => {
            // show growl notification
            toast(t("form.savedSuccessfully"));
            refresh();
          }}
          environment={environment}
        />
      </RelayEnvironmentProvider>,
    );
  };

  const move = (item: SkillLevel, input: RankChangeEnum) => {
    if (!item || !environment) {
      return;
    }

    updateRank({
      variables: {
        businessId,
        id: item.id,
        input,
      },
      onCompleted: () => {
        // show growl notification
        toast(t("form.savedSuccessfully"));
        refresh();
      },
      onError: (e: Error) => {
        const { source } = (e as any) || {};
        if (!source) {
          return;
        }
        const serverError = new ServerError(source);
        alert(serverError.getErrorDetails());
      },
    });
  };

  const skillLevels = data.skillLevels.nodes;

  return (
    <Table hover size="sm">
      <thead>
        <tr>
          <th>{t("table.headers.name")}</th>
          <th>{t("table.headers.skillLevel")}</th>
          <th>{t("table.headers.aosLevel")}</th>
          <th>{t("table.headers.onManualScheduling")}</th>
          <th>{t("table.headers.updatedAt")}</th>
          <th className="w-25">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {skillLevels.map((item, index) => {
          if (item) {
            return (
              <tr key={item.id} className="hoverable">
                <td>
                  <Link
                    to={`/stack/${stackId}/business/${businessId}/skill_level/edit/${item.id}`}
                  >
                    {item.name ?? ""}
                    {item.isDefault && (
                      <Badge variant="secondary" className="ml-2">
                        {t("table.default")}
                      </Badge>
                    )}
                  </Link>
                </td>
                <td>{item.level}</td>
                <td>
                  {item.aosLevel != null
                    ? item.aosLevel
                    : t("table.aosLevel.unused")}
                </td>
                <td>
                  {item.assignmentAction === SkillLevelAssignmentActionEnum.None
                    ? t("table.assignmentType.allow")
                    : item.assignmentAction}
                </td>
                <td>
                  {toRelative(item.updatedAt as string, {
                    defaultValue: "-",
                  })}
                </td>
                <td>
                  <span className="show-inline-on-hover">
                    <Button
                      variant="link"
                      disabled={index === 0}
                      className="ml-1 mr-2"
                      onClick={() => move(item, RankChangeEnum.Decrease)}
                    >
                      {t("table.actions.moveUp")}
                    </Button>
                  </span>
                  <span className="border-left show-inline-on-hover">
                    <Button
                      variant="link"
                      disabled={index === skillLevels.length - 1}
                      className="ml-1 mr-2"
                      onClick={() => move(item, RankChangeEnum.Increase)}
                    >
                      {t("table.actions.moveDown")}
                    </Button>
                  </span>
                  <span className="border-left show-inline-on-hover">
                    <Button
                      variant="link"
                      disabled={item.isDefault}
                      className="ml-1 mr-2"
                      onClick={() => showSetAsDefaultConfirmation(item)}
                    >
                      {t("table.actions.setAsDefaultTemplate")}
                    </Button>
                  </span>
                </td>
              </tr>
            );
          }
          return null;
        })}
      </tbody>
    </Table>
  );
}

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

function SkillLevels({ match }: Props) {
  const { t } = useTranslation("skill-levels");

  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<{
    fetchKey?: string | number;
    fetchPolicy?: FetchPolicy;
  }>({
    fetchPolicy: "network-only",
  });

  const { params } = match;
  const { business_id: businessId, stack_id: stackId } = params;
  const queryVariables = {
    businessId: businessId ?? "",
  };

  const refresh = useCallback(() => {
    // Trigger a re-render of useLazyLoadQuery with the same variables,
    // but an updated fetchKey and fetchPolicy.
    // The new fetchKey will ensure that the query is fully
    // re-evaluated and refetched.
    // The fetchPolicy ensures that we always fetch from the network
    // and skip the local data cache.
    setRefreshedQueryOptions((prev) => ({
      fetchKey: ((prev?.fetchKey ?? 0) as number) + 1,
      fetchPolicy: "network-only",
    }));
  }, []);

  return (
    <>
      <HeaderPortal as="span" elementId="sub-header-portal">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{t("breadcrumb")}</span>
      </HeaderPortal>
      <Row className="mt-2 mb-2">
        <Col>{t("table.info")}</Col>
        <Col md="auto">
          <LinkContainer
            to={`/stack/${stackId}/business/${businessId}/skill_level/create`}
          >
            <Button>{t("table.new")}</Button>
          </LinkContainer>
        </Col>
      </Row>
      <Card body>
        <React.Suspense fallback={<Loader />}>
          <SkillLevelsTable
            refresh={refresh}
            queryOptions={refreshedQueryOptions ?? {}}
            variables={queryVariables}
            businessId={businessId}
            stackId={stackId}
            t={t}
          />
        </React.Suspense>
      </Card>
    </>
  );
}

export default SkillLevels;
