import { graphql, useLazyLoadQuery, useMutation } from "react-relay";
import intersectionBy from "lodash/intersectionBy";
import { useBusinessContext } from "../../../../contexts/BusinessContext";
import {
  MetadataLayoutQueries_GetMetadataTypesForLayouts_Query,
  MetadataLayoutQueries_GetMetadataTypesForLayouts_QueryResponse,
} from "./__generated__/MetadataLayoutQueries_GetMetadataTypesForLayouts_Query.graphql";
import {
  DynamicFieldsLayoutGroupFieldInput,
  DynamicFieldsLayoutViewInput,
  DynamicFieldsLayoutGroup,
  DynamicFieldsLayout,
  DynamicFieldsLayoutView,
  MetadataType,
} from "../../../../data/generated/stack_internal_schema";
import { MetadataLayoutQueries_UpdateBusinessMutation_Mutation } from "./__generated__/MetadataLayoutQueries_UpdateBusinessMutation_Mutation.graphql";

export const GetMetadataTypesForLayouts = graphql`
  query MetadataLayoutQueries_GetMetadataTypesForLayouts_Query(
    $businessId: ID!
  ) {
    metadataTypes(businessId: $businessId) {
      nodes {
        id
        name
        displayName
        required
        dataType
        internalAccess
      }
    }
  }
`;

export const UpdateMetadataLayoutMutation = graphql`
  mutation MetadataLayoutQueries_UpdateBusinessMutation_Mutation(
    $input: BusinessInput!
    $id: ID!
  ) {
    updateBusiness(id: $id, input: $input) {
      id
      businessName
      dynamicFieldsLayout {
        views {
          name
          groups
        }
        groups {
          name
          label
          fields {
            metadataTypeName
            width
          }
        }
      }
    }
  }
`;

/**
 * A modest hook to handle dynamic metadata fields utility function and data management
 * This hook integrates stateful logic (which is why it's a hook not a set of utility functions)
 */
export function useDynamicFieldsLayout() {
  const { business } = useBusinessContext();
  const [updateBusinessMutation] =
    useMutation<MetadataLayoutQueries_UpdateBusinessMutation_Mutation>(
      UpdateMetadataLayoutMutation,
    );

  const layout = business.dynamicFieldsLayout;
  const dynamicFieldsLayout = {
    views: layout.views,
    groups: layout.groups,
  } as DynamicFieldsLayout;

  const getGroupIndexByName = () =>
    new Map<string, number>(
      dynamicFieldsLayout.groups.map((i, index) => [i.name, index]),
    );

  const getViewsForGroup = (group: DynamicFieldsLayoutGroup) => {
    return dynamicFieldsLayout.views.reduce(
      (acc: DynamicFieldsLayoutView[], view: DynamicFieldsLayoutView) => {
        if (view.groups.includes(group.name)) {
          acc.push(view);
        }
        return acc;
      },
      [],
    );
  };

  const getGroupByName = (groupName: string) => {
    return dynamicFieldsLayout.groups.find((group) => group.name === groupName);
  };

  // Updates the dynamicFieldsLayout property and returns the layout
  const updateDynamicFieldsLayout = (
    newLayout: DynamicFieldsLayout,
    onCompleted: () => void,
    onError: (error: Error) => void,
  ) => {
    const newData = {
      ...newLayout,
    };

    updateBusinessMutation({
      variables: {
        id: business.id,
        input: {
          dynamicFieldsLayout: newData,
        },
      },
      onCompleted,
      onError,
    });

    return newLayout;
  };

  const removeGroup = (
    existingGroup: DynamicFieldsLayoutGroup,
    onCompleted: () => void,
    onError: (error: Error) => void,
  ) => {
    if (dynamicFieldsLayout) {
      // Note: When removing a group, we need to ensure that the group is also removed from the groups
      // array inside of a view (or else backend validation error will occur)
      return updateDynamicFieldsLayout(
        {
          views: dynamicFieldsLayout.views.map((view) => ({
            name: view.name,
            groups: view.groups.filter((group) => group !== existingGroup.name),
          })),
          groups: dynamicFieldsLayout.groups.filter(
            (group) => group.name !== existingGroup.name,
          ),
        },
        onCompleted,
        onError,
      );
    }

    return null;
  };

  const getViewsWithUpdatedGroupName = (
    existingGroupName: string | undefined,
    newGroupName: string,
  ) => {
    const { views } = dynamicFieldsLayout;
    if (!existingGroupName) {
      return views;
    }

    return views.map((view) => {
      const groupNameInViewIndex = view.groups.findIndex(
        (viewGroupName) => viewGroupName === existingGroupName,
      );
      const groups = [...view.groups];
      if (groupNameInViewIndex >= 0) {
        groups[groupNameInViewIndex] = newGroupName;
        return {
          ...view,
          groups,
        };
      }
      return view;
    });
  };

  const addGroup = (
    existingGroup: DynamicFieldsLayoutGroup | null | undefined,
    groupToAdd: DynamicFieldsLayoutGroup,
    onCompleted: () => void,
    onError: (error: Error) => void,
  ) => {
    const groups = [...dynamicFieldsLayout.groups];
    const existingIndex =
      existingGroup != null
        ? groups.findIndex((x) => x.name === existingGroup?.name)
        : -1;

    const views = getViewsWithUpdatedGroupName(
      existingGroup?.name,
      groupToAdd.name,
    );

    if (existingGroup && existingIndex >= 0) {
      groups[existingIndex] = {
        ...groups[existingIndex],
        ...groupToAdd,
      };
    } else {
      groups.push(groupToAdd);
    }

    return updateDynamicFieldsLayout(
      {
        views,
        groups,
      },
      onCompleted,
      onError,
    );
  };

  const addMetadataTypeToGroups = (
    metadataTypeName: string,
    groupsToAdd: DynamicFieldsLayoutGroup[],
    onCompleted: () => void,
    onError: (error: Error) => void,
  ) => {
    const t: DynamicFieldsLayoutGroupFieldInput = {
      metadataTypeName,
      width: 4,
    };

    const groups = [...dynamicFieldsLayout.groups];
    const groupIndexByName = getGroupIndexByName();

    groupsToAdd.forEach((i) => {
      const groupIndex = groupIndexByName.get(i.name);
      if (groupIndex !== undefined) {
        const existingGroup = groups[groupIndex];
        const existingField = existingGroup.fields.some(
          (f) => f.metadataTypeName === metadataTypeName,
        );
        if (!existingField) {
          groups[groupIndex] = {
            ...existingGroup,
            ...{
              fields: [...existingGroup.fields, t],
            },
          };
        }
      }
    });

    return updateDynamicFieldsLayout(
      {
        views: dynamicFieldsLayout.views,
        groups,
      },
      onCompleted,
      onError,
    );
  };

  const updateView = (
    viewName: string,
    groups: string[],
    onCompleted: () => void,
    onError: (error: Error) => void,
  ) => {
    const views = dynamicFieldsLayout.views.map((v) => {
      if (v.name === viewName) {
        return {
          ...v,
          groups,
        };
      }
      return v;
    });

    return updateDynamicFieldsLayout(
      {
        views,
        groups: dynamicFieldsLayout.groups,
      },
      onCompleted,
      onError,
    );
  };

  const isMetadataTypeVisibleInGroup = (
    metadataType: Pick<MetadataType, "name">,
    groups: DynamicFieldsLayoutGroup[] = [],
  ) => {
    const { views } = dynamicFieldsLayout;
    return groups.some((group) => {
      const isGroupInAView = views.some((view) =>
        view.groups.includes(group.name),
      );
      return (
        isGroupInAView &&
        group.fields.some(
          (field) => field.metadataTypeName === metadataType.name,
        )
      );
    });
  };

  const isMetadataTypeVisibleInView = (
    metadataType: Pick<MetadataType, "name">,
    views: DynamicFieldsLayoutViewInput[] = [],
  ) => {
    const { groups } = dynamicFieldsLayout;
    return views.some((view) => {
      return groups.some(
        (group) =>
          view.groups.includes(group.name) &&
          group.fields.some(
            (field) => field.metadataTypeName === metadataType.name,
          ),
      );
    });
  };

  // Check if a metadata type is included in a visible group (which is also included in a view)
  const checkMetadataTypeInGroup = (metadataType: MetadataType) => {
    const { groups } = dynamicFieldsLayout;
    return isMetadataTypeVisibleInGroup(metadataType, groups);
  };

  // Using a list of group names, check if each group has any fields with the same name
  const groupsHaveOverlappingFields = (listOfGroups: string[]) => {
    const groups = listOfGroups
      .map((groupName) =>
        dynamicFieldsLayout.groups.find((x) => x.name === groupName),
      )
      .filter((x) => x != null) as (Exclude<
      DynamicFieldsLayoutGroup,
      "fields"
    > & {
      fields: string[];
    })[];

    return groups.some((group, index) =>
      groups
        .filter((x) => x.name !== group.name)
        .some(
          (otherGroup) =>
            intersectionBy(otherGroup.fields, group.fields, "metadataTypeName")
              .length > 0,
        ),
    );
  };

  /**
   * Returns true if the name of a group does not already exist
   * Pass the current group being edited to ensure
   */
  const validateUpdateGroupName = (
    groupNameBeingEdited: string | null | undefined,
    groupName: string,
  ) => {
    return !dynamicFieldsLayout.groups
      .map((group) => group.name)
      .filter((name) => {
        if (!groupNameBeingEdited) {
          return true;
        }
        return name !== groupNameBeingEdited;
      })
      .includes(groupName);
  };

  const getMetadataTypesFromGroupNames = (groupNames: string[]) => {
    const { groups } = dynamicFieldsLayout;
    const groupIndexByName = getGroupIndexByName();
    const fieldsSet = groupNames.reduce((fields, i) => {
      const groupIndex = groupIndexByName.get(i) ?? -1;
      const group = groups?.[groupIndex] ?? null;

      (group?.fields || []).forEach((field) => {
        fields.add(field);
      });

      return fields;
    }, new Set<DynamicFieldsLayoutGroupFieldInput>());

    return Array.from(fieldsSet.values());
  };

  return [
    dynamicFieldsLayout,
    {
      getViewsForGroup,
      getGroupByName,
      addGroup,
      removeGroup,
      addMetadataTypeToGroups,
      updateView,
      checkMetadataTypeInGroup,
      groupsHaveOverlappingFields,
      isMetadataTypeVisibleInGroup,
      isMetadataTypeVisibleInView,
      getMetadataTypesFromGroupNames,
      validateUpdateGroupName,
    },
  ] as const;
}

type ResponseType =
  {} & MetadataLayoutQueries_GetMetadataTypesForLayouts_QueryResponse["metadataTypes"];
type NodeType = {} & ResponseType["nodes"];
export type MetadataTypeForLayout = {} & NodeType[number];

/**
 * A low cut version of the metadata types query, with only the essential data needed
 * to display/handle metadata layout functionalities
 */
export function useMetadataTypesForLayouts() {
  const { business } = useBusinessContext();

  const data =
    useLazyLoadQuery<MetadataLayoutQueries_GetMetadataTypesForLayouts_Query>(
      GetMetadataTypesForLayouts,
      {
        businessId: business.id ?? "",
      },
      {
        fetchPolicy: "network-only",
      },
    );

  const metadataTypes = data.metadataTypes.nodes as MetadataTypeForLayout[];

  const nonInternalMetadataTypesMap = new Map<string, MetadataType>(
    metadataTypes
      .filter((i) => !i.internalAccess)
      .map((i) => [i.name, i as MetadataType]),
  );

  const getMetadataTypesFromFieldLayouts = (
    layoutFields: DynamicFieldsLayoutGroupFieldInput[],
  ) => {
    return layoutFields.map(
      (i) =>
        nonInternalMetadataTypesMap.get(i.metadataTypeName) as MetadataType,
    );
  };

  return [
    metadataTypes,
    {
      getMetadataTypesFromFieldLayouts,
      nonInternalMetadataTypesMap,
    },
  ] as const;
}
