import React, { FunctionComponent, useEffect, useState } from "react";
import * as yup from "yup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";

import { Environment } from "react-relay";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FormLayout from "../../../common/Form/FormLayout";
import DynamicSelect from "../../../common/Form/DynamicSelect";
import { Option } from "../../../../data/models/common";
import { getRegionalStackEnvironment } from "../../../../environment";
import StackService from "./StackService";

import CreateGlobalBusinessStackMutation from "../../../Stack/mutations/CreateGlobalBusinessStackMutation";
import UpdateGlobalBusinessStackMutation from "../../../Stack/mutations/UpdateGlobalBusinessStackMutation";
import DeleteGlobalBusinessStackMutation from "../../../Stack/mutations/DeleteGlobalBusinessStackMutation";
import { Profile_stack } from "./__generated__/Profile_stack.graphql";
import Field from "../../../common/Form/Field";
import { GlobalBusinessStack } from "../../../Stack/Services/BusinessService";
import { UpdateGlobalBusinessStackMutationResponse } from "../../../Stack/mutations/__generated__/UpdateGlobalBusinessStackMutation.graphql";
import { CreateGlobalBusinessStackMutationResponse } from "../../../Stack/mutations/__generated__/CreateGlobalBusinessStackMutation.graphql";

type Props = {
  stack: Profile_stack;
  globalBusinessStack: GlobalBusinessStack | null;
  onSaved: (newGlobalBusinessStack: GlobalBusinessStack | null) => void;
  hideModal: () => void;
};

type FormData = {
  globalBusinessStackDomainName?: string;
  id?: string;
};

const validationRules = yup.object({
  globalBusinessStackDomainName: yup.string(),
});

const SetGlobalBusinessStackModal: FunctionComponent<Props> = (
  props: Props,
) => {
  const { stack, globalBusinessStack, onSaved, hideModal } = props;

  const { t } = useTranslation("stacks");

  const formBase = {
    globalBusinessStackDomainName: globalBusinessStack?.stackDomainName,
    id: globalBusinessStack?.id,
  };

  const [allStacks, setAllStacks] = useState<Option<string>[]>([]);
  const [stackEnvironment, setStackEnvironment] = useState<Environment>();

  const handleSave = (
    changes: Partial<FormData>,
    onError: (err: Error) => void,
    event: React.MouseEvent<HTMLButtonElement> | undefined,
    values: FormData | undefined,
  ) => {
    if (!values || !stackEnvironment) {
      return;
    }

    const { globalBusinessStackDomainName: newDomainName, id } = values;

    if (id != null) {
      if (newDomainName == null) {
        // Delete

        DeleteGlobalBusinessStackMutation(
          stackEnvironment,
          id,
          () => {
            // on saved successfully
            hideModal();
            toast(t("setGlobalBusinessStackDomainNameModal.savedSuccessfully"));
            onSaved(null);
          },
          onError,
        );
        return;
      }

      if (
        formBase.globalBusinessStackDomainName != null &&
        newDomainName != null
      ) {
        // Update
        UpdateGlobalBusinessStackMutation(
          stackEnvironment,
          id,
          {
            stackDomainName: newDomainName,
            isSelf: newDomainName === stack?.domainName,
          },
          (response: UpdateGlobalBusinessStackMutationResponse) => {
            // on saved successfully
            hideModal();
            toast(t("setGlobalBusinessStackDomainNameModal.savedSuccessfully"));
            onSaved(response.updateGlobalBusinessStack);
          },
          onError,
        );

        return;
      }
    }

    if (newDomainName != null) {
      // Create
      CreateGlobalBusinessStackMutation(
        stackEnvironment,
        {
          stackDomainName: newDomainName,
          isSelf: newDomainName === stack?.domainName,
        },
        (response: CreateGlobalBusinessStackMutationResponse) => {
          // on saved successfully
          hideModal();
          toast(t("setGlobalBusinessStackDomainNameModal.savedSuccessfully"));
          onSaved(response.createGlobalBusinessStack);
        },
        onError,
      );
    }
  };

  useEffect(() => {
    async function load() {
      const currentStackEnvironment = stack
        ? getRegionalStackEnvironment(stack.domainName)
        : null;

      const stacks = await StackService.getAllStacks();

      setAllStacks(
        Array.from(stacks?.values() ?? []).map((i) => {
          return { value: i.domainName, label: i.domainName };
        }),
      );

      setStackEnvironment(currentStackEnvironment);
    }

    load();
  }, [stack]);

  return (
    <Modal show onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("setGlobalBusinessStackDomainNameModal.title")}
        </Modal.Title>
      </Modal.Header>

      <FormLayout<FormData>
        base={formBase}
        onSave={handleSave}
        propertyList={[]}
        validationRules={validationRules}
        isCreate
      >
        <Modal.Body>
          <fieldset>
            <Row>
              <Field
                label={t(
                  "setGlobalBusinessStackDomainNameModal.stackDomainName",
                )}
                md={12}
                lg={12}
                fieldKey="globalBusinessStackDomainName"
                component={DynamicSelect}
                componentProps={{
                  options: allStacks,
                  defaultValue: null,
                  isClearable: true,
                }}
              />
            </Row>
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            {t("setGlobalBusinessStackDomainNameModal.cancel")}
          </Button>
          <Button variant="primary" type="submit">
            {t("setGlobalBusinessStackDomainNameModal.set")}
          </Button>
        </Modal.Footer>
      </FormLayout>
    </Modal>
  );
};

export default SetGlobalBusinessStackModal;
