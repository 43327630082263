import React, { useEffect, Suspense } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PreloadedQuery, useQueryLoader } from "react-relay";
import Card from "react-bootstrap/Card";
import HeaderPortal, {
  HeaderPortalBreadcrumbs,
} from "../../../common/Portal/HeaderPortal";
import BreakTypesProfileForm from "./BreakTypesProfileForm";
import {
  GetSingleBusinessBreakTypeQuery,
  GetSingleScheduleBreakTypeQuery,
} from "../BreakTypeQueries";
import { useScheduleBusinessContextCheck } from "../../../../hooks/useScheduleBusinessContextCheck";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import { GetEmploymentTypeConfigsQuery } from "../../EmploymentTypes/EmploymentTypesQueries";

type MatchParams = {
  break_type_id: string;
  schedule_break_type_id?: string;
};

type Props = {
  initialQueryRef: PreloadedQuery<any>;
};

export default function BreakTypesProfile({ initialQueryRef }: Props) {
  const { businessId, scheduleId } = useScheduleBusinessContextCheck();

  const { t } = useTranslation("break-types");

  const {
    params: {
      break_type_id: breakTypeId,
      schedule_break_type_id: scheduleBreakTypeId,
    },
  } = useAppRouter<MatchParams>();

  const [breakTypeQueryRef, loadBreakTypeQuery, disposeQuery] = useQueryLoader(
    GetSingleBusinessBreakTypeQuery,
    initialQueryRef,
  );
  const [
    scheduleBreakTypeQueryRef,
    loadScheduleBreakTypeQuery,
    disposeScheduleBreakTypesQuery,
  ] = useQueryLoader(GetSingleScheduleBreakTypeQuery, initialQueryRef);
  const [
    employmentTypeConfigsQueryRef,
    loadEmploymentTypeConfigsQuery,
    disposeEmploymentTypeConfigsQuery,
  ] = useQueryLoader(GetEmploymentTypeConfigsQuery, initialQueryRef);

  useEffect(() => {
    if (businessId) {
      loadBreakTypeQuery(
        {
          businessId,
          id: breakTypeId ?? "",
          // skip if we're creating a new leave type
          skip: breakTypeId == null,
        },
        {
          fetchPolicy: "network-only",
        },
      );
      loadScheduleBreakTypeQuery(
        {
          businessId,
          id: scheduleBreakTypeId ?? "",
          scheduleId: scheduleId ?? "",
          // skip if we're creating a new leave type
          skip: scheduleId == null || breakTypeId == null,
        },
        {
          fetchPolicy: "network-only",
        },
      );
      loadEmploymentTypeConfigsQuery(
        {
          businessId,
          deleted: false,
        },
        {
          fetchPolicy: "network-only",
        },
      );
    }
    return () => {
      disposeQuery();
      disposeScheduleBreakTypesQuery();
      disposeEmploymentTypeConfigsQuery();
    };
  }, [
    breakTypeId,
    businessId,
    loadBreakTypeQuery,
    disposeQuery,
    scheduleId,
    loadScheduleBreakTypeQuery,
    disposeScheduleBreakTypesQuery,
    scheduleBreakTypeId,
    loadEmploymentTypeConfigsQuery,
    disposeEmploymentTypeConfigsQuery,
  ]);

  return (
    <Suspense
      fallback={
        <HeaderPortal as="span" elementId="sub-header-portal">
          <HeaderPortalBreadcrumbs
            breadcrumbs={[
              <Link to="#">
                <span>{t("nav.breakTypes")}</span>
              </Link>,
            ]}
          />
        </HeaderPortal>
      }
    >
      {breakTypeQueryRef != null &&
        scheduleBreakTypeQueryRef != null &&
        employmentTypeConfigsQueryRef != null && (
          <Card body>
            <BreakTypesProfileForm
              queryReferences={{
                breakTypes: breakTypeQueryRef,
                scheduleBreakTypes: scheduleBreakTypeQueryRef,
                employmentTypeConfigs: employmentTypeConfigsQueryRef,
              }}
              breakTypeId={breakTypeId}
              scheduleBreakTypeId={scheduleBreakTypeId}
            />
          </Card>
        )}
    </Suspense>
  );
}
