import React, { useEffect } from "react";
import { Environment } from "react-relay";
import {
  AppContextValue,
  useAppContext,
} from "../components/IDM/external/Context/AppContext";
import { RelayEnvironmentConfig, idmExternalEnvironment } from "../environment";
import { StackContextLayout_QueryResponse } from "../components/Stack/__generated__/StackContextLayout_Query.graphql";

export type BusinessInContext =
  StackContextLayout_QueryResponse["businesses"]["nodes"][number];

export type BusinessContextValue = {
  business: BusinessInContext;
  appContext: AppContextValue;
  environment: Environment;
};

const BusinessContext = React.createContext<BusinessContextValue>({
  business: {} as BusinessInContext,
  appContext: {
    setStackId: () => undefined,
    loadMyStacks: () => undefined,
  },
  environment: idmExternalEnvironment,
});

type Props = {
  business: BusinessInContext | undefined;
  environment: Environment;
  children: React.ReactNode;
};

function BusinessContextProvider(props: Props) {
  const appContext = useAppContext();

  useEffect(() => {
    RelayEnvironmentConfig.BUSINESS_ID = props.business?.id;
  }, [props.business]);

  return (
    <BusinessContext.Provider
      value={{
        business:
          props.business ?? ({ id: null } as unknown as BusinessInContext),
        appContext,
        environment: props.environment,
      }}
    >
      {props.children}
    </BusinessContext.Provider>
  );
}

const useBusinessContext = () => React.useContext(BusinessContext);

export { BusinessContext, BusinessContextProvider, useBusinessContext };
