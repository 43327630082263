/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PerformanceSettingsQueries_PerformanceSettingsQueryVariables = {
    businessId: string;
};
export type PerformanceSettingsQueries_PerformanceSettingsQueryResponse = {
    readonly businesses: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly employmentPerformanceReviewEnabled: boolean;
                readonly employmentPerformanceReviewDetail: {
                    readonly autoPublish: boolean;
                    readonly adjustJtc: boolean;
                    readonly reviewDueReminderDays: unknown;
                    readonly regularReviewStartDate: unknown;
                    readonly regularReviewPeriodMonths: number;
                    readonly initialReviewEnabled: boolean;
                    readonly initialReviewPeriodMonths: number;
                    readonly adhocReviewEnabled: boolean;
                };
                readonly approvalRequests: {
                    readonly employmentPerformanceReview: {
                        readonly autoApprovalEnabled: boolean;
                    };
                    readonly employmentRateChange: {
                        readonly autoApprovalEnabled: boolean;
                    };
                };
                readonly employmentRateEditEnabled: boolean;
                readonly feedbackReasonEnabled: boolean;
                readonly feedbackCommentEnabled: boolean;
                readonly commendationEnabled: boolean;
                readonly approvalNeededForCommendation: boolean;
                readonly commendationEmploymentNotifications: boolean;
                readonly disciplinaryActionEnabled: boolean;
                readonly approvalNeededForDisciplinaryAction: boolean;
                readonly disciplinaryActionEmploymentNotifications: boolean;
            };
        }>;
    };
};
export type PerformanceSettingsQueries_PerformanceSettingsQuery = {
    readonly response: PerformanceSettingsQueries_PerformanceSettingsQueryResponse;
    readonly variables: PerformanceSettingsQueries_PerformanceSettingsQueryVariables;
};



/*
query PerformanceSettingsQueries_PerformanceSettingsQuery(
  $businessId: ID!
) {
  businesses(ids: [$businessId]) {
    edges {
      node {
        id
        employmentPerformanceReviewEnabled
        employmentPerformanceReviewDetail {
          autoPublish
          adjustJtc
          reviewDueReminderDays
          regularReviewStartDate
          regularReviewPeriodMonths
          initialReviewEnabled
          initialReviewPeriodMonths
          adhocReviewEnabled
        }
        approvalRequests {
          employmentPerformanceReview {
            autoApprovalEnabled
          }
          employmentRateChange {
            autoApprovalEnabled
          }
        }
        employmentRateEditEnabled
        feedbackReasonEnabled
        feedbackCommentEnabled
        commendationEnabled
        approvalNeededForCommendation
        commendationEmploymentNotifications
        disciplinaryActionEnabled
        approvalNeededForDisciplinaryAction
        disciplinaryActionEmploymentNotifications
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "autoApprovalEnabled",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "businessId"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "concreteType": "BusinessConnection",
    "kind": "LinkedField",
    "name": "businesses",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Business",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentPerformanceReviewEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EmploymentPerformanceReviewDetail",
                "kind": "LinkedField",
                "name": "employmentPerformanceReviewDetail",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "autoPublish",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adjustJtc",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reviewDueReminderDays",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regularReviewStartDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "regularReviewPeriodMonths",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "initialReviewEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "initialReviewPeriodMonths",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "adhocReviewEnabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ApprovalRequestsConfig",
                "kind": "LinkedField",
                "name": "approvalRequests",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmploymentPerformanceReviewRequestConfig",
                    "kind": "LinkedField",
                    "name": "employmentPerformanceReview",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EmploymentRateChangeRequestConfig",
                    "kind": "LinkedField",
                    "name": "employmentRateChange",
                    "plural": false,
                    "selections": (v1/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentRateEditEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedbackReasonEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedbackCommentEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commendationEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalNeededForCommendation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commendationEmploymentNotifications",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disciplinaryActionEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalNeededForDisciplinaryAction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disciplinaryActionEmploymentNotifications",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceSettingsQueries_PerformanceSettingsQuery",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PerformanceSettingsQueries_PerformanceSettingsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "908390cd3ec19fd6cbbd24bd81f8c89e",
    "id": null,
    "metadata": {},
    "name": "PerformanceSettingsQueries_PerformanceSettingsQuery",
    "operationKind": "query",
    "text": "query PerformanceSettingsQueries_PerformanceSettingsQuery(\n  $businessId: ID!\n) {\n  businesses(ids: [$businessId]) {\n    edges {\n      node {\n        id\n        employmentPerformanceReviewEnabled\n        employmentPerformanceReviewDetail {\n          autoPublish\n          adjustJtc\n          reviewDueReminderDays\n          regularReviewStartDate\n          regularReviewPeriodMonths\n          initialReviewEnabled\n          initialReviewPeriodMonths\n          adhocReviewEnabled\n        }\n        approvalRequests {\n          employmentPerformanceReview {\n            autoApprovalEnabled\n          }\n          employmentRateChange {\n            autoApprovalEnabled\n          }\n        }\n        employmentRateEditEnabled\n        feedbackReasonEnabled\n        feedbackCommentEnabled\n        commendationEnabled\n        approvalNeededForCommendation\n        commendationEmploymentNotifications\n        disciplinaryActionEnabled\n        approvalNeededForDisciplinaryAction\n        disciplinaryActionEmploymentNotifications\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd852f50b083928f8293315d195f3e110';
export default node;
