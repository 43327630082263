/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type FixedShiftRestrictionEnum = "not_applicable" | "optional" | "required" | "%future added value";
export type EmploymentTypeConfigCreateInput = {
    code?: string | null | undefined;
    deleted?: boolean | null | undefined;
    employmentTypeCode: string;
    fixedShiftRestriction?: FixedShiftRestrictionEnum | null | undefined;
    gmhEnabled?: boolean | null | undefined;
    gmhMinimumDurationError?: unknown | null | undefined;
    gmhMinimumDurationWarning?: unknown | null | undefined;
    isDefault?: boolean | null | undefined;
    name: string;
    shortName: string;
    timeClockName: string;
    timeOffComplianceEnabled?: boolean | null | undefined;
};
export type EmploymentTypesQueries_CreateEmploymentTypeConfig_MutationVariables = {
    businessId: string;
    input: EmploymentTypeConfigCreateInput;
};
export type EmploymentTypesQueries_CreateEmploymentTypeConfig_MutationResponse = {
    readonly createEmploymentTypeConfig: {
        readonly id: string;
        readonly code: string | null;
        readonly deleted: boolean;
        readonly employmentTypeCode: string;
        readonly fixedShiftRestriction: FixedShiftRestrictionEnum;
        readonly gmhEnabled: boolean;
        readonly gmhMinimumDurationError: unknown | null;
        readonly gmhMinimumDurationWarning: unknown | null;
        readonly isDefault: boolean;
        readonly name: string;
        readonly shortName: string;
        readonly smallId: number;
        readonly timeClockName: string;
        readonly timeOffComplianceEnabled: boolean;
    };
};
export type EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation = {
    readonly response: EmploymentTypesQueries_CreateEmploymentTypeConfig_MutationResponse;
    readonly variables: EmploymentTypesQueries_CreateEmploymentTypeConfig_MutationVariables;
};



/*
mutation EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation(
  $businessId: ID!
  $input: EmploymentTypeConfigCreateInput!
) {
  createEmploymentTypeConfig(businessId: $businessId, input: $input) {
    id
    code
    deleted
    employmentTypeCode
    fixedShiftRestriction
    gmhEnabled
    gmhMinimumDurationError
    gmhMinimumDurationWarning
    isDefault
    name
    shortName
    smallId
    timeClockName
    timeOffComplianceEnabled
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmploymentTypeConfig",
    "kind": "LinkedField",
    "name": "createEmploymentTypeConfig",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentTypeCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fixedShiftRestriction",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gmhEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gmhMinimumDurationError",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gmhMinimumDurationWarning",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDefault",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "smallId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeClockName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeOffComplianceEnabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d911ce37c14fde2dc770ebc4f5a6148b",
    "id": null,
    "metadata": {},
    "name": "EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmploymentTypesQueries_CreateEmploymentTypeConfig_Mutation(\n  $businessId: ID!\n  $input: EmploymentTypeConfigCreateInput!\n) {\n  createEmploymentTypeConfig(businessId: $businessId, input: $input) {\n    id\n    code\n    deleted\n    employmentTypeCode\n    fixedShiftRestriction\n    gmhEnabled\n    gmhMinimumDurationError\n    gmhMinimumDurationWarning\n    isDefault\n    name\n    shortName\n    smallId\n    timeClockName\n    timeOffComplianceEnabled\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fbc4922f17094ae68c0cb965da277ca0';
export default node;
