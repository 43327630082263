/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CurrencyEnum = "aud" | "cad" | "eur" | "gbp" | "nzd" | "usd" | "%future added value";
export type EmploymentRateEditPastEnum = "disabled" | "enabled" | "%future added value";
export type SwapDropFilteringEnum = "default" | "jtc" | "%future added value";
export type SwapSortingEnum = "default" | "skill_level" | "%future added value";
export type Profile_business = {
    readonly id: string;
    readonly timeClockAppBusinessImageId: string | null;
    readonly businessName: string;
    readonly globalBusinessId: string | null;
    readonly shortDescription: string | null;
    readonly registrationNumber: string | null;
    readonly globalImageId: string | null;
    readonly schoolCalendarsEnabled: boolean;
    readonly chatEnabled: boolean;
    readonly inviteHeader: string | null;
    readonly inviteMessage: string | null;
    readonly inviteFooter: string | null;
    readonly accentColor1: string;
    readonly coBrandingEnabled: boolean;
    readonly employmentCodePreprocessor: unknown | null;
    readonly firstDayOfWeek: number;
    readonly defaultScheduleDayStartTime: string;
    readonly defaultScheduleDayEndTime: string;
    readonly storeHoursEnabled: boolean;
    readonly showEarnings: boolean;
    readonly showSystemRolesAndPermissions: boolean;
    readonly businessTerms: unknown;
    readonly webuiViewOptions: unknown | null;
    readonly highlightOverstaffing: boolean;
    readonly militaryTime: boolean;
    readonly durationFormat: string;
    readonly dateFormat: string;
    readonly showAvatarIcon: boolean;
    readonly showNickname: boolean;
    readonly nicknameReplacesOnlyFirstName: boolean;
    readonly firstNameCharCount: number;
    readonly lastNameCharCount: number;
    readonly firstNameSuffix: string;
    readonly lastNameSuffix: string;
    readonly idleTimeoutEnabled: boolean;
    readonly idleTimeoutLength: number;
    readonly rostered: boolean;
    readonly maxShiftDuration: number;
    readonly autoAcceptRosteredShifts: boolean;
    readonly markAsAbsentEnabled: boolean;
    readonly enablePastShiftOperation: boolean;
    readonly timeOffShiftsEnabled: boolean;
    readonly timeOffShiftNotificationsEnabled: boolean;
    readonly meetingsEnabled: boolean;
    readonly meetingMaxPerYear: number;
    readonly meetingMinimumDuration: unknown;
    readonly meetingTitles: ReadonlyArray<string>;
    readonly shiftCanOverlapLeaveRequest: boolean;
    readonly shiftCanOverlapUnavailability: boolean;
    readonly shiftMultiRateEnabled: boolean;
    readonly shiftCostBreakdownEnabled: boolean;
    readonly unassignedShiftDefaultRateEnabled: boolean;
    readonly crossScheduleComplianceChecksEnabled: boolean;
    readonly scheduleWarningsFilterEnabled: boolean;
    readonly sharedStoreShiftsEnabled: boolean;
    readonly shiftAutoAcceptNotificationEnabled: boolean;
    readonly breakTypeManagementEnabled: boolean;
    readonly swapDropFiltering: SwapDropFilteringEnum;
    readonly swapSorting: SwapSortingEnum;
    readonly shiftSwapEnabled: boolean;
    readonly shiftSwapNeedsApproval: boolean;
    readonly swapMobileDisclaimerEnabled: boolean;
    readonly swapMobileDisclaimer: string | null;
    readonly shiftDropEnabled: boolean;
    readonly shiftDropThreshold: number;
    readonly dropMobileDisclaimerEnabled: boolean;
    readonly dropMobileDisclaimer: string | null;
    readonly clockTrackingEnabled: boolean;
    readonly clockInThreshold: number | null;
    readonly clockOutThreshold: number | null;
    readonly timekeepingReadonly: boolean;
    readonly timekeepingRolesVisible: boolean;
    readonly earlyShiftStartThreshold: number | null;
    readonly restScreenTheme: string;
    readonly punchSlipMode: string;
    readonly voluntaryLateClockOutEnabled: boolean;
    readonly timeClockAppFetchScheduleJobTitles: boolean;
    readonly timeClockAppSyncIntervalInSeconds: number;
    readonly approvalNeededForRoleRateChange: boolean;
    readonly timeClockRoleRateChangeAuthThreshold: number | null;
    readonly allowManagerToApproveOwnPunch: boolean;
    readonly notificationsOnTimeClockAppEnabled: boolean;
    readonly punchTrackingEnabled: boolean;
    readonly timeClockAppPinConfig: {
        readonly min: number;
        readonly max: number;
    };
    readonly biometricsExpirationDays: unknown;
    readonly biometricExpirationExemptJtcCodes: ReadonlyArray<string>;
    readonly timeClockAppWorkflowConfig: unknown | null;
    readonly timekeepingPrepopulateNewEntry: boolean;
    readonly timekeepingDefaultToWeekView: boolean;
    readonly punchAdjustmentConsentEnabled: boolean;
    readonly punchEditForceChangesLegalDisclaimerEnabled: boolean;
    readonly punchEditLegalDisclaimer: string | null;
    readonly punchEditManagerCommentsEnabled: boolean;
    readonly punchEditEmployeeCommentsEnabled: boolean;
    readonly enableShiftDifferentials: boolean;
    readonly enableMiscPay: boolean;
    readonly payPeriodReviewEnabled: boolean;
    readonly payPeriodSelfApprovalEnabled: boolean;
    readonly payPeriodDailyReportSplitNames: boolean;
    readonly payFrequency: string;
    readonly payCycleStartDate: string;
    readonly payDates: ReadonlyArray<number> | null;
    readonly aggregateToStartDay: boolean;
    readonly defaultPayrollCutoffTime: string | null;
    readonly earningsEstimator: string;
    readonly payDisclaimer: string | null;
    readonly roleJobTitles: unknown;
    readonly warningCodeMap: unknown;
    readonly rateType: unknown;
    readonly employeeMultiRateEnabled: boolean;
    readonly jobTitlesEnabled: boolean;
    readonly employmentRateEditEnabled: boolean;
    readonly approvalRequests: {
        readonly employmentRateChange: {
            readonly autoApprovalEnabled: boolean;
        };
    };
    readonly employmentRateEditPast: EmploymentRateEditPastEnum;
    readonly deletePrimaryJobTitlesEnabled: boolean;
    readonly analyticsEnabled: boolean;
    readonly analyticsServerUrl: string | null;
    readonly defaultScheduleSnapshotStartDate: string | null;
    readonly defaultScheduleSnapshotDuration: number;
    readonly dataLakeGlobalId: string | null;
    readonly dataLakeId: string | null;
    readonly quarterlyAggregationEnabled: boolean;
    readonly topLevelForecastMeasurements: ReadonlyArray<{
        readonly name: string;
        readonly label: string;
        readonly isPrimary: boolean;
        readonly isCurrency: boolean;
    }> | null;
    readonly forecastRequiresApproval: boolean;
    readonly canAdjustForecast: boolean;
    readonly populateScheduleReplanningEnabled: boolean;
    readonly targetsScreenEnabled: boolean;
    readonly reportSettings: unknown;
    readonly reportDisclaimerEnabled: boolean;
    readonly reportDisclaimerText: string | null;
    readonly showEarningsToUser: boolean;
    readonly showShiftRolesToUser: boolean;
    readonly showColleaguesToUser: boolean;
    readonly showBreaksToUser: boolean;
    readonly anonymousShiftSwapToUser: boolean;
    readonly anonymousShiftDropToUser: boolean;
    readonly markAsAbsentEnabledToUser: boolean;
    readonly managerAppEnabled: boolean;
    readonly liveTimeClockViewEnabled: boolean;
    readonly darEnabled: boolean;
    readonly darFields: ReadonlyArray<{
        readonly name: string;
        readonly fieldCode: string;
        readonly valueType: string;
        readonly description: string | null;
    }>;
    readonly darDataOnlyPastSlicesEnabled: boolean;
    readonly darAnalyticsSyncThreshold: number;
    readonly currencyType: CurrencyEnum;
    readonly localeSuffix: string | null;
    readonly mailerLocales: unknown | null;
    readonly regularBusinessInviteEnabled: boolean;
    readonly managerInviteUsersEnabled: boolean;
    readonly managerAssignUsersToSchedulesEnabled: boolean;
    readonly timeoutThreshold: number;
    readonly roundingStrategy: string | null;
    readonly inviteUserConfirmationText: string | null;
    readonly terminateUserConfirmationText: string | null;
    readonly assignEmployeeConfirmationText: string | null;
    readonly unassignEmployeeConfirmationText: string | null;
    readonly monthlyCostLoadingEnabled: boolean;
    readonly graphqlQueryBatchingEnabled: boolean;
    readonly " $refType": "Profile_business";
};
export type Profile_business$data = Profile_business;
export type Profile_business$key = {
    readonly " $data"?: Profile_business$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Profile_business">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Profile_business",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeClockAppBusinessImageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "globalBusinessId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "globalImageId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schoolCalendarsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chatEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inviteHeader",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inviteMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inviteFooter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accentColor1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coBrandingEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentCodePreprocessor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstDayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultScheduleDayStartTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultScheduleDayEndTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storeHoursEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showEarnings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showSystemRolesAndPermissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessTerms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "webuiViewOptions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highlightOverstaffing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "militaryTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "durationFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showAvatarIcon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showNickname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nicknameReplacesOnlyFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstNameCharCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastNameCharCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstNameSuffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastNameSuffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "idleTimeoutEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "idleTimeoutLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rostered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxShiftDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoAcceptRosteredShifts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "markAsAbsentEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enablePastShiftOperation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeOffShiftsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeOffShiftNotificationsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetingsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetingMaxPerYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetingMinimumDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "meetingTitles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftCanOverlapLeaveRequest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftCanOverlapUnavailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftMultiRateEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftCostBreakdownEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unassignedShiftDefaultRateEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "crossScheduleComplianceChecksEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduleWarningsFilterEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sharedStoreShiftsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftAutoAcceptNotificationEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "breakTypeManagementEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "swapDropFiltering",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "swapSorting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftSwapEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftSwapNeedsApproval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "swapMobileDisclaimerEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "swapMobileDisclaimer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDropEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shiftDropThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dropMobileDisclaimerEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dropMobileDisclaimer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clockTrackingEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clockInThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clockOutThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timekeepingReadonly",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timekeepingRolesVisible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyShiftStartThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "restScreenTheme",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "punchSlipMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voluntaryLateClockOutEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeClockAppFetchScheduleJobTitles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeClockAppSyncIntervalInSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvalNeededForRoleRateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeClockRoleRateChangeAuthThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowManagerToApproveOwnPunch",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationsOnTimeClockAppEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "punchTrackingEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeClockAppPinConfig",
      "kind": "LinkedField",
      "name": "timeClockAppPinConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "min",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "max",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "biometricsExpirationDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "biometricExpirationExemptJtcCodes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeClockAppWorkflowConfig",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timekeepingPrepopulateNewEntry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timekeepingDefaultToWeekView",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "punchAdjustmentConsentEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "punchEditForceChangesLegalDisclaimerEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "punchEditLegalDisclaimer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "punchEditManagerCommentsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "punchEditEmployeeCommentsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableShiftDifferentials",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableMiscPay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payPeriodReviewEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payPeriodSelfApprovalEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payPeriodDailyReportSplitNames",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payFrequency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payCycleStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payDates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aggregateToStartDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultPayrollCutoffTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earningsEstimator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payDisclaimer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleJobTitles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warningCodeMap",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rateType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employeeMultiRateEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitlesEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentRateEditEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ApprovalRequestsConfig",
      "kind": "LinkedField",
      "name": "approvalRequests",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmploymentRateChangeRequestConfig",
          "kind": "LinkedField",
          "name": "employmentRateChange",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "autoApprovalEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "employmentRateEditPast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletePrimaryJobTitlesEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "analyticsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "analyticsServerUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultScheduleSnapshotStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultScheduleSnapshotDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataLakeGlobalId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataLakeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quarterlyAggregationEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalyticsTopLevelMeasurement",
      "kind": "LinkedField",
      "name": "topLevelForecastMeasurements",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPrimary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurrency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forecastRequiresApproval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAdjustForecast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "populateScheduleReplanningEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetsScreenEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportSettings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportDisclaimerEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportDisclaimerText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showEarningsToUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showShiftRolesToUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showColleaguesToUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showBreaksToUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "anonymousShiftSwapToUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "anonymousShiftDropToUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "markAsAbsentEnabledToUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerAppEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "liveTimeClockViewEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "darEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessDarField",
      "kind": "LinkedField",
      "name": "darFields",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "valueType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "darDataOnlyPastSlicesEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "darAnalyticsSyncThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currencyType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localeSuffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mailerLocales",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "regularBusinessInviteEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerInviteUsersEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managerAssignUsersToSchedulesEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeoutThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roundingStrategy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inviteUserConfirmationText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminateUserConfirmationText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignEmployeeConfirmationText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unassignEmployeeConfirmationText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyCostLoadingEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "graphqlQueryBatchingEnabled",
      "storageKey": null
    }
  ],
  "type": "Business",
  "abstractKey": null
};
})();
(node as any).hash = 'ef6d804b4b26f59979eaa479580a5ae0';
export default node;
