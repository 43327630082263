import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
// eslint-disable-next-line import/no-unresolved
import { Client, IConfig } from "@bugsnag/browser/dist/types/bugsnag-core";

import { ThemeProvider } from "styled-components";
import ConfigurationService from "./configuration-service";
import * as serviceWorker from "./serviceWorker";
import App from "./components/App";
import defaultTheme from "./styles/theme";
import "./i18n";
import { RelayEnvironmentConfig } from "./environment";
import { ModalProvider } from "./contexts/ModalContext";

function PlatformAdmin() {
  const configuration = ConfigurationService.config;

  const getBugsnagClient = (): Client => {
    // Create the configuration for the client
    const bugsnagClientConfiguration: IConfig = {
      apiKey: configuration.bugsnag.apiKey || "",
      appVersion: configuration.version.release,
      autoNotify: configuration.bugsnag.notify,
      autoCaptureSessions: configuration.bugsnag.notify,
      notifyReleaseStages: [],
      releaseStage: configuration.target,
    };

    // Only put in notify stage if we want it
    if (configuration.bugsnag.notify) {
      if (bugsnagClientConfiguration.notifyReleaseStages?.push) {
        bugsnagClientConfiguration.notifyReleaseStages.push(
          configuration.target,
        );
      }
    }

    // Create the client and add react plugin to Bugsnag
    const bugsnagClient: Client = bugsnag(bugsnagClientConfiguration);
    bugsnagClient.use(bugsnagReact, React);

    // Allow access via window.Bugsnag
    (window as any).Bugsnag = bugsnagClient;

    // set release version
    (RelayEnvironmentConfig as any).VERSION = configuration.version.release;

    return bugsnagClient;
  };

  if (!configuration) {
    // config not loaded yet
    return null;
  }

  const bugsnagClient = getBugsnagClient();
  const ErrorBoundary = bugsnagClient.getPlugin("react");

  return (
    <ErrorBoundary>
      <ThemeProvider theme={defaultTheme}>
        <Suspense fallback="loading">
          <ModalProvider>
            <App configuration={configuration} />
          </ModalProvider>
        </Suspense>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

ReactDOM.render(<PlatformAdmin />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
