import React, { FormEventHandler } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import styled from "styled-components";

import { InputGroup } from "react-bootstrap";
import {
  SessionInfo,
  IUser,
  ConfigurationObject,
} from "../../../data/models/common";
import { idmExternalEnvironment } from "../../../environment";
import SignInMutation from "./mutations/SignInMutation";
import { SignInMutationResponse } from "./mutations/__generated__/SignInMutation.graphql";
import Authentication from "../../../utils/authentication";

type Props = {
  deploymentConfiguration?: ConfigurationObject;
  onLoggedIn: (s: SessionInfo) => void;
};

type FormValues = {
  email: string;
  password: string;
  apiUrl?: string;
};

const validationRules = yup.object({
  email: yup.string().email(),
  // TODO: add more rules
});

const StyledContainer = styled(Card)`
  width: 360px;
  height: 400px;
  position: absolute;
  margin: auto;
  left: 0 /* this is needed */;
  right: 0 /* this is needed */;
  top: 0 /* this is needed */;
  bottom: 0 /* this is needed */;
`;

export default function Login(props: Props) {
  // private acceptPrivacyPolicy(values: FormValues, modalProps: ModalProps) {
  //   const { sessionInfo, privacyPolicyVersion } = state;
  //   if (!sessionInfo) {
  //     return;
  //   }

  //   const { apiUrl } = values;
  //   const { apiVersion, authToken } = sessionInfo;

  //   AcceptPrivacyPolicyMutation(
  //     getEnvironment({
  //       url: apiUrl,
  //       apiVersion: apiVersion,
  //       schemaName: "external",
  //     }),
  //     privacyPolicyVersion,
  //     (response: AcceptPrivacyPolicyMutationResponse, errors?: any) => {
  //       const { acceptPrivacyPolicy } = response;
  //       if (acceptPrivacyPolicy) {
  //         loadStacks(
  //           values.apiUrl || "",
  //           apiVersion,
  //           authToken,
  //           modalProps
  //         );
  //       }
  //     },
  //     (error: Error) => {
  //       alert(error.message);
  //     }
  //   );
  // }

  function handleLogin(
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ) {
    const { apiUrl, email, password } = values;
    const { onLoggedIn } = props;

    if (!apiUrl) {
      alert("cannot detect api url");
      return;
    }

    setSubmitting(true);

    Authentication.storeSession({
      apiUrl: apiUrl || "",
    });

    SignInMutation(
      idmExternalEnvironment,
      email,
      password,
      (response: SignInMutationResponse) => {
        const { signIn } = response;
        const { authToken, user } = signIn;
        const sessionInfo: SessionInfo = {
          user: user as IUser,
          authToken,
          apiUrl: apiUrl || "",
        };

        setSubmitting(false);
        onLoggedIn(sessionInfo);
      },
      (error: Error) => {
        setFieldError("email", error.message);
        setFieldError("password", error.message);
      },
    );
  }

  const { deploymentConfiguration } = props;
  if (!deploymentConfiguration) {
    // deployment config not loaded yet
    return null;
  }

  const urlMap: {
    value: string;
    label: string;
    default: boolean;
  }[] = [];
  const servers = deploymentConfiguration?.idm.servers || {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(servers)) {
    urlMap.push({
      value: (servers as any)[key].url,
      label: (servers as any)[key].name,
      default: deploymentConfiguration?.idm.default === key,
    });
  }

  const defaultUrl = urlMap.find((item) => item.default)?.value || "";
  const dataListOptions = urlMap.map((x) => x.value);

  return (
    <StyledContainer body className="bg-light">
      <Formik
        validationSchema={validationRules}
        onSubmit={(v, e) => {
          handleLogin(v, e as FormikHelpers<FormValues>);
        }}
        initialValues={{
          email: "",
          password: "",
          apiUrl: defaultUrl,
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form onSubmit={handleSubmit as unknown as FormEventHandler}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={errors.email != null}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={errors.password != null}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            {urlMap.length > 1 ? (
              <Form.Group>
                <Form.Label>IDM Server</Form.Label>
                <br />

                <InputGroup>
                  <Form.Control
                    type="text"
                    value={values.apiUrl}
                    list="idm-data-list"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const v = e.target.value;
                      setFieldValue("apiUrl", v);
                    }}
                  />
                  {(dataListOptions ?? []).length > 0 && (
                    <datalist id="idm-data-list">
                      {dataListOptions.map((listOption) => (
                        <option value={listOption} key={listOption}>
                          {" "}
                        </option>
                      ))}
                    </datalist>
                  )}
                </InputGroup>
              </Form.Group>
            ) : null}

            <Button variant="primary" type="submit" block>
              Submit
            </Button>

            {/* <OkCancelModal
                    title="Privacy policy"
                    modalProps={modalProps}
                    okLabel="Accept"
                    onOk={() => {
                      acceptPrivacyPolicy(values, modalProps);
                    }}
                  >
                    <p>
                      <span>Please accept </span>
                      <a
                        href="https://www.lifelenz.com/privacy/au"
                        target="blank"
                      >
                        privacy policy
                      </a>
                    </p>
                  </OkCancelModal> */}
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
}
