import { commitMutation, Environment, graphql } from "react-relay";

const mutation = graphql`
  mutation CreateBusinessMutation($input: BusinessInput!) {
    createBusiness(input: $input) {
      id
      timeClockAppBusinessImageId
      ### Replaceable content start
      businessName
      globalBusinessId
      shortDescription
      registrationNumber
      globalImageId
      schoolCalendarsEnabled
      chatEnabled
      inviteHeader
      inviteMessage
      inviteFooter
      accentColor1
      coBrandingEnabled
      employmentCodePreprocessor
      firstDayOfWeek
      defaultScheduleDayStartTime
      defaultScheduleDayEndTime
      storeHoursEnabled
      showEarnings
      showSystemRolesAndPermissions
      businessTerms
      webuiViewOptions
      highlightOverstaffing
      militaryTime
      durationFormat
      dateFormat
      showAvatarIcon
      showNickname
      nicknameReplacesOnlyFirstName
      firstNameCharCount
      lastNameCharCount
      firstNameSuffix
      lastNameSuffix
      idleTimeoutEnabled
      idleTimeoutLength
      rostered
      maxShiftDuration
      autoAcceptRosteredShifts
      markAsAbsentEnabled
      enablePastShiftOperation
      timeOffShiftsEnabled
      timeOffShiftNotificationsEnabled
      meetingsEnabled
      meetingMaxPerYear
      meetingMinimumDuration
      meetingTitles
      shiftCanOverlapLeaveRequest
      shiftCanOverlapUnavailability
      shiftMultiRateEnabled
      shiftCostBreakdownEnabled
      unassignedShiftDefaultRateEnabled
      crossScheduleComplianceChecksEnabled
      scheduleWarningsFilterEnabled
      sharedStoreShiftsEnabled
      shiftAutoAcceptNotificationEnabled
      breakTypeManagementEnabled
      swapDropFiltering
      swapSorting
      shiftSwapEnabled
      shiftSwapNeedsApproval
      swapMobileDisclaimerEnabled
      swapMobileDisclaimer
      shiftDropEnabled
      shiftDropThreshold
      dropMobileDisclaimerEnabled
      dropMobileDisclaimer
      clockTrackingEnabled
      clockInThreshold
      clockOutThreshold
      timekeepingReadonly
      timekeepingRolesVisible
      earlyShiftStartThreshold
      restScreenTheme
      punchSlipMode
      voluntaryLateClockOutEnabled
      timeClockAppFetchScheduleJobTitles
      timeClockAppSyncIntervalInSeconds
      approvalNeededForRoleRateChange
      timeClockRoleRateChangeAuthThreshold
      allowManagerToApproveOwnPunch
      notificationsOnTimeClockAppEnabled
      punchTrackingEnabled
      timeClockAppPinConfig {
        min
        max
      }
      biometricsExpirationDays
      biometricExpirationExemptJtcCodes
      timeClockAppWorkflowConfig
      timekeepingPrepopulateNewEntry
      timekeepingDefaultToWeekView
      punchAdjustmentConsentEnabled
      punchEditForceChangesLegalDisclaimerEnabled
      punchEditLegalDisclaimer
      punchEditManagerCommentsEnabled
      punchEditEmployeeCommentsEnabled
      enableShiftDifferentials
      enableMiscPay
      payPeriodReviewEnabled
      payPeriodSelfApprovalEnabled
      payPeriodDailyReportSplitNames
      payFrequency
      payCycleStartDate
      payDates
      aggregateToStartDay
      defaultPayrollCutoffTime
      earningsEstimator
      payDisclaimer
      roleJobTitles
      warningCodeMap
      rateType
      employeeMultiRateEnabled
      jobTitlesEnabled
      employmentRateEditEnabled
      approvalRequests {
        employmentRateChange {
          autoApprovalEnabled
        }
      }
      employmentRateEditPast
      deletePrimaryJobTitlesEnabled
      analyticsEnabled
      analyticsServerUrl
      defaultScheduleSnapshotStartDate
      defaultScheduleSnapshotDuration
      dataLakeGlobalId
      dataLakeId
      quarterlyAggregationEnabled
      topLevelForecastMeasurements {
        name
        label
        isPrimary
        isCurrency
      }
      forecastRequiresApproval
      canAdjustForecast
      populateScheduleReplanningEnabled
      targetsScreenEnabled
      reportSettings
      reportDisclaimerEnabled
      reportDisclaimerText
      showEarningsToUser
      showShiftRolesToUser
      showColleaguesToUser
      showBreaksToUser
      anonymousShiftSwapToUser
      anonymousShiftDropToUser
      markAsAbsentEnabledToUser
      managerAppEnabled
      liveTimeClockViewEnabled
      darEnabled
      darFields {
        name
        fieldCode
        valueType
        description
      }
      darDataOnlyPastSlicesEnabled
      darAnalyticsSyncThreshold
      currencyType
      localeSuffix
      mailerLocales
      regularBusinessInviteEnabled
      managerInviteUsersEnabled
      managerAssignUsersToSchedulesEnabled
      timeoutThreshold
      roundingStrategy
      inviteUserConfirmationText
      terminateUserConfirmationText
      assignEmployeeConfirmationText
      unassignEmployeeConfirmationText
      monthlyCostLoadingEnabled
      graphqlQueryBatchingEnabled
      ### Replaceable content finish
    }
  }
`;

export default (
  environment: Environment,
  input: {},
  callback: Function,
  onError: (error: Error) => void = (err: Error) => {
    alert(err);
  },
) => {
  const variables = {
    input,
  };

  return commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (response: {}, errors: readonly any[] | null | undefined) => {
      callback(response, errors);
    },
    onError,
  });
};
