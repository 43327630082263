/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentTypesQueries_DeleteEmploymentTypeConfig_MutationVariables = {
    businessId: string;
    id: string;
};
export type EmploymentTypesQueries_DeleteEmploymentTypeConfig_MutationResponse = {
    readonly deleteEmploymentTypeConfig: boolean;
};
export type EmploymentTypesQueries_DeleteEmploymentTypeConfig_Mutation = {
    readonly response: EmploymentTypesQueries_DeleteEmploymentTypeConfig_MutationResponse;
    readonly variables: EmploymentTypesQueries_DeleteEmploymentTypeConfig_MutationVariables;
};



/*
mutation EmploymentTypesQueries_DeleteEmploymentTypeConfig_Mutation(
  $businessId: ID!
  $id: ID!
) {
  deleteEmploymentTypeConfig(businessId: $businessId, id: $id)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteEmploymentTypeConfig",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentTypesQueries_DeleteEmploymentTypeConfig_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentTypesQueries_DeleteEmploymentTypeConfig_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "00885cd73d51091d25586afbc50beeb6",
    "id": null,
    "metadata": {},
    "name": "EmploymentTypesQueries_DeleteEmploymentTypeConfig_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmploymentTypesQueries_DeleteEmploymentTypeConfig_Mutation(\n  $businessId: ID!\n  $id: ID!\n) {\n  deleteEmploymentTypeConfig(businessId: $businessId, id: $id)\n}\n"
  }
};
})();
(node as any).hash = 'f40bb7d3be842553ba9843c5cdfca520';
export default node;
