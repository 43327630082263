/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BusinessTableQueryVariables = {
    after?: string | null | undefined;
    pageSize?: number | null | undefined;
    searchValue?: string | null | undefined;
};
export type BusinessTableQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"BusinessQueries_ListFragment">;
};
export type BusinessTableQuery = {
    readonly response: BusinessTableQueryResponse;
    readonly variables: BusinessTableQueryVariables;
};



/*
query BusinessTableQuery(
  $after: String
  $pageSize: Int
  $searchValue: String
) {
  ...BusinessQueries_ListFragment
}

fragment BusinessQueries_ListFragment on InternalQuery {
  businesses(search: $searchValue, first: $pageSize, after: $after) {
    edges {
      node {
        id
        businessName
        shortDescription
        globalBusinessId
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchValue"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchValue"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessTableQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BusinessQueries_ListFragment"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BusinessTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BusinessConnection",
        "kind": "LinkedField",
        "name": "businesses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Business",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "businessName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "globalBusinessId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "search"
        ],
        "handle": "connection",
        "key": "BusinessTable_businesses",
        "kind": "LinkedHandle",
        "name": "businesses"
      }
    ]
  },
  "params": {
    "cacheID": "8364e15b358a52cb125d551a56b24506",
    "id": null,
    "metadata": {},
    "name": "BusinessTableQuery",
    "operationKind": "query",
    "text": "query BusinessTableQuery(\n  $after: String\n  $pageSize: Int\n  $searchValue: String\n) {\n  ...BusinessQueries_ListFragment\n}\n\nfragment BusinessQueries_ListFragment on InternalQuery {\n  businesses(search: $searchValue, first: $pageSize, after: $after) {\n    edges {\n      node {\n        id\n        businessName\n        shortDescription\n        globalBusinessId\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8f2c0e4c91e24fc51b8a3ccfa0457c57';
export default node;
