import React, { FunctionComponent, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import flatten from "lodash/flatten";
import { Link, RouteComponentProps } from "react-router-dom";
import { getRegionalStackEnvironment } from "../../../../environment";
import BusinessService, {
  Employment,
} from "../../../Stack/Services/BusinessService";
import EmploymentBadge from "../../../Stack/Employment/EmploymentBadge";
import { toRelative } from "../../../../utils/utility";
import { IStack } from "../../../../data/models/common";
import Loader from "../../../common/Loader";
import { useAppContext } from "../../external/Context/AppContext";

export interface MatchParams {
  user_id: string;
}

type Props = RouteComponentProps<MatchParams> &
  WithTranslation & {
    user: {
      readonly id: string;
      readonly userStacks: ReadonlyArray<{
        readonly stackId: string;
      }>;
    };
  };

type StackEmployment = {
  stack: IStack;
  employment: Employment;
  isViewableBusiness: boolean;
};

const AccountEmployments: FunctionComponent<Props> = ({ user, t }) => {
  const [employmentStacks, setEmploymentStacks] = useState<StackEmployment[]>();

  const { myStacksById } = useAppContext();

  useEffect(() => {
    async function load() {
      const { id: userId, userStacks } = user;

      const employments = await Promise.all(
        userStacks.map(async (i) => {
          const stack = myStacksById?.get(i.stackId);
          if (!stack) {
            // not in stack that logged in user has access to
            return Promise.resolve([]);
          }
          const environment = getRegionalStackEnvironment(stack?.domainName);
          const result = await Promise.all([
            BusinessService.getStackEmploymentsByUserId(environment, userId),
            BusinessService.getBusinesses(environment),
          ]);

          return result[0].map((employment) => {
            return {
              stack,
              employment,
              isViewableBusiness: result[1].has(employment?.business.id),
            };
          });
        }),
      );

      setEmploymentStacks(flatten(employments));
    }

    load();
  }, [myStacksById, user]);

  if (!employmentStacks) {
    return <Loader />;
  }

  return (
    <Card body>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("accountEmploymentsTable.headers.stack")}</th>
            <th>{t("accountEmploymentsTable.headers.businessName")}</th>
            <th>{t("accountEmploymentsTable.headers.employmentName")}</th>
            <th>{t("accountEmploymentsTable.headers.employmentEmail")}</th>
            <th>{t("accountEmploymentsTable.headers.employmentStatus")}</th>
            <th>{t("accountEmploymentsTable.headers.employmentDuration")}</th>
            <th>{t("accountEmploymentsTable.headers.created")}</th>
            <th>{t("accountEmploymentsTable.headers.lastUpdate")}</th>
          </tr>
        </thead>
        <tbody>
          {employmentStacks.map((i, index) => {
            const { employment, stack, isViewableBusiness } = i;
            const { id: stackId, stackCode } = stack;
            if (!employment) {
              return null;
            }

            const {
              id: employmentId,
              during,
              createdAt,
              updatedAt,
              email,
              business,
              computedName,
            } = employment;
            const { id: businessId, businessName } = business;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={`${employmentId}-${index}`}>
                <td>
                  <Link to={`/stack/${stackId}`}>{stackCode}</Link>
                </td>
                <td>
                  {isViewableBusiness ? (
                    <Link
                      to={`/stack/${stackId}/business/${businessId}/profile`}
                    >
                      {businessName}
                    </Link>
                  ) : (
                    <span>{businessName}</span>
                  )}
                </td>
                <td>{computedName}</td>
                <td>
                  <Link
                    to={`/stack/${stackId}/business/${businessId}/employee/${employmentId}`}
                  >
                    {email}
                  </Link>
                </td>
                <td>
                  <EmploymentBadge employment={employment} />
                </td>
                <td>{during}</td>
                <td>
                  {toRelative(createdAt as string, {
                    defaultValue: "-",
                  })}
                </td>
                <td>
                  {toRelative(updatedAt as string, {
                    defaultValue: "-",
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
};

export default withTranslation("accounts")(AccountEmployments);
