import React from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
} from "react-relay";
import Row from "./AccountRow";
import RelayLazyLoader from "../../../common/RelayLazyLoader";
import { AccountTable_viewer } from "./__generated__/AccountTable_viewer.graphql";
import { Id, IStack } from "../../../../data/models/common";

const AccountTablePageQuery = graphql`
  query AccountTable_InternalQuery(
    $email: String!
    $isLocked: Boolean
    $isConfirmed: Boolean
    $stackId: ID
    $pageSize: Int!
    $after: String
  ) {
    ...AccountTable_viewer
  }
`;

type Props = {
  searchValue: string;
  isLocked: boolean;
  isUnconfirmed: boolean;
  stackId: Id | null;
  viewer: AccountTable_viewer;
  relay: RelayPaginationProp;
  stacksById: Map<string, IStack>;
};

export function AccountTable(props: Props) {
  const { t } = useTranslation("accounts");
  const { relay, stacksById } = props;
  const nodes = (props.viewer.users.edges || []).map((edge: any) => (
    <Row key={edge.node.__id} viewer={edge.node} stacksById={stacksById} />
  ));

  return (
    <>
      <Table hover size="sm">
        <thead>
          <tr>
            <th>{t("table.headers.accountEmail")}</th>
            <th>{t("table.headers.accountStatus")}</th>
            <th>{t("table.headers.locale")}</th>
            <th>{t("table.headers.stacks")}</th>
            <th>{t("table.headers.acceptedPolicy")}</th>
            <th>{t("table.headers.lastSignIn")}</th>
            <th>{t("table.headers.lastUpdate")}</th>
          </tr>
        </thead>
        <tbody>{nodes}</tbody>
      </Table>
      <RelayLazyLoader relay={relay} />
    </>
  );
}

export default createPaginationContainer(
  AccountTable,
  {
    viewer: graphql`
      fragment AccountTable_viewer on InternalQuery {
        users(
          email: $email
          isLocked: $isLocked
          isConfirmed: $isConfirmed
          stackId: $stackId
          first: $pageSize
          after: $after
        ) @connection(key: "AccountTable_users") {
          edges {
            node {
              ...AccountRow_viewer
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: AccountTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.users,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
    }),
    getVariables: (props, paginationInfo) => ({
      email: props.searchValue,
      isLocked: props.isLocked || undefined,
      isConfirmed: props.isUnconfirmed ? false : undefined,
      stackId: props.stackId || undefined,
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
    }),
  },
);
