/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BreakTypeQueries_RemoveScheduleBreakTypeOverride_MutationVariables = {
    businessId: string;
    id: string;
};
export type BreakTypeQueries_RemoveScheduleBreakTypeOverride_MutationResponse = {
    readonly deleteScheduleBreakType: boolean;
};
export type BreakTypeQueries_RemoveScheduleBreakTypeOverride_Mutation = {
    readonly response: BreakTypeQueries_RemoveScheduleBreakTypeOverride_MutationResponse;
    readonly variables: BreakTypeQueries_RemoveScheduleBreakTypeOverride_MutationVariables;
};



/*
mutation BreakTypeQueries_RemoveScheduleBreakTypeOverride_Mutation(
  $businessId: ID!
  $id: ID!
) {
  deleteScheduleBreakType(businessId: $businessId, id: $id)
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteScheduleBreakType",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueries_RemoveScheduleBreakTypeOverride_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BreakTypeQueries_RemoveScheduleBreakTypeOverride_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1638cf3ed134328d0bd224f2d7c35816",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueries_RemoveScheduleBreakTypeOverride_Mutation",
    "operationKind": "mutation",
    "text": "mutation BreakTypeQueries_RemoveScheduleBreakTypeOverride_Mutation(\n  $businessId: ID!\n  $id: ID!\n) {\n  deleteScheduleBreakType(businessId: $businessId, id: $id)\n}\n"
  }
};
})();
(node as any).hash = '5669affd915bfa0cf59cda6275ca6739';
export default node;
