import React from "react";
import {
  graphql,
  Environment,
  useFragment,
  useLazyLoadQuery,
} from "react-relay";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as yup from "yup";
import { Link, RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";
import {
  EmploymentProfileForm_employment as ProfileEmployment,
  EmploymentProfileForm_employment$key,
} from "./__generated__/EmploymentProfileForm_employment.graphql";
import UpdateEmploymentMutation from "../mutations/UpdateEmploymentMutation";
import { UpdateEmploymentMutationResponse } from "../mutations/__generated__/UpdateEmploymentMutation.graphql";
import properties from "../../../data/csv-settings/employment-settings.json";
import FormLayout from "../../common/Form/FormLayout";
import FormLayoutFooter from "../../common/Form/FormLayoutFooter";
import { ComponentRule, IProperty } from "../../common/Form/models";
import DynamicInputGroup from "../../common/Form/DynamicInputGroup";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../common/Form/formUtilities";
import GroupHeader from "../../common/Form/GroupHeader";
import { toRelative } from "../../../utils/utility";
import ValueField from "../../common/ValueField";
import Switch from "../../common/Form/Switch";
import { ConfirmationModal } from "../../common/ConfirmationModal";
import { useModal } from "../../../contexts/ModalContext";
import { GetEmploymentTypeConfigsQuery } from "../EmploymentTypes/EmploymentTypesQueries";
import { EmploymentTypesQueries_GetEmploymentTypeConfigs_Query } from "../EmploymentTypes/__generated__/EmploymentTypesQueries_GetEmploymentTypeConfigs_Query.graphql";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import DynamicSelect from "../../common/Form/DynamicSelect";

interface MatchParams {
  business_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  employment: EmploymentProfileForm_employment$key;
  environment: Environment;
};

const validationRules = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email(),
  employmentRate: yup.number().min(0),
  // TODO: add more rules
});

function EmploymentProfileForm(props: Props) {
  const businessId = props.match.params.business_id;
  const { business } = useBusinessContext();
  const { t } = useTranslation();
  const { showModal, hideModal } = useModal();

  const [
    employmentTypeConfigsSelectOptions,
    allEmploymentTypeConfigsSelectOptions,
  ] = useEmploymentTypeConfigsSelectOptions();

  const employment = useFragment(
    graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment EmploymentProfileForm_employment on Employment {
        id
        userId
        createdAt
        acceptedInvite
        during
        updatedAt
        userEmail
        businessInvite {
          id
          activationCode
          attemptsRemaining
          expiryTime
          inviteToken
        }
        ### Replaceable content start
        email
        firstName
        lastName
        nickname
        dateOfBirth
        imageId
        schoolId
        external
        rating
        employmentRate
        securityRole
        code
        employmentTypeCode
        payrollCode
        mutedFeatures
        ### Replaceable content finish
      }
    `,
    props.employment,
  );

  function onSaved(response: UpdateEmploymentMutationResponse) {
    toast("Save successfully");
  }

  // Add a new event handler that fires off the mutation
  const handleSave = (
    changes: Partial<ProfileEmployment>,
    onError: (err: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: Partial<ProfileEmployment>,
    helpers?: FormikHelpers<ProfileEmployment>,
  ) => {
    const updateEmploymentMutation = () => {
      UpdateEmploymentMutation(
        props.environment,
        changes,
        employment.id,
        businessId,
        onSaved,
        onError,
      );
    };

    // If user is changing user to an externally managed employment, warn them before letting them save
    if (changes.external) {
      showModal(
        <ConfirmationModal
          onClose={() => {
            hideModal();
            helpers?.setSubmitting(false);
          }}
          okClicked={() => {
            hideModal();
            updateEmploymentMutation();
          }}
          title={t("employment:externallyManagedWarningModal.title")}
          okText={t("employment:externallyManagedWarningModal.actions.ok")}
        >
          {t("employment:externallyManagedWarningModal.body")}
        </ConfirmationModal>,
      );
    } else {
      updateEmploymentMutation();
    }
  };

  const componentRules: Record<string, ComponentRule> = {
    external: {
      component: Switch,
      label: undefined,
      hideLabel: true,
      xs: 12,
      md: 12,
      lg: 12,
      componentProps: {
        boldLabels: true,
        onLabel: t("employment:details.externallyManaged"),
        offLabel: t("employment:details.externallyManaged"),
      },
    },
    employmentTypeCode: {
      component: DynamicSelect,
      // LK-12327: Disable if employment contract use employment types is enabled (the value is periodically updated/computed based on the current applicable employment contract)
      disabled: () => business.contractsUseEmploymentType === true,
      componentProps: {
        options: allEmploymentTypeConfigsSelectOptions,
        selectableOptions: employmentTypeConfigsSelectOptions,
      },
    },
  };

  if (employment) {
    const { businessInvite } = employment;
    return (
      <div className="panel">
        {businessInvite ? (
          <>
            <GroupHeader>{t("employment:details.businessInvite")}</GroupHeader>
            <Row>
              <Col md={6}>
                <ValueField title={t("employment:details.expiry")}>
                  {toRelative(businessInvite.expiryTime as string, {
                    defaultValue: "-",
                  })}
                </ValueField>

                <ValueField title={t("employment:details.activationCode")}>
                  {businessInvite.activationCode}
                </ValueField>
              </Col>
              <Col md={6}>
                <ValueField title={t("employment:details.attemptsRemaining")}>
                  {businessInvite.attemptsRemaining}
                </ValueField>
                <ValueField title={t("employment:details.inviteToken")}>
                  {businessInvite.inviteToken}
                </ValueField>
              </Col>
            </Row>
          </>
        ) : null}
        <FormLayout<ProfileEmployment>
          base={employment}
          onSave={handleSave}
          propertyList={properties as unknown as IProperty[]}
          validationRules={validationRules}
          componentRules={componentRules}
        >
          <DynamicInputGroup
            fields={getSettingsByGroup(
              getFieldsByInputObjectName(
                properties as unknown as IProperty[],
                "EmploymentInput",
              ),
            )}
          />
          <GroupHeader>{t("employment:details.title")}</GroupHeader>
          <Row>
            <Col md={6}>
              <ValueField title={t("employment:details.accountEmail")}>
                <Link to={`/account/${employment.userId}`}>
                  {employment.userEmail}
                </Link>
              </ValueField>
              <ValueField title={t("employment:details.created")}>
                {toRelative(employment.createdAt as string, {
                  defaultValue: "-",
                })}
              </ValueField>
              <ValueField title={t("employment:details.acceptedInvite")}>
                {employment.acceptedInvite ? "Yes" : "No"}
              </ValueField>

              <ValueField title={t("employment:details.employmentDuration")}>
                {employment.during}
              </ValueField>
            </Col>
            <Col md={6}>
              <ValueField title={t("employment:details.lastUpdate")}>
                {toRelative(employment.updatedAt as string, {
                  defaultValue: "-",
                })}
              </ValueField>
            </Col>
          </Row>
          <FormLayoutFooter />
        </FormLayout>
      </div>
    );
  }
  return <div>{t("employment:details.employmentNotFound")}</div>;
}

function useEmploymentTypeConfigsSelectOptions() {
  const businessContext = useBusinessContext();
  const query =
    useLazyLoadQuery<EmploymentTypesQueries_GetEmploymentTypeConfigs_Query>(
      GetEmploymentTypeConfigsQuery,
      {
        businessId: businessContext.business.id ?? "",
      },
      { fetchPolicy: "network-only" },
    );
  const employmentTypeConfigsData = query.employmentTypeConfigs.nodes;

  // Separate these options so that the dropdown can still display the deleted type as being selected
  const nonDeletedOptions = employmentTypeConfigsData
    .filter((x) => x?.deleted === false)
    .map((config) => {
      return { label: config?.name, value: config?.employmentTypeCode };
    });

  const allOptions = employmentTypeConfigsData.map((config) => {
    return { label: config?.name, value: config?.employmentTypeCode };
  });

  return [nonDeletedOptions, allOptions] as const;
}

export default EmploymentProfileForm;
