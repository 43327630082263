import React, { useEffect, useState } from "react";
import { Dictionary, keyBy } from "lodash";
import { fetchQuery, graphql } from "react-relay";
import { useTranslation } from "react-i18next";
import GroupHeader from "../../../../common/Form/GroupHeader";
import DynamicInputGroup from "../../../../common/Form/DynamicInputGroup";
import OptimizationGoals from "../OptimizationGoals";
import EmployeeGroups, { EmployeeGroup } from "../EmployeeGroups";
import PlanningOrder from "../PlanningOrder";

import ShiftMinMax from "../ShiftMinMax";
import { useBusinessContext } from "../../../../../contexts/BusinessContext";
import {
  getSettingsByGroup,
  getFieldsByNames,
} from "../../../../common/Form/formUtilities";
import { ComponentRule, IProperty } from "../../../../common/Form/models";
import TimeRangeRanks from "../TimeRangeRanks";
import { DynamicFormContext } from "../../../../../contexts/DynamicFormContext";
import DateTimePicker from "../../../../common/Form/DateTimePicker";
import OrderedList from "../../../../common/Form/OrderedList";
import SelectionGroup from "../../../../common/Form/SelectionGroup";
import { BaseOption, daysOfWeek, Id } from "../../../../../data/models/common";

import { BasicFields_BusinessRolesQuery } from "./__generated__/BasicFields_BusinessRolesQuery.graphql";
import MultiSelectList from "../../../../common/Form/MultiSelectList";
import DynamicSelect from "../../../../common/Form/DynamicSelect";
import CheckboxList from "../../../../common/Form/CheckboxList";
import { TransformedAosConfigBasic } from "../models";
import {
  BusinessRoleSortField,
  Order,
} from "../../../../../data/generated/stack_internal_schema";
import { useAppRouter } from "../../../../../hooks/useAppRouter";

const businessRoleQuery = graphql`
  query BasicFields_BusinessRolesQuery(
    $businessId: ID!
    $sort: [BusinessRoleSort!]
  ) {
    businessRoles(businessId: $businessId, sort: $sort) {
      nodes {
        id
        businessRoleName
        replaceByAos
      }
    }
  }
`;

interface MatchParams {
  business_id: string;
}

type Props = {
  values: {
    aosConfig: TransformedAosConfigBasic;
  };
  properties: IProperty[];
  disabled: boolean;
};

const getIncludedRoleOptions = (
  map: Record<Id, BaseOption<string, any>>,
  includeRoles: Id[],
) => {
  let selected: BaseOption<string, any>[] = [];
  if (includeRoles) {
    selected = includeRoles.map((n) => {
      return map[n];
    });
  }

  return selected;
};

const BasicFields: React.FunctionComponent<Props> = ({
  values,
  properties,
  disabled,
}) => {
  const { t } = useTranslation("aos");
  const { params } = useAppRouter<MatchParams>();
  const { business, environment } = useBusinessContext();
  const [businessRoles, setBusinessRoles] = useState<BaseOption<string, any>[]>(
    [],
  );

  const [flexibleRoles, setFlexibleRoles] = useState<BaseOption<string, any>[]>(
    [],
  );

  const [selectedBusinessRoles, setSelectedBusinessRoles] = useState<
    BaseOption<string, any>[]
  >([]);

  const [businessRolesOptionsById, setBusinessRolesOptionsById] =
    useState<Record<Id, BaseOption<string, any>>>();

  useEffect(() => {
    async function loadBusinessRoles() {
      if (!environment) {
        return;
      }
      const data = await fetchQuery<BasicFields_BusinessRolesQuery>(
        environment,
        businessRoleQuery,
        {
          businessId: params.business_id,
          sort: [
            {
              field: BusinessRoleSortField.BusinessRoleName,
              order: Order.Asc,
            },
          ],
        },
      ).toPromise();

      const allRoles = (data?.businessRoles.nodes || []).map((n) => {
        return {
          value: n ? n.businessRoleName : "", // store as business role name instead of id
          label: n ? n.businessRoleName : "",
          data: n,
        };
      });

      setBusinessRoles(allRoles.filter((i) => !i.data?.replaceByAos));
      setFlexibleRoles(allRoles.filter((i) => i.data?.replaceByAos));
      setBusinessRolesOptionsById(keyBy(allRoles, "value"));
    }

    loadBusinessRoles();
  }, [environment, params.business_id]);

  useEffect(() => {
    let selectedRoles: BaseOption<string, any>[] = [];
    if (values && businessRolesOptionsById) {
      selectedRoles = getIncludedRoleOptions(
        businessRolesOptionsById,
        (values.aosConfig.includeRoles || []) as Id[],
      );
    }

    setSelectedBusinessRoles(selectedRoles);
  }, [businessRolesOptionsById, values, values.aosConfig.includeRoles]);

  // Custom component rules
  const componentRules: Record<string, ComponentRule> = {
    "aosConfig.schedulingDayStart": {
      component: DateTimePicker,
      componentProps: {
        showTimeSelect: true,
        showTimeSelectOnly: true,
        timeIntervals: 30,
        valueFormat: "HH:mm",
        displayFormat: "HH:mm",
        timeFormat: "HH:mm",
      },
    },
    "aosConfig.shiftMaxStart": {
      component: DateTimePicker,
      componentProps: {
        showTimeSelect: true,
        showTimeSelectOnly: true,
        timeIntervals: 30,
        valueFormat: "HH:mm",
        displayFormat: "HH:mm",
        timeFormat: "HH:mm",
      },
    },
    "aosConfig.minShiftPartLength": {
      postfix: t("translation:form.minute_plural"),
    },
    "aosConfig.breakLength": {
      postfix: t("translation:form.minute_plural"),
    },
    "aosConfig.planningOrder": {
      component: OrderedList,
      componentProps: {
        options: daysOfWeek,
      },
      md: 4,
      lg: 3,
    },
    "aosConfig.includeRoles": {
      component: CheckboxList,
      componentProps: {
        options: businessRoles,
        defaultValue: null,
        placeholder: t("translation:form.search_placeholder"),
      },
      onValueChanged: (newValue: Id[]) => {
        if (businessRolesOptionsById) {
          const includeRoleOptions = getIncludedRoleOptions(
            businessRolesOptionsById,
            (newValue || []) as Id[],
          );
          setSelectedBusinessRoles(includeRoleOptions);
        }
      },
    },
    "aosConfig.demandOnly": {
      component: MultiSelectList,
      componentProps: {
        allOptions: businessRoles,
        selectableOptions: selectedBusinessRoles,
        defaultValue: null,
      },
    },
    "aosConfig.ignoreBreakRoles": {
      component: MultiSelectList,
      componentProps: {
        allOptions: businessRoles,
        selectableOptions: selectedBusinessRoles,
        defaultValue: null,
      },
    },
    "aosConfig.bonusRoles": {
      component: MultiSelectList,
      componentProps: {
        allOptions: businessRoles,
        selectableOptions: selectedBusinessRoles,
        defaultValue: null,
      },
    },
    "aosConfig.flexibleRole": {
      component: DynamicSelect,
      componentProps: {
        options: flexibleRoles,
        defaultValue: null,
        allowCreate: true,
      },
    },
    "aosConfig.maxRolesPerShift": {
      component: SelectionGroup,
      componentProps: {
        options: [
          { label: t("aos:roleCount", { count: 1 }), value: 1 },
          { label: t("aos:roleCount", { count: 2 }), value: 2 },
        ],
        formCheckType: "radio",
      },
    },
    "aosConfig.maxWorkdaysCalweek": {
      postfix: t("translation:form.day_plural"),
    },
    "aosConfig.maxWorkdaysWorkweek": {
      postfix: t("translation:form.day_plural"),
      lg: 6,
    },
    "aosConfig.overstaffingByRole": {
      component: MultiSelectList,
      componentProps: {
        allOptions: businessRoles,
        selectableOptions: selectedBusinessRoles,
        defaultValue: null,
        menuPlacement: "top",
      },
    },
    "aosConfig.understaffingByRole": {
      component: MultiSelectList,
      componentProps: {
        allOptions: businessRoles,
        selectableOptions: selectedBusinessRoles,
        defaultValue: null,
        menuPlacement: "top",
      },
    },
  };

  const aosGroupsByKey = keyBy(business.aosGroups || [], "group");

  return (
    <>
      <DynamicFormContext.Provider
        value={{
          propertyList: properties,
          componentRules,
        }}
      >
        <fieldset>
          {/* Scheduling Configuration */}
          <DynamicInputGroup
            disabled={disabled}
            fields={getSettingsByGroup(
              getFieldsByNames(properties as unknown as IProperty[], [
                "aosConfig.schedulingDayStart",
                "aosConfig.addUnderstaffingShifts",
              ]),
            )}
          />
        </fieldset>
        <fieldset>
          {/* Week Configuration */}
          <DynamicInputGroup
            disabled={disabled}
            fields={getSettingsByGroup(
              getFieldsByNames(properties as unknown as IProperty[], [
                "aosConfig.weeklyWorkhoursMin",
                "aosConfig.weeklyWorkhoursMax",
              ]),
            )}
          />
        </fieldset>
        <fieldset>
          <GroupHeader>
            {t("aos:basic.sections.shift_config.title")}
          </GroupHeader>
          <ShiftMinMax disabled={disabled} />
          <DynamicInputGroup
            hideGroupName
            disabled={disabled}
            fields={getSettingsByGroup(
              getFieldsByNames(properties as unknown as IProperty[], [
                "aosConfig.shiftMinAbsolute",
                "aosConfig.shiftGapMin",
                "aosConfig.shiftMaxStart",
                "aosConfig.maxRolesPerShift",
                "aosConfig.minShiftPartLength",
              ]),
            )}
          />
        </fieldset>

        <fieldset>
          {/* Breaks */}
          <DynamicInputGroup
            disabled={disabled}
            fields={getSettingsByGroup(
              getFieldsByNames(properties as unknown as IProperty[], [
                "aosConfig.breakTrigger",
                "aosConfig.breakLength",
                "aosConfig.breakMinStart",
                "aosConfig.breakMaxStart",
                "aosConfig.breakEndPad",
              ]),
            )}
          />
        </fieldset>

        <fieldset>
          {/* Roles */}
          <DynamicInputGroup
            disabled={disabled}
            fields={getSettingsByGroup(
              getFieldsByNames(properties as unknown as IProperty[], [
                "aosConfig.includeRoles",
                "aosConfig.demandOnly",
                "aosConfig.ignoreBreakRoles",
                "aosConfig.bonusRoles",
                "aosConfig.flexibleRole",
              ]),
            )}
          />
        </fieldset>

        <fieldset>
          <GroupHeader>
            {t("aos:basic.sections.custom_hours.title")}
          </GroupHeader>
          <EmployeeGroups
            aosGroupsByKey={
              aosGroupsByKey as Dictionary<{
                group: number | null;
                name: string | null;
              }>
            }
            customSettings={
              ((values?.aosConfig?.shiftConfig as any)?.custom_settings ||
                []) as EmployeeGroup[]
            }
            fieldKey="aosConfig.shiftConfig.custom_settings"
            disabled={disabled}
          />
        </fieldset>

        <fieldset>
          {/* AOS Shifts Template */}
          <DynamicInputGroup
            disabled={disabled}
            fields={getSettingsByGroup(
              getFieldsByNames(properties as unknown as IProperty[], [
                "aosConfig.coreObeysAosRules",
                "aosConfig.skipSchedulingManagers",
                "aosConfig.skipSchedulingCore",
                "aosConfig.addCoreBreaks",
                "aosConfig.optimizeCoreBreaks",
              ]),
            )}
          />
        </fieldset>

        <fieldset>
          <GroupHeader>
            {t("aos:basic.sections.peak_staffing_times")}
          </GroupHeader>
          <TimeRangeRanks disabled={disabled} />
        </fieldset>

        <fieldset>
          <GroupHeader>
            {t("aos:basic.sections.planning_order.title")}
          </GroupHeader>
          <PlanningOrder values={values.aosConfig} disabled={disabled} />
        </fieldset>

        <fieldset>
          <GroupHeader>
            {t("aos:basic.sections.optimization_goals.title")}
          </GroupHeader>
          <OptimizationGoals
            values={values.aosConfig}
            disabled={disabled}
            aosGroupsByKey={
              aosGroupsByKey as Dictionary<{
                group: number | null;
                name: string | null;
              }>
            }
          />

          <DynamicInputGroup
            hideGroupName
            disabled={disabled}
            fields={getSettingsByGroup(
              getFieldsByNames(properties as unknown as IProperty[], [
                "aosConfig.applyRule24HoursRest",
                "aosConfig.maxWorkdaysCalweek",
                "aosConfig.maxWorkdaysWorkweek",
                "aosConfig.overstaffingByRole",
                "aosConfig.understaffingByRole",
              ]),
            )}
          />
        </fieldset>
      </DynamicFormContext.Provider>
    </>
  );
};

export default BasicFields;
