import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Profile_user } from "./__generated__/Profile_user.graphql";

import properties from "../../../../data/csv-settings/user-settings.json";
import { idmInternalEnvironment } from "../../../../environment";
import FormLayout from "../../../common/Form/FormLayout";
import FormLayoutFooter from "../../../common/Form/FormLayoutFooter";
import { IProperty } from "../../../common/Form/models";
import DynamicInputGroup from "../../../common/Form/DynamicInputGroup";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../../common/Form/formUtilities";
import UpdateUserMutation from "../mutations/UpdateUserMutation";

type Props = {
  user: Profile_user;
};

const validationRules = yup.object({
  email: yup.string().email(),
  // TODO: add more rules
});

function Profile({ user }: Props) {
  const onSaved = () => {
    toast("Save successfully");
  };

  // Add a new event handler that fires off the mutation
  const handleSave = (
    values: Partial<Profile_user>,
    onError: (err: Error) => void,
  ) => {
    UpdateUserMutation(
      idmInternalEnvironment,
      user.id,
      values,
      onSaved,
      onError,
    );
  };

  if (user) {
    return (
      <div className="panel">
        <FormLayout<Profile_user>
          base={user}
          onSave={handleSave}
          propertyList={properties as unknown as IProperty[]}
          validationRules={validationRules}
        >
          <DynamicInputGroup
            fields={getSettingsByGroup(
              getFieldsByInputObjectName(
                properties as unknown as IProperty[],
                "UserInput",
              ),
            )}
          />
          <FormLayoutFooter />
        </FormLayout>
      </div>
    );
  }
  return <div>User not found</div>;
}

export default createFragmentContainer(
  Profile,
  // Each key specified in this object will correspond to a prop available to the component
  {
    user: graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment Profile_user on User {
        id
        email
        locale
      }
    `,
  },
);
