/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentMetadataUpsertInput = {
    details?: unknown | null | undefined;
    metadataTypeId: string;
};
export type EmploymentMetadataQueries_UpsertEmploymentMetadata_MutationVariables = {
    businessId: string;
    employmentId: string;
    input: Array<EmploymentMetadataUpsertInput>;
};
export type EmploymentMetadataQueries_UpsertEmploymentMetadata_MutationResponse = {
    readonly upsertEmploymentMetadata: ReadonlyArray<{
        readonly id: string;
        readonly createdAt: string;
        readonly updatedAt: string;
        readonly employmentId: string;
        readonly details: unknown;
        readonly metadataTypeId: string;
        readonly startTime: string | null;
        readonly endTime: string | null;
    }>;
};
export type EmploymentMetadataQueries_UpsertEmploymentMetadata_Mutation = {
    readonly response: EmploymentMetadataQueries_UpsertEmploymentMetadata_MutationResponse;
    readonly variables: EmploymentMetadataQueries_UpsertEmploymentMetadata_MutationVariables;
};



/*
mutation EmploymentMetadataQueries_UpsertEmploymentMetadata_Mutation(
  $businessId: ID!
  $employmentId: ID!
  $input: [EmploymentMetadataUpsertInput!]!
) {
  upsertEmploymentMetadata(businessId: $businessId, employmentId: $employmentId, input: $input) {
    id
    createdAt
    updatedAt
    employmentId
    details
    metadataTypeId
    startTime
    endTime
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "employmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "employmentId",
        "variableName": "employmentId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmploymentMetadata",
    "kind": "LinkedField",
    "name": "upsertEmploymentMetadata",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "details",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataTypeId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endTime",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentMetadataQueries_UpsertEmploymentMetadata_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentMetadataQueries_UpsertEmploymentMetadata_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f42828dd9b5a069e2e226ce17d12c128",
    "id": null,
    "metadata": {},
    "name": "EmploymentMetadataQueries_UpsertEmploymentMetadata_Mutation",
    "operationKind": "mutation",
    "text": "mutation EmploymentMetadataQueries_UpsertEmploymentMetadata_Mutation(\n  $businessId: ID!\n  $employmentId: ID!\n  $input: [EmploymentMetadataUpsertInput!]!\n) {\n  upsertEmploymentMetadata(businessId: $businessId, employmentId: $employmentId, input: $input) {\n    id\n    createdAt\n    updatedAt\n    employmentId\n    details\n    metadataTypeId\n    startTime\n    endTime\n  }\n}\n"
  }
};
})();
(node as any).hash = '9251bdec910736e1a9ee94dbc8a2c542';
export default node;
