import React, { Suspense } from "react";
import { graphql, RelayEnvironmentProvider, useFragment } from "react-relay";
import * as yup from "yup";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Profile_skillLevel } from "./__generated__/Profile_skillLevel.graphql";
import FormLayout from "../../common/Form/FormLayout";
import FormLayoutFooter from "../../common/Form/FormLayoutFooter";

import properties from "../../../data/csv-settings/skill-level-settings.json";
import HeaderPortal from "../../common/Portal/HeaderPortal";

import { useModal } from "../../../contexts/ModalContext";
import DeleteModal from "./Modals/DeleteModal";
import DynamicInputGroup from "../../common/Form/DynamicInputGroup";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../common/Form/formUtilities";
import { ComponentRule, IProperty } from "../../common/Form/models";
import { useBusinessContext } from "../../../contexts/BusinessContext";
import DynamicSelect from "../../common/Form/DynamicSelect";
import Checkbox from "../../common/Form/Checkbox";
import { SkillLevelAssignmentActionEnum } from "../../../data/generated/stack_internal_schema";
import { BaseOption } from "../../../data/models/common";
import type { Profile_skillLevel$key } from "./__generated__/Profile_skillLevel.graphql";

export interface MatchParams {
  stack_id: string;
  business_id: string;
  skill_level_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {
  skillLevel: Profile_skillLevel$key | null;
  onSave: (
    state: Partial<Profile_skillLevel>,
    onError: (err: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: Profile_skillLevel,
  ) => void;
  onDeleteSuccess: Function;
};

const validationRules = yup.object({
  name: yup.string().required(),
  level: yup.string().required(),
  assignmentAction: yup
    .string()
    .oneOf(Object.values(SkillLevelAssignmentActionEnum)),
  aosLevel: yup.number().nullable(true),
  isDefault: yup.boolean(),
});

const aosLevels: BaseOption<number | null, any>[] = [1, 2, 3].map((i) => ({
  label: i.toString(),
  value: i,
}));

function Profile(props: Props) {
  const {
    match: {
      params: { skill_level_id: skillLevelId, business_id: businessId },
    },
    skillLevel,
    onSave,
    onDeleteSuccess,
  } = props;

  const skillLevelFragment = useFragment(
    graphql`
      fragment Profile_skillLevel on SkillLevel {
        ### Replaceable content start
        name
        isDefault
        level
        aosLevel
        assignmentAction
        ### Replaceable content finish
      }
    `,
    skillLevel,
  );

  const isCreate = skillLevelId == null;
  const data = skillLevelFragment || ({} as Profile_skillLevel);

  const { environment, business } = useBusinessContext();
  const { t } = useTranslation("skill-levels");
  const { showModal, hideModal } = useModal();

  const componentRules: Record<string, ComponentRule> = {
    assignmentAction: {
      component: Checkbox,
      hideLabel: true,
      componentProps: {
        toBoolean: (v: SkillLevelAssignmentActionEnum) =>
          v === SkillLevelAssignmentActionEnum.Warn,
        fromBoolean: (v: boolean) =>
          v
            ? SkillLevelAssignmentActionEnum.Warn
            : SkillLevelAssignmentActionEnum.None,
      },
    },
    isDefault: {
      disabled: true,
    },
    aosLevel: {
      component: DynamicSelect,
      componentProps: {
        options: aosLevels,
        defaultValue: null,
        placeholder: t("form.doNotAutoSchedule"),
        isClearable: true,
      },
    },
  };

  if (business.dataLakeId == null || business.dataLakeId.trim().length === 0) {
    // overwrite description of AOS is disabled
    componentRules.aosLevel.description = t("form.aosDisabled");
  }

  const onDeleteClicked = () => {
    if (!data || !skillLevelId || !environment) {
      return;
    }

    showModal(
      <RelayEnvironmentProvider environment={environment}>
        <Suspense fallback="">
          <DeleteModal
            onClose={hideModal}
            businessId={businessId}
            id={skillLevelId}
            skillLevel={data}
            onSuccess={onDeleteSuccess}
            environment={environment}
          />
        </Suspense>
      </RelayEnvironmentProvider>,
    );
  };

  return (
    <>
      <FormLayout<Profile_skillLevel>
        isCreate={isCreate}
        base={data}
        onSave={onSave}
        propertyList={[]}
        validationRules={validationRules}
        componentRules={componentRules}
      >
        <DynamicInputGroup
          fields={getSettingsByGroup(
            getFieldsByInputObjectName(
              properties as unknown as IProperty[],
              "SkillLevelInput",
            ),
          )}
        />
        <FormLayoutFooter isCreate={isCreate} onDelete={onDeleteClicked} />
      </FormLayout>
      <HeaderPortal elementId="skill-level-header-portal" as="span">
        <span className="ml-2 mr-2">&gt;</span>
        <span>{isCreate ? t("layout.new") : data.name}</span>
      </HeaderPortal>
    </>
  );
}

export default Profile;
