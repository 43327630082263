import { Environment, fetchQuery, graphql } from "react-relay";
import { toast } from "react-toastify";
import { Id } from "../../../data/models/common";

import {
  BusinessService_SearchStackForEmployments_Query,
  BusinessService_SearchStackForEmployments_QueryResponse,
} from "./__generated__/BusinessService_SearchStackForEmployments_Query.graphql";

import { BusinessService_Businesses_Query } from "./__generated__/BusinessService_Businesses_Query.graphql";
import { BusinessService_Employments_Query } from "./__generated__/BusinessService_Employments_Query.graphql";
import {
  BusinessService_GetGlobalBusinessStack_Query,
  BusinessService_GetGlobalBusinessStack_QueryResponse,
} from "./__generated__/BusinessService_GetGlobalBusinessStack_Query.graphql";

import {
  BusinessService_GlobalBusinesses_Query,
  BusinessService_GlobalBusinesses_QueryResponse,
} from "./__generated__/BusinessService_GlobalBusinesses_Query.graphql";
import ServerError from "../../../utils/server-error";

const EmploymentsByUserIdQuery = graphql`
  query BusinessService_Employments_Query($businessId: ID!, $userId: ID) {
    employments(businessId: $businessId, userId: $userId) {
      edges {
        node {
          id
          firstName
          lastName
          acceptedInvite
          deleted
          email
          during
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const BusinessesQuery = graphql`
  query BusinessService_Businesses_Query($userId: ID) {
    businesses(userId: $userId) {
      edges {
        node {
          id
          businessName
        }
      }
    }
  }
`;

type GetBusinessFilter = {
  userId?: Id;
};

type GetEmploymentsFilter = {
  businessId: Id;
  userId: Id;
};

const SearchStackForEmploymentQuery = graphql`
  query BusinessService_SearchStackForEmployments_Query($userId: ID) {
    searchStackForEmployments(userId: $userId) {
      edges {
        node {
          id
          computedName
          acceptedInvite
          deleted
          email
          during
          createdAt
          updatedAt
          business {
            id
            businessName
          }
        }
      }
    }
  }
`;

export type Employment =
  BusinessService_SearchStackForEmployments_QueryResponse["searchStackForEmployments"]["edges"][number]["node"];

export type GlobalBusinessStack =
  BusinessService_GetGlobalBusinessStack_QueryResponse["globalBusinessStack"];

const GetGlobalBusinessStackQuery = graphql`
  query BusinessService_GetGlobalBusinessStack_Query {
    globalBusinessStack {
      id
      isSelf
      stackDomainName
    }
  }
`;

export type GlobalBusiness =
  BusinessService_GlobalBusinesses_QueryResponse["globalBusinesses"]["nodes"][number];

const GlobalBusinessesQuery = graphql`
  query BusinessService_GlobalBusinesses_Query {
    globalBusinesses {
      nodes {
        id
        name
        used
      }
    }
  }
`;

export default class BusinessService {
  /**
   * Find all employments within a stack by user id
   * @param environment environment to connect to
   * @param userId an id of user
   * @returns a list of employees within the stack with specified user id
   */
  public static async getStackEmploymentsByUserId(
    environment: Environment,
    userId: Id,
  ): Promise<Employment[]> {
    const result =
      await fetchQuery<BusinessService_SearchStackForEmployments_Query>(
        environment,
        SearchStackForEmploymentQuery,
        {
          userId,
        },
      ).toPromise();

    return (
      result?.searchStackForEmployments?.edges?.map((i) => i?.node ?? null) ??
      []
    );
  }

  /**
   * Fetch Employments matching specified business id and user id
   * @param environment environment to connect to
   * @param businessId an id of business
   * @param userId an id of user
   * @returns a list of employments
   */
  public static async getEmploymentsByUserId(
    environment: Environment,
    filter: GetEmploymentsFilter,
  ) {
    const result = await fetchQuery<BusinessService_Employments_Query>(
      environment,
      EmploymentsByUserIdQuery,
      filter,
    ).toPromise();

    return result?.employments.edges[0]?.node ?? null;
  }

  /**
   * Fetch Businesses matching specified user id
   * @param environment environment to connect to
   * @param userId an id of user
   * @returns a list of businesses
   */
  public static async getBusinesses(
    environment: Environment,
    filter: GetBusinessFilter = {},
  ) {
    const result = await fetchQuery<BusinessService_Businesses_Query>(
      environment,
      BusinessesQuery,
      filter,
    ).toPromise();

    const map = new Map();

    if (result?.businesses?.edges) {
      result.businesses.edges.forEach((i) => {
        if (i && i.node) {
          map.set(i.node.id, i.node);
        }
      });
    }

    return map;
  }

  /**
   * Get global business stack of current stack
   * @param environment environment to connect to
   * @returns the global business stack which current stack is set to
   */
  public static async getGlobalBusinessStack(environment: Environment) {
    const result =
      await fetchQuery<BusinessService_GetGlobalBusinessStack_Query>(
        environment,
        GetGlobalBusinessStackQuery,
        {},
      ).toPromise();
    return result?.globalBusinessStack;
  }

  /**
   * Fetch Global Businesses that this stack uses
   * @param environment environment to connect to
   * @returns a map of global businesses by id
   */
  public static async getGlobalBusinesses(
    environment: Environment,
  ): Promise<Map<Id, GlobalBusiness>> {
    let result;
    const map = new Map<Id, GlobalBusiness>();
    try {
      // TODO: remove this when backend exposes global business stack as part of stacks query
      const currentGlobalBusinessStack =
        await BusinessService.getGlobalBusinessStack(environment);

      // if global stack has been set
      if (currentGlobalBusinessStack) {
        result = await fetchQuery<BusinessService_GlobalBusinesses_Query>(
          environment,
          GlobalBusinessesQuery,
          {},
        ).toPromise();
      }
    } catch (error) {
      if (error instanceof ServerError) {
        // handle server errors including inter-stack errors
        // alert(error.getErrorDetails());
        toast(error.getErrorDetails());
      } else {
        alert((error as Error).message);
      }
    } finally {
      (result?.globalBusinesses?.nodes || []).forEach((i) => {
        if (i) {
          map.set(i.id, i);
        }
      });
    }

    return map;
  }
}
