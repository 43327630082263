import { graphql } from "react-relay";

export const UpdateBusinessPerformanceSettingsMutation = graphql`
  mutation PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation(
    $input: BusinessInput!
    $id: ID!
  ) {
    updateBusiness(id: $id, input: $input) {
      id
      ### Replaceable content start
      employmentPerformanceReviewEnabled
      employmentPerformanceReviewDetail {
        autoPublish
        adjustJtc
        reviewDueReminderDays
        regularReviewStartDate
        regularReviewPeriodMonths
        initialReviewEnabled
        initialReviewPeriodMonths
        adhocReviewEnabled
      }
      approvalRequests {
        employmentPerformanceReview {
          autoApprovalEnabled
        }
      }
      feedbackReasonEnabled
      feedbackCommentEnabled
      commendationEnabled
      approvalNeededForCommendation
      commendationEmploymentNotifications
      disciplinaryActionEnabled
      approvalNeededForDisciplinaryAction
      disciplinaryActionEmploymentNotifications
      ### Replaceable content finish
    }
  }
`;

export const GetBusinessPerformanceSettingsQuery = graphql`
  query PerformanceSettingsQueries_PerformanceSettingsQuery($businessId: ID!) {
    businesses(ids: [$businessId]) {
      edges {
        node {
          id
          ### Replaceable content start
          employmentPerformanceReviewEnabled
          employmentPerformanceReviewDetail {
            autoPublish
            adjustJtc
            reviewDueReminderDays
            regularReviewStartDate
            regularReviewPeriodMonths
            initialReviewEnabled
            initialReviewPeriodMonths
            adhocReviewEnabled
          }
          approvalRequests {
            employmentPerformanceReview {
              autoApprovalEnabled
            }
            employmentRateChange {
              autoApprovalEnabled
            }
          }
          employmentRateEditEnabled
          feedbackReasonEnabled
          feedbackCommentEnabled
          commendationEnabled
          approvalNeededForCommendation
          commendationEmploymentNotifications
          disciplinaryActionEnabled
          approvalNeededForDisciplinaryAction
          disciplinaryActionEmploymentNotifications
          ### Replaceable content finish
        }
      }
    }
  }
`;
