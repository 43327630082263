/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentInput = {
    code?: string | null | undefined;
    dateOfBirth?: string | null | undefined;
    email?: string | null | undefined;
    employmentRate?: number | null | undefined;
    employmentType?: string | null | undefined;
    employmentTypeCode?: string | null | undefined;
    employmentTypeSmallId?: number | null | undefined;
    external?: boolean | null | undefined;
    firstName?: string | null | undefined;
    imageId?: string | null | undefined;
    lastName?: string | null | undefined;
    mutedFeatures?: Array<string> | null | undefined;
    nickname?: string | null | undefined;
    payrollCode?: string | null | undefined;
    rating?: number | null | undefined;
    schoolId?: string | null | undefined;
    securityRole?: string | null | undefined;
    ssoCode?: string | null | undefined;
};
export type UpdateEmploymentMutationVariables = {
    input: EmploymentInput;
    id: string;
    businessId: string;
};
export type UpdateEmploymentMutationResponse = {
    readonly updateEmployment: {
        readonly id: string;
        readonly userId: string | null;
        readonly createdAt: string;
        readonly acceptedInvite: boolean;
        readonly during: string | null;
        readonly updatedAt: string;
        readonly userEmail: string | null;
        readonly businessInvite: {
            readonly id: string;
            readonly activationCode: string;
            readonly attemptsRemaining: number;
            readonly expiryTime: string;
            readonly inviteToken: string;
        } | null;
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly nickname: string | null;
        readonly dateOfBirth: string;
        readonly imageId: string | null;
        readonly schoolId: string | null;
        readonly external: boolean;
        readonly rating: number;
        readonly employmentRate: number;
        readonly securityRole: string;
        readonly code: string | null;
        readonly employmentTypeCode: string;
        readonly payrollCode: string | null;
        readonly mutedFeatures: ReadonlyArray<string> | null;
    };
};
export type UpdateEmploymentMutation = {
    readonly response: UpdateEmploymentMutationResponse;
    readonly variables: UpdateEmploymentMutationVariables;
};



/*
mutation UpdateEmploymentMutation(
  $input: EmploymentInput!
  $id: ID!
  $businessId: ID!
) {
  updateEmployment(businessId: $businessId, id: $id, input: $input) {
    id
    userId
    createdAt
    acceptedInvite
    during
    updatedAt
    userEmail
    businessInvite {
      id
      activationCode
      attemptsRemaining
      expiryTime
      inviteToken
    }
    email
    firstName
    lastName
    nickname
    dateOfBirth
    imageId
    schoolId
    external
    rating
    employmentRate
    securityRole
    code
    employmentTypeCode
    payrollCode
    mutedFeatures
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Employment",
    "kind": "LinkedField",
    "name": "updateEmployment",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "acceptedInvite",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "during",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessInvite",
        "kind": "LinkedField",
        "name": "businessInvite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activationCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "attemptsRemaining",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiryTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inviteToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nickname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateOfBirth",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schoolId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "external",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rating",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "securityRole",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentTypeCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "payrollCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mutedFeatures",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateEmploymentMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateEmploymentMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "1e6ee61f94fe8a1af94ad474a203247d",
    "id": null,
    "metadata": {},
    "name": "UpdateEmploymentMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateEmploymentMutation(\n  $input: EmploymentInput!\n  $id: ID!\n  $businessId: ID!\n) {\n  updateEmployment(businessId: $businessId, id: $id, input: $input) {\n    id\n    userId\n    createdAt\n    acceptedInvite\n    during\n    updatedAt\n    userEmail\n    businessInvite {\n      id\n      activationCode\n      attemptsRemaining\n      expiryTime\n      inviteToken\n    }\n    email\n    firstName\n    lastName\n    nickname\n    dateOfBirth\n    imageId\n    schoolId\n    external\n    rating\n    employmentRate\n    securityRole\n    code\n    employmentTypeCode\n    payrollCode\n    mutedFeatures\n  }\n}\n"
  }
};
})();
(node as any).hash = '86406489611c499328b56a3638c196f4';
export default node;
