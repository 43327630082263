/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AosQueries_AdvancedProfile_Fragment = {
    readonly id: string;
    readonly analyticsEnabled: boolean;
    readonly populateScheduleEnabled: boolean;
    readonly aosConfigSchema: unknown;
    readonly populateScheduleReplanningEnabled: boolean;
    readonly aosConfig: {
        readonly aosTimeout: number | null;
        readonly aosCombinedRoles: unknown | null;
        readonly aosOrchestratorConfig: unknown | null;
        readonly replanningTimeout: number | null;
        readonly replanningOrchestratorConfig: unknown | null;
    } | null;
    readonly " $refType": "AosQueries_AdvancedProfile_Fragment";
};
export type AosQueries_AdvancedProfile_Fragment$data = AosQueries_AdvancedProfile_Fragment;
export type AosQueries_AdvancedProfile_Fragment$key = {
    readonly " $data"?: AosQueries_AdvancedProfile_Fragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AosQueries_AdvancedProfile_Fragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AosQueries_AdvancedProfile_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "analyticsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "populateScheduleEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aosConfigSchema",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "populateScheduleReplanningEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AosConfig",
      "kind": "LinkedField",
      "name": "aosConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aosTimeout",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aosCombinedRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aosOrchestratorConfig",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "replanningTimeout",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "replanningOrchestratorConfig",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Schedule",
  "abstractKey": null
};
(node as any).hash = 'a80818ac34656b5de9e5f428fff4e893';
export default node;
