/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ScreenCodeEnum = "schedule" | "timekeeping" | "%future added value";
export type KpiComponentsQueries_GetKpiScreenConfigs_InternalQueryVariables = {
    businessId: string;
    screenCode?: ScreenCodeEnum | null | undefined;
};
export type KpiComponentsQueries_GetKpiScreenConfigs_InternalQueryResponse = {
    readonly kpiScreenConfigs: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly screenCode: ScreenCodeEnum;
            readonly updatedByName: string | null;
            readonly updatedById: string | null;
            readonly updatedAt: string;
            readonly kpiDisplayConfig: ReadonlyArray<{
                readonly displayLabel: string;
                readonly displayOrder: number;
                readonly displayTooltip: string | null;
                readonly kpiId: string;
                readonly targetId: string | null;
                readonly kpi: {
                    readonly id: string;
                    readonly code: string;
                    readonly createdAt: string;
                    readonly description: string;
                    readonly isCostRelated: boolean;
                    readonly name: string;
                    readonly updatedAt: string;
                } | null;
            }>;
        }>;
    };
};
export type KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery = {
    readonly response: KpiComponentsQueries_GetKpiScreenConfigs_InternalQueryResponse;
    readonly variables: KpiComponentsQueries_GetKpiScreenConfigs_InternalQueryVariables;
};



/*
query KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery(
  $businessId: ID!
  $screenCode: ScreenCodeEnum
) {
  kpiScreenConfigs(businessId: $businessId, screenCode: $screenCode) {
    nodes {
      id
      screenCode
      updatedByName
      updatedById
      updatedAt
      kpiDisplayConfig {
        displayLabel
        displayOrder
        displayTooltip
        kpiId
        targetId
        kpi {
          id
          code
          createdAt
          description
          isCostRelated
          name
          updatedAt
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "screenCode"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "screenCode",
        "variableName": "screenCode"
      }
    ],
    "concreteType": "KpiScreenConfigConnection",
    "kind": "LinkedField",
    "name": "kpiScreenConfigs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KpiScreenConfig",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "screenCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedByName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedById",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "KpiDisplayConfig",
            "kind": "LinkedField",
            "name": "kpiDisplayConfig",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayOrder",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayTooltip",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kpiId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "targetId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Kpi",
                "kind": "LinkedField",
                "name": "kpi",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCostRelated",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery",
    "selections": (v3/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b8c1adf3af87c65bae667bb5cb3142e0",
    "id": null,
    "metadata": {},
    "name": "KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery",
    "operationKind": "query",
    "text": "query KpiComponentsQueries_GetKpiScreenConfigs_InternalQuery(\n  $businessId: ID!\n  $screenCode: ScreenCodeEnum\n) {\n  kpiScreenConfigs(businessId: $businessId, screenCode: $screenCode) {\n    nodes {\n      id\n      screenCode\n      updatedByName\n      updatedById\n      updatedAt\n      kpiDisplayConfig {\n        displayLabel\n        displayOrder\n        displayTooltip\n        kpiId\n        targetId\n        kpi {\n          id\n          code\n          createdAt\n          description\n          isCostRelated\n          name\n          updatedAt\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '02118b8689cdf60d9cd4c709bc2568f4';
export default node;
