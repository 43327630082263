/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BusinessesQueries_GetCorporatesBusinessesQueryVariables = {};
export type BusinessesQueries_GetCorporatesBusinessesQueryResponse = {
    readonly businesses: {
        readonly nodes: ReadonlyArray<{
            readonly businessName: string;
            readonly id: string;
            readonly corporate: {
                readonly id: string;
                readonly name: string;
            } | null;
        } | null> | null;
    };
};
export type BusinessesQueries_GetCorporatesBusinessesQuery = {
    readonly response: BusinessesQueries_GetCorporatesBusinessesQueryResponse;
    readonly variables: BusinessesQueries_GetCorporatesBusinessesQueryVariables;
};



/*
query BusinessesQueries_GetCorporatesBusinessesQuery {
  businesses {
    nodes {
      businessName
      id
      corporate {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BusinessConnection",
    "kind": "LinkedField",
    "name": "businesses",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Business",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessName",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Corporate",
            "kind": "LinkedField",
            "name": "corporate",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessesQueries_GetCorporatesBusinessesQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BusinessesQueries_GetCorporatesBusinessesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d005f027f3b2633680acedb85ab06ef7",
    "id": null,
    "metadata": {},
    "name": "BusinessesQueries_GetCorporatesBusinessesQuery",
    "operationKind": "query",
    "text": "query BusinessesQueries_GetCorporatesBusinessesQuery {\n  businesses {\n    nodes {\n      businessName\n      id\n      corporate {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ac8ddf48e5ea07da49aa4f75543e2139';
export default node;
