/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentTypeEnum = "casual" | "full_time" | "part_time" | "permanent" | "%future added value";
export type LegacyBreakTypeEnum = "meal" | "rest" | "unknown" | "%future added value";
export type BreakTypeCreateInput = {
    availableRange?: HoursMinutesRangeCreateInput | null | undefined;
    breakScreenOrdering: number;
    breakTypeCode: string;
    canOverrideClockedPaidBreak: boolean;
    code?: string | null | undefined;
    defaultDuration?: unknown | null | undefined;
    description?: string | null | undefined;
    employmentMetadata?: string | null | undefined;
    employmentTypeCodes?: Array<string> | null | undefined;
    employmentTypeSmallIds?: Array<number> | null | undefined;
    employmentTypes?: Array<EmploymentTypeEnum> | null | undefined;
    internalAccess?: boolean | null | undefined;
    legacyBreakType: LegacyBreakTypeEnum;
    name: string;
    paidThreshold: unknown;
    schedulable?: boolean | null | undefined;
};
export type HoursMinutesRangeCreateInput = {
    rangeEndTime: unknown;
    rangeStartTime: unknown;
};
export type BreakTypeQueries_CreateBreakType_MutationVariables = {
    businessId: string;
    input: BreakTypeCreateInput;
};
export type BreakTypeQueries_CreateBreakType_MutationResponse = {
    readonly createBreakType: {
        readonly name: string;
        readonly breakTypeCode: string;
        readonly legacyBreakType: LegacyBreakTypeEnum;
        readonly breakScreenOrdering: number;
        readonly description: string | null;
        readonly code: string | null;
        readonly internalAccess: boolean;
        readonly defaultDuration: unknown | null;
        readonly availableRange: {
            readonly rangeStartTime: unknown;
            readonly rangeEndTime: unknown;
        } | null;
        readonly schedulable: boolean;
        readonly paidThreshold: unknown;
        readonly canOverrideClockedPaidBreak: boolean;
        readonly employmentTypeCodes: ReadonlyArray<string>;
        readonly employmentMetadata: string | null;
    };
};
export type BreakTypeQueries_CreateBreakType_Mutation = {
    readonly response: BreakTypeQueries_CreateBreakType_MutationResponse;
    readonly variables: BreakTypeQueries_CreateBreakType_MutationVariables;
};



/*
mutation BreakTypeQueries_CreateBreakType_Mutation(
  $businessId: ID!
  $input: BreakTypeCreateInput!
) {
  createBreakType(businessId: $businessId, input: $input) {
    name
    breakTypeCode
    legacyBreakType
    breakScreenOrdering
    description
    code
    internalAccess
    defaultDuration
    availableRange {
      rangeStartTime
      rangeEndTime
    }
    schedulable
    paidThreshold
    canOverrideClockedPaidBreak
    employmentTypeCodes
    employmentMetadata
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "breakTypeCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legacyBreakType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "breakScreenOrdering",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalAccess",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultDuration",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "HoursMinutesRange",
  "kind": "LinkedField",
  "name": "availableRange",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rangeStartTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rangeEndTime",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "schedulable",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paidThreshold",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canOverrideClockedPaidBreak",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentTypeCodes",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employmentMetadata",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueries_CreateBreakType_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BreakType",
        "kind": "LinkedField",
        "name": "createBreakType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BreakTypeQueries_CreateBreakType_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BreakType",
        "kind": "LinkedField",
        "name": "createBreakType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3e9d0568a2f7282dc899164f37e535a",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueries_CreateBreakType_Mutation",
    "operationKind": "mutation",
    "text": "mutation BreakTypeQueries_CreateBreakType_Mutation(\n  $businessId: ID!\n  $input: BreakTypeCreateInput!\n) {\n  createBreakType(businessId: $businessId, input: $input) {\n    name\n    breakTypeCode\n    legacyBreakType\n    breakScreenOrdering\n    description\n    code\n    internalAccess\n    defaultDuration\n    availableRange {\n      rangeStartTime\n      rangeEndTime\n    }\n    schedulable\n    paidThreshold\n    canOverrideClockedPaidBreak\n    employmentTypeCodes\n    employmentMetadata\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '6db2a2c30ec1154d6136af8c35716747';
export default node;
