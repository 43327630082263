import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import cloneDeep from "lodash/cloneDeep";
import isFunction from "lodash/isFunction";
import { BaseOption } from "../../../data/models/common";
import ButtonIcon from "../ButtonIcon";

type Props<T> = {
  options: BaseOption<T, any>[];
  value: T[];
  fieldKey: string;
  onChange: (newValue: T[]) => void;
  disabled?: boolean;
};

export default function OrderedList<T>(props: Props<T>) {
  const getOptionFromValue = (value: T) => {
    return props.options.find((i) => i.value === value);
  };

  const {
    value,
    onChange,
    // fieldKey,
    // options,
    disabled,
    // ...rest
  } = props;

  return (
    <>
      <ListGroup>
        {(value || []).map((v: T, index: number) => {
          const option = getOptionFromValue(v);
          if (option == null) {
            return null;
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <ListGroup.Item key={index} className="hoverable">
              <Row>
                <Col>{option.label}</Col>
                <Col md="auto">
                  <ButtonIcon
                    icon={faChevronUp}
                    disabled={disabled || index === 0}
                    onClick={() => {
                      const temp = cloneDeep(value);
                      if (index === 0) {
                        return;
                      }
                      temp.splice(index, 1);
                      temp.splice(index - 1, 0, v);

                      if (isFunction(onChange)) {
                        onChange(temp);
                      }
                    }}
                  />
                </Col>
                <Col md="auto">
                  <ButtonIcon
                    icon={faChevronDown}
                    disabled={disabled || index === value.length - 1}
                    onClick={() => {
                      const temp = cloneDeep(value);
                      if (index === temp.length - 1) {
                        return;
                      }
                      temp.splice(index, 1);
                      temp.splice(index + 1, 0, v);

                      if (isFunction(onChange)) {
                        onChange(temp);
                      }
                    }}
                  />
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </>
  );
}
