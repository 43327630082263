import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Environment, usePreloadedQuery } from "react-relay";
import * as yup from "yup";
import { FormikContext, FormikContextType } from "formik";
import { RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import omit from "lodash/omit";
import uniqBy from "lodash/uniqBy";
import GroupHeader from "../../../../common/Form/GroupHeader";
import { ComponentRule, IProperty } from "../../../../common/Form/models";
import FormLayout from "../../../../common/Form/FormLayout";
import properties from "../../../../../data/csv-settings/schedule-aos-settings.json";
import { TransformedAosConfigBasic, WeeklyDayparts } from "../models";
import { LaborCostOptimization } from "../LaborCostOptimization";
import SubGroupHeader from "../../../../common/Form/SubGroupHeader";
import UpdateScheduleAOSBasicMutation from "../../../mutations/UpdateScheduleAOSBasicMutation";
import {
  getSettingsByGroup,
  getFieldsByNames,
  ServerValidationConfig,
} from "../../../../common/Form/formUtilities";
import DynamicInputGroup from "../../../../common/Form/DynamicInputGroup";
import DateTimePicker from "../../../../common/Form/DateTimePicker";
import BasicFields from "./BasicFields";
import {
  DaypartRanksObject,
  WeeklyDaypartObject,
  toFormData,
  fromFormData,
} from "../TimeRangeRanks";
import { getBasicAOSConfigSchema } from "../aosUtils";
import AOSFooter from "../AOSFooter";
import { BasicProfileQuery } from "../AosQueries";
import { useBusinessContext } from "../../../../../contexts/BusinessContext";

type FormData = any & {
  aosConfig: TransformedAosConfigBasic;
};

interface MatchParams {
  business_id: string;
  stack_id: string;
  schedule_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  environment: Environment;
  queryReference: any;
};

const DefaultLabourCostPercentage = [28, 28, 28, 28, 28, 28, 28];

export default function Basic(props: Props) {
  const { t } = useTranslation();
  const { business } = useBusinessContext();

  const { schedules } = usePreloadedQuery<any>(
    BasicProfileQuery,
    props.queryReference,
  );

  const schedule = schedules.edges[0]?.node ?? null;

  const { defaults, validationRules, componentRules } = useAosConfigSchema(
    schedule?.aosConfigSchema as ServerValidationConfig,
  );

  // Add a new event handler that fires off the mutation
  const handleSave = (
    changes: Partial<FormData>,
    onError: (err: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: FormData,
  ) => {
    const { environment } = props;
    if (schedule) {
      let aosConfig = {};
      if (changes.aosConfig) {
        aosConfig = omit(changes.aosConfig, [
          "weeklyDaypartsData",
          "useGlobalPreference",
          "globalPreference",
        ]);

        const extraData = fromFormData(values?.aosConfig);
        aosConfig = {
          ...aosConfig,
          ...extraData,
        };
      }

      UpdateScheduleAOSBasicMutation(
        environment,
        {
          ...changes,
          aosConfig,
        },
        schedule.id,
        props.match.params.business_id,
        () => {
          toast(t("translation:form.notifications.saved_successfully"));
        },
        onError,
      );
    }
  };

  if (!business || schedule?.aosConfig == null) {
    return null;
  }

  return (
    <FormLayout<FormData>
      base={{
        ...schedule,
        aosConfig: {
          ...schedule.aosConfig,
          ...toFormData(
            schedule.aosConfig.weeklyDayparts as WeeklyDaypartObject,
            schedule.aosConfig.daypartRanks as DaypartRanksObject,
          ),
        },
      }}
      onSave={handleSave}
      propertyList={properties as unknown as IProperty[]}
      validationRules={validationRules}
      componentRules={componentRules}
    >
      <FormikContext.Consumer>
        {(formikContext: FormikContextType<FormData>) => {
          const { values, handleChange } = formikContext;

          // Disable this if Analytics Enabled is not enabled in business/schedule settings
          const isDisabled =
            !values.populateScheduleEnabled ||
            !schedule.analyticsEnabled ||
            !business.analyticsEnabled;

          return (
            <>
              <Form.Group>
                <SubGroupHeader className="text-uppercase">
                  {t("aos:basic.sections.enable_auto_scheduling")}
                </SubGroupHeader>
                <Form.Check
                  type="switch"
                  id="enable"
                  label={values.populateScheduleEnabled ? "On" : "Off"}
                  name="populateScheduleEnabled"
                  checked={values.populateScheduleEnabled}
                  onChange={handleChange}
                  disabled={!business.analyticsEnabled}
                />
              </Form.Group>
              <fieldset>
                {/* Generation Method */}
                <DynamicInputGroup
                  disabled={isDisabled}
                  fields={getSettingsByGroup(
                    getFieldsByNames(properties as unknown as IProperty[], [
                      "autoPopulateScheduleEnabled",
                      "autoPopulateScheduleDaysInAdvance",
                      "autoPopulateScheduleAtTime",
                    ]),
                  )}
                />
              </fieldset>

              <fieldset>
                {/* Labor Cost Optimization */}
                <GroupHeader>
                  {t("aos:basic.sections.labour_cost_optimizations.title")}
                </GroupHeader>
                <Row className="mb-2">
                  <Col md={6}>
                    <Form.Text className="text-muted">
                      {t(
                        "aos:basic.sections.labour_cost_optimizations.disclaimer",
                      )}
                    </Form.Text>
                  </Col>
                </Row>
                <DynamicInputGroup
                  hideGroupName
                  disabled={isDisabled}
                  fields={getSettingsByGroup(
                    getFieldsByNames(properties as unknown as IProperty[], [
                      "labourCostPercentageEnabled",
                      "labourCostPercentage",
                    ]),
                  )}
                />
              </fieldset>

              {values?.aosConfig ? (
                <BasicFields
                  {...props}
                  values={values}
                  properties={properties as unknown as IProperty[]}
                  disabled={isDisabled}
                />
              ) : null}

              <AOSFooter
                {...props}
                onReset={() => {
                  const { aosConfig: aosConfigValues } = values;
                  const newAosConfig = { ...aosConfigValues, ...defaults };
                  formikContext.setValues({
                    ...values,
                    aosConfig: newAosConfig || {},
                    labourCostPercentage: values.labourCostPercentageEnabled
                      ? DefaultLabourCostPercentage
                      : null,
                  });
                }}
              />
            </>
          );
        }}
      </FormikContext.Consumer>
    </FormLayout>
  );
}

function useAosConfigSchema(aosConfigSchema: ServerValidationConfig) {
  const { t } = useTranslation();

  const result = getBasicAOSConfigSchema(
    t,
    aosConfigSchema,
    properties as unknown as IProperty[],
  );

  const validationRules = yup.object({
    autoPopulateScheduleDaysInAdvance: yup.number().min(0).max(30),
    weeklyDaypartsData: yup
      .array()
      .test(
        "unique-daypart-name",
        t("aos:basic.property.weeklyDaypartsData.validationUnique"),
        (value: WeeklyDayparts[]) => {
          const daypartNames = uniqBy(value || [], (i) => i.daypart.name);
          return daypartNames.length === (value || []).length;
        },
      ),
    labourCostPercentage: yup
      .array()
      .of(
        yup
          .number()
          .min(0)
          .max(100)
          .label(
            t(
              "aos:basic.sections.labour_cost_optimizations.labour_cost_percentage",
            ),
          ),
      )
      .nullable(),
    aosConfig: yup.object().when("populateScheduleEnabled", {
      is: true,
      then: result.aosConfigValidationRules,
    }),
  });

  // Custom component rules
  const componentRules: Record<string, ComponentRule> = {
    autoPopulateScheduleDaysInAdvance: {
      postfix: t("translation:form.day_plural"),
    },
    labourCostPercentageEnabled: {
      onValueChanged: (
        newValue: boolean,
        formikContext: FormikContextType<FormData>,
      ) => {
        if (newValue) {
          const labourCostPercentageKey = "labourCostPercentage";
          const meta = formikContext.getFieldMeta(labourCostPercentageKey);
          if (meta.value == null) {
            formikContext.setFieldValue(
              labourCostPercentageKey,
              DefaultLabourCostPercentage,
            );
          }
        }
      },
    },
    labourCostPercentage: {
      component: LaborCostOptimization,
      componentProps: {
        hideError: true,
      },
      disabled: (values: FormData) =>
        !values.populateScheduleEnabled || !values.labourCostPercentageEnabled,
      xs: 12,
      md: 12,
      lg: 9,
    },
    autoPopulateScheduleAtTime: {
      component: DateTimePicker,
      componentProps: {
        showTimeSelect: true,
        showTimeSelectOnly: true,
        timeIntervals: 60,
        valueFormat: "HH",
        displayFormat: "HH:mm",
        timeFormat: "HH:mm",
      },
    },
  };

  return {
    validationRules,
    defaults: result.defaults,
    componentRules,
  };
}
