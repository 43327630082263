import React, { FunctionComponent, PropsWithChildren } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { WithTranslation, withTranslation } from "react-i18next";
import { useModal } from "../../contexts/ModalContext";

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type OkCancelModalProps = WithTranslation & {
  onOk: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  okLabel?: string;
  cancelLabel?: string;
};

const OkCancelModal: FunctionComponent<
  PropsWithChildren<OkCancelModalProps>
> = ({ onOk, title, okLabel, cancelLabel, t, children }) => {
  const { hideModal } = useModal();

  const onHideModal = () => {
    hideModal();
  };

  return (
    <Modal show onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHideModal}>
          {cancelLabel || t("form.actions.cancel")}
        </Button>
        <Button variant="primary" onClick={onOk}>
          {okLabel || t("form.actions.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withTranslation()(OkCancelModal);
