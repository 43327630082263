import { graphql, useLazyLoadQuery, useMutation } from "react-relay";
import { EmploymentMetadataQueries_GetEmploymentMetadata_Query } from "./__generated__/EmploymentMetadataQueries_GetEmploymentMetadata_Query.graphql";
import { MetadataTypesQueries_MetaDataTypesQuery } from "../../Business/MetadataTypes/__generated__/MetadataTypesQueries_MetaDataTypesQuery.graphql";
import { GetAllBusinessMetadataTypesQuery } from "../../Business/MetadataTypes/MetadataTypesQueries";
import {
  EmploymentMetadata,
  EmploymentMetadataEdge,
  MetadataType,
} from "../../../../data/generated/stack_internal_schema";
import {
  EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query,
  EmploymentMetadataQueries_GetEmploymentScheduleHistories_QueryResponse,
} from "./__generated__/EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query.graphql";
import { useAppRouter } from "../../../../hooks/useAppRouter";

export const GetMetadataTypesQuery = graphql`
  query EmploymentMetadataQueries_MetaDataTypes_Query($businessId: ID!) {
    metadataTypes(businessId: $businessId) {
      nodes {
        id
        displayName
        name
        dataType
        encrypted
        external
        internalAccess
        isTimeboxed
        maximum
        minimum
        validValues
        obfuscatedNumber
        objectClass
        required
        updatedAt
        createdAt
      }
    }
  }
`;

export const GetEmploymentMetadataQuery = graphql`
  query EmploymentMetadataQueries_GetEmploymentMetadata_Query(
    $businessId: ID!
    $employmentId: ID
    $timeboxed: Boolean
  ) {
    employmentMetadata(
      businessId: $businessId
      employmentId: $employmentId
      timeboxed: $timeboxed
      first: null
    ) @connection(key: "connection_employmentMetadata") {
      __id
      edges {
        node {
          id
          createdAt
          updatedAt
          employmentId
          details
          metadataTypeId
          startTime
          endTime
        }
      }
    }
  }
`;

export const UpsertEmploymentMetadataMutation = graphql`
  mutation EmploymentMetadataQueries_UpsertEmploymentMetadata_Mutation(
    $businessId: ID!
    $employmentId: ID!
    $input: [EmploymentMetadataUpsertInput!]!
  ) {
    upsertEmploymentMetadata(
      businessId: $businessId
      employmentId: $employmentId
      input: $input
    ) {
      id
      createdAt
      updatedAt
      employmentId
      details
      metadataTypeId
      startTime
      endTime
    }
  }
`;

export const CreateEmploymentMetadataMutation = graphql`
  mutation EmploymentMetadataQueries_CreateEmploymentMetadata_Mutation(
    $businessId: ID!
    $input: EmploymentMetadataCreateInput!
  ) {
    createEmploymentMetadata(businessId: $businessId, input: $input) {
      id
      createdAt
      updatedAt
      employmentId
      details
      metadataTypeId
      startTime
      endTime
    }
  }
`;

export const UpdateEmploymentMetadataMutation = graphql`
  mutation EmploymentMetadataQueries_UpdateEmploymentMetadata_Mutation(
    $businessId: ID!
    $id: ID!
    $input: EmploymentMetadataUpdateInput!
  ) {
    updateEmploymentMetadata(businessId: $businessId, id: $id, input: $input) {
      id
      createdAt
      updatedAt
      employmentId
      details
      metadataTypeId
      startTime
      endTime
    }
  }
`;

export const DeleteEmploymentMetadataMutation = graphql`
  mutation EmploymentMetadataQueries_DeleteEmploymentMetadata_Mutation(
    $businessId: ID!
    $id: ID!
  ) {
    deleteEmploymentMetadata(businessId: $businessId, id: $id)
  }
`;

export const GetEmploymentScheduleHistories = graphql`
  query EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query(
    $businessId: ID!
    $employmentId: ID!
  ) {
    employmentScheduleHistories(
      businessId: $businessId
      employmentId: $employmentId
    ) {
      nodes {
        id
        isHomeSchedule
        employmentId
        schedule {
          dayStartTime
          timeZone
        }
      }
    }
  }
`;

export type EmployeeHomeStore =
  ({} & EmploymentMetadataQueries_GetEmploymentScheduleHistories_QueryResponse["employmentScheduleHistories"]["nodes"])[number];

// Use this hook when trying to access employment metadata or business metadata types - saves having to worry about which order they're fetched
// Returns [data, mutations]
export function useEmployeeMetadataQueries(isTimeboxed = false) {
  const {
    params: { employment_id: employmentId, business_id: businessId },
  } = useAppRouter<{
    employment_id: string;
    business_id: string;
  }>();

  const [upsertEmploymentMetadata] = useMutation(
    UpsertEmploymentMetadataMutation,
  );
  const [deleteEmploymentMetadata] = useMutation(
    DeleteEmploymentMetadataMutation,
  );
  const [createEmploymentMetadata] = useMutation(
    CreateEmploymentMetadataMutation,
  );
  const [updateEmploymentMetadata] = useMutation(
    UpdateEmploymentMetadataMutation,
  );

  // Both business employment metadata types and employment metadata types are needed for
  // displaying all available metadata types as well as mapping values to each field

  const employmentMetadataResult =
    useLazyLoadQuery<EmploymentMetadataQueries_GetEmploymentMetadata_Query>(
      GetEmploymentMetadataQuery,
      {
        businessId,
        employmentId,
        timeboxed: isTimeboxed,
      },
      {
        fetchPolicy: "network-only",
      },
    );

  const businessMetadataTypesResult =
    useLazyLoadQuery<MetadataTypesQueries_MetaDataTypesQuery>(
      GetAllBusinessMetadataTypesQuery,
      {
        businessId,
      },
      {
        fetchPolicy: "network-only",
      },
    );

  const employmentScheduleHistories =
    useLazyLoadQuery<EmploymentMetadataQueries_GetEmploymentScheduleHistories_Query>(
      GetEmploymentScheduleHistories,
      {
        businessId,
        employmentId,
      },
    );

  const businessMetadataTypes = businessMetadataTypesResult.metadataTypes
    .nodes as MetadataType[];

  const employmentMetadataEdges = employmentMetadataResult.employmentMetadata
    .edges as EmploymentMetadataEdge[];

  const employmentMetadata = employmentMetadataEdges.map(
    (edge: EmploymentMetadataEdge) => {
      return edge.node as EmploymentMetadata;
    },
  );

  const employeeHomeStore =
    employmentScheduleHistories.employmentScheduleHistories.nodes.filter(
      (scheduleHistory) => scheduleHistory.isHomeSchedule,
    )[0] ?? null;

  return [
    // data
    {
      employmentMetadata,
      businessMetadataTypes: businessMetadataTypes.filter(
        (metadataType) => metadataType.isTimeboxed === isTimeboxed,
      ),
      employmentMetadataConnectionId:
        employmentMetadataResult.employmentMetadata.__id,
      employeeHomeStore,
    },
    // mutations
    {
      upsertEmploymentMetadata,
      deleteEmploymentMetadata,
      createEmploymentMetadata,
      updateEmploymentMetadata,
    },
  ] as const;
}
