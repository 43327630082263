import React, { FormEvent, useEffect, useRef } from "react";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { useFormikContext } from "formik";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import JsonEditor from "../../../common/Form/JsonEditor";
import {
  RuleSet,
  RuleSetContents,
  RuleSetFetch,
  ScheduleRulesInfo,
  SubscriptionStatus,
} from "./models";

import Footer from "../../../common/Form/Footer";
import { BaseOption, Option } from "../../../../data/models/common";

type RulesConfigurationFormProps = {
  ruleSets: RuleSetFetch;
  fetchAllRules(): void;
  removeRules(): void;
};

const findHighestProductionVersion = (
  ruleSet: RuleSet,
): RuleSetContents | undefined => {
  // Note: contents is always in descending version order
  return ruleSet.contents.find((c) => c.level.toLowerCase() === "production");
};

const createOption = (labelAndValue: any) => {
  return { label: labelAndValue, value: labelAndValue };
};

const subscriptionOptions = [
  { value: SubscriptionStatus.NOT_SUBSCRIBED, label: "Not Subscribed" },
  { value: SubscriptionStatus.STAGING, label: "Staging" },
  { value: SubscriptionStatus.PRODUCTION, label: "Production" },
];

export const RULES_CONFIG_VALIDATION = Object.freeze(
  yup.object({
    ruleSetName: yup.string(),
    ruleSetSubscriptionStatus: yup.string().required(),
    ruleSetMeta: yup.mixed().isValidJson("Meta"),
    ruleSetVersion: yup
      .string()
      .notRequired()
      .when("ruleSetName", {
        is: (value) => !!value,
        then: yup.string().required().typeError("Version must be selected"),
        else: yup.string().notRequired(),
      }),
  }),
);

export default function RulesConfigurationForm(
  props: RulesConfigurationFormProps,
) {
  const jsonRef = useRef<any>();
  const { t } = useTranslation();
  const { fetchAllRules, removeRules } = props;
  const {
    handleSubmit,
    handleReset,
    errors,
    values,
    initialValues,
    setFieldValue,
  } = useFormikContext<ScheduleRulesInfo>();

  useEffect(() => {
    if (jsonRef && jsonRef.current) {
      jsonRef.current.set(values.ruleSetMeta);
    }
  }, [jsonRef, values.ruleSetMeta]);

  const loadingRuleSets = (): boolean => {
    return props.ruleSets.loading;
  };

  const processRuleSetsIntoOptions = (): Array<Option<RuleSet>> => {
    return props.ruleSets.data.map((rs) => {
      return { label: rs.name, value: rs.name, data: rs };
    });
  };

  const getVersionsFromRuleSet = (
    ruleSetName: string | null,
  ): Array<Option<RuleSetContents>> => {
    if (ruleSetName === null) {
      return [];
    }
    return (
      props.ruleSets.data
        .find((rs) => rs.name === ruleSetName)
        ?.contents?.map((c) => {
          return {
            value: c.version,
            label: `${c.version} (${c.level})`,
            data: c,
          };
        }) || []
    );
  };

  const setSubscriptionStatus = (subscriptionStatus: SubscriptionStatus) => {
    setFieldValue("ruleSetSubscriptionStatus", subscriptionStatus);
  };

  const setName = (name: string) => {
    setFieldValue("ruleSetName", name);
  };

  const handleRulesetChange = (selected: Option<RuleSet>) => {
    setName(selected.value);
    const version =
      selected.data != null
        ? findHighestProductionVersion(selected.data)?.version
        : null;
    if (version == null) {
      clearVersion();
    } else {
      setVersion(version);
    }
  };

  /**
   * Set the version of the selected rule set to use.
   *
   * If a ruleSetName is non-null, ruleSetVersion also has to be non-null. If the selected ruleSet does not have any
   * associated versions, then it should not be able to be selected in the first place.
   *
   * @param version The version of the ruleset to use. If null, it means the ruleSet was unselected an a version
   * should not be set.
   */
  const setVersion = (version: string | null) => {
    setFieldValue("ruleSetVersion", version);
  };

  const clearVersion = () => {
    setVersion(null);
  };

  const handleSubscriptionStatusChange = (
    newValues: ScheduleRulesInfo,
    subOptionSelected: BaseOption<SubscriptionStatus, null>,
  ) => {
    // We should always get subOptionSelected of type Option, as subscription status is scalar.
    const subValue = subOptionSelected.value;

    setSubscriptionStatus(subValue);

    if (subValue === SubscriptionStatus.NOT_SUBSCRIBED) {
      return;
    }

    const ruleSet = props.ruleSets.data.find(
      (r) => r.name === newValues.ruleSetName,
    );
    if (ruleSet == null || ruleSet.contents.length === 0) {
      return;
    }

    let contents: RuleSetContents | undefined;
    if (subValue === SubscriptionStatus.PRODUCTION) {
      contents = findHighestProductionVersion(ruleSet);
    } else if (subValue === SubscriptionStatus.STAGING) {
      const [c] = ruleSet.contents;
      contents = c;
    }

    if (contents !== undefined) {
      setVersion(contents.version);
    }
  };

  return (
    <div>
      <Form onSubmit={(e) => handleSubmit(e as FormEvent<HTMLFormElement>)}>
        <Row>
          <Col md={12} lg={5}>
            <Form.Group controlId="ruleSetName">
              <Form.Label>Name</Form.Label>
              <Select
                cacheOptions
                isLoading={loadingRuleSets()}
                onChange={(selectedRaw) =>
                  handleRulesetChange(selectedRaw as Option<RuleSet>)
                }
                value={createOption(values.ruleSetName)}
                onMenuOpen={() => fetchAllRules()}
                options={processRuleSetsIntoOptions()}
              />
            </Form.Group>
          </Col>
          <Col md={12} lg={5}>
            <Form.Group controlId="ruleSetVersion">
              <Form.Label>
                Version{" "}
                {errors.ruleSetVersion && (
                  <span className="text-danger">{errors.ruleSetVersion}</span>
                )}
              </Form.Label>
              <Select
                onChange={(selected) => {
                  setVersion((selected as Option<RuleSetContents>)?.value);
                }}
                onMenuOpen={() => fetchAllRules()}
                isLoading={loadingRuleSets()}
                isDisabled={values.ruleSetName == null}
                value={createOption(values.ruleSetVersion)}
                options={getVersionsFromRuleSet(values.ruleSetName)}
              />
            </Form.Group>
          </Col>
          <Col md={12} lg={5}>
            <Form.Group controlId="ruleSetSubscriptionStatus">
              <Form.Label>Subscription status</Form.Label>
              <Select
                onChange={(selected) => {
                  if (selected == null) {
                    // We don't want to do anything if null.
                    return;
                  }

                  handleSubscriptionStatusChange(
                    values,
                    selected as BaseOption<SubscriptionStatus, null>,
                  );
                }}
                isDisabled={values.ruleSetName == null}
                value={createOption(values.ruleSetSubscriptionStatus)}
                options={subscriptionOptions}
              />
              <div className="mt-3">
                <ul>
                  <li>
                    <strong>Not subscribed</strong>: Schedule will never
                    automatically update the rule set version it uses.
                  </li>
                  <li>
                    <strong>Production</strong>: Schedule will automatically
                    update to newer production versions.
                  </li>
                  <li>
                    <strong>Staging</strong>: Schedule will always update to the
                    latest version.
                  </li>
                </ul>
              </div>
            </Form.Group>
          </Col>
          <Col md={12} lg={5}>
            <Form.Group controlId="ruleSetMeta">
              <Form.Label>Meta</Form.Label>
              <JsonEditor
                ref={jsonRef}
                value={values.ruleSetMeta}
                name="ruleSetMeta"
                mode="code"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChange={() => {}}
                onEditable={() => false}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-5">
          <Footer>
            <Button variant="primary" type="submit">
              {t("form.actions.save")}
            </Button>
            <Button
              variant="outline-primary"
              onClick={handleReset}
              className="ml-2"
            >
              {t("form.actions.reset")}
            </Button>
            <Button
              variant="danger"
              className="ml-4"
              type="button"
              disabled={initialValues.ruleSetName == null}
              onClick={removeRules}
            >
              Remove rules
            </Button>
          </Footer>
        </div>
      </Form>
    </div>
  );
}
