/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MetadataTypeDataTypeEnum = "boolean" | "date" | "number" | "object" | "string" | "timestamp" | "%future added value";
export type MetadataTypeObjectClassEnum = "Employment" | "%future added value";
export type MetadataTypeCreateInput = {
    dataType: MetadataTypeDataTypeEnum;
    description?: string | null | undefined;
    displayName?: string | null | undefined;
    encrypted?: boolean | null | undefined;
    external?: boolean | null | undefined;
    internalAccess?: boolean | null | undefined;
    isTimeboxed?: boolean | null | undefined;
    maximum?: number | null | undefined;
    minimum?: number | null | undefined;
    name: string;
    obfuscatedNumber?: number | null | undefined;
    objectClass: MetadataTypeObjectClassEnum;
    required?: boolean | null | undefined;
    validValues?: unknown | null | undefined;
};
export type MetadataTypesQueries_CreateBusinessMetadataType_MutationVariables = {
    businessId: string;
    input: MetadataTypeCreateInput;
};
export type MetadataTypesQueries_CreateBusinessMetadataType_MutationResponse = {
    readonly createMetadataType: {
        readonly id: string;
        readonly name: string;
        readonly displayName: string | null;
        readonly objectClass: MetadataTypeObjectClassEnum;
        readonly required: boolean;
        readonly description: string | null;
        readonly dataType: MetadataTypeDataTypeEnum;
        readonly minimum: number | null;
        readonly maximum: number | null;
        readonly validValues: unknown | null;
        readonly isTimeboxed: boolean;
        readonly external: boolean;
        readonly internalAccess: boolean;
        readonly encrypted: boolean;
        readonly obfuscatedNumber: number | null;
    };
};
export type MetadataTypesQueries_CreateBusinessMetadataType_Mutation = {
    readonly response: MetadataTypesQueries_CreateBusinessMetadataType_MutationResponse;
    readonly variables: MetadataTypesQueries_CreateBusinessMetadataType_MutationVariables;
};



/*
mutation MetadataTypesQueries_CreateBusinessMetadataType_Mutation(
  $businessId: ID!
  $input: MetadataTypeCreateInput!
) {
  createMetadataType(businessId: $businessId, input: $input) {
    id
    name
    displayName
    objectClass
    required
    description
    dataType
    minimum
    maximum
    validValues
    isTimeboxed
    external
    internalAccess
    encrypted
    obfuscatedNumber
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MetadataType",
    "kind": "LinkedField",
    "name": "createMetadataType",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectClass",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "required",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dataType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maximum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "validValues",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isTimeboxed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "external",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "internalAccess",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "encrypted",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "obfuscatedNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetadataTypesQueries_CreateBusinessMetadataType_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetadataTypesQueries_CreateBusinessMetadataType_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "529b0349f9195662900ad9436584095e",
    "id": null,
    "metadata": {},
    "name": "MetadataTypesQueries_CreateBusinessMetadataType_Mutation",
    "operationKind": "mutation",
    "text": "mutation MetadataTypesQueries_CreateBusinessMetadataType_Mutation(\n  $businessId: ID!\n  $input: MetadataTypeCreateInput!\n) {\n  createMetadataType(businessId: $businessId, input: $input) {\n    id\n    name\n    displayName\n    objectClass\n    required\n    description\n    dataType\n    minimum\n    maximum\n    validValues\n    isTimeboxed\n    external\n    internalAccess\n    encrypted\n    obfuscatedNumber\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f560fd0bf02b4c2f17143dff3f000107';
export default node;
