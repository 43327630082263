import React, { createContext } from "react";
import { fetchQuery, graphql } from "react-relay";
import { idmExternalEnvironment } from "../../../../environment";
import type {
  IStack,
  IUser,
  Id,
  StackCode,
} from "../../../../data/models/common";
import { AppContext_Query } from "./__generated__/AppContext_Query.graphql";

const query = graphql`
  query AppContext_Query {
    stacks {
      edges {
        node {
          id
          domainName
          deleted
          stackCode
        }
      }
    }
  }
`;

export type AppContextValue = {
  stackCode?: StackCode;
  user?: IUser;
  stack?: IStack;
  myStacksById?: Map<Id, IStack>;
  myStacksByCode?: Map<StackCode, IStack>;
  setStackId: (stackId?: Id) => void;
  loadMyStacks: () => void;
  stacks?: IStack[];
};

type Props = {
  user?: IUser;
  stacks: IStack[];
  stackCode: StackCode;
};

const AppContext = createContext<AppContextValue>({
  setStackId: () => undefined,
  loadMyStacks: () => undefined,
  myStacksById: new Map<Id, IStack>(),
  myStacksByCode: new Map<StackCode, IStack>(),
  stacks: [],
});

export default AppContext;
const useAppContext = () => React.useContext(AppContext);

class AppContextProvider extends React.Component<Props, AppContextValue> {
  public static redirectToStackIfNeeded(
    currentStackCode: string,
    newStackCode: string,
  ): void {
    if (currentStackCode === newStackCode) {
      return;
    }
    const urlOnOtherStack = window.location.href.replace(
      `/${currentStackCode}/`,
      `/${newStackCode}/`,
    );
    // switching to another state
    window.location.replace(urlOnOtherStack);
  }

  constructor(props: Props) {
    super(props);

    const { stacks, stackCode } = props;
    const stacksByCode = this.getStacksByCode(stacks);
    const stack: IStack = stacksByCode.get(stackCode) ?? stacks[0];
    this.state = {
      stackCode,
      user: props.user,
      setStackId: this.setStackId,
      loadMyStacks: this.loadMyStacks.bind(this),
      myStacksById: this.getStacksById(stacks),
      myStacksByCode: stacksByCode,
      stack,
    };
    // If they log in on a stack they don't have access to, redirect to their
    // first stack
    AppContextProvider.redirectToStackIfNeeded(stackCode, stack.stackCode);
  }

  getStacksById = (stacks: IStack[]): Map<Id, IStack> =>
    new Map<Id, IStack>(stacks.map((s) => [s?.id ?? "", s]));

  getStacksByCode = (stacks: IStack[]): Map<StackCode, IStack> =>
    new Map<StackCode, IStack>(stacks.map((s) => [s?.stackCode ?? "", s]));

  setStackId = (stackId?: Id) => {
    this.setState((prevState) => {
      // fallback to first stack if stack id is unknown
      const stack: IStack | undefined =
        stackId && prevState.myStacksById?.has(stackId)
          ? prevState.myStacksById?.get(stackId)
          : // otherwise default to stack declared in index.html
            prevState.myStacksByCode?.get(prevState.stackCode ?? "") ||
            // and fall back in case of deleted stack
            prevState.myStacksById?.values().next().value ||
            undefined;

      if (stack) {
        const currentStackCode = prevState.stackCode as string;
        const newStackCode = stack.stackCode;
        AppContextProvider.redirectToStackIfNeeded(
          currentStackCode,
          newStackCode,
        );
      }

      return {
        stack,
      };
    });
  };

  async loadMyStacks() {
    const result = await fetchQuery<AppContext_Query>(
      idmExternalEnvironment,
      query,
      {},
    ).toPromise();

    if (!result || !result.stacks || !result.stacks.edges) {
      return;
    }

    const stacks = result.stacks.edges.map((i) => (i as any).node);
    this.setState((prevState) => {
      const stacksById = this.getStacksById(stacks);
      const stacksByCode = this.getStacksByCode(stacks);
      const stack =
        prevState.stack ??
        prevState.myStacksByCode?.get(prevState.stackCode ?? "") ??
        stacks[0];

      AppContextProvider.redirectToStackIfNeeded(
        (prevState.stackCode ?? prevState.stack?.stackCode) as string,
        stack.stackCode,
      );

      return {
        myStacksById: stacksById,
        myStacksByCode: stacksByCode,
        stack,
      };
    });
  }

  render() {
    const { children } = this.props;
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          stacks: this.props.stacks,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

export { AppContextProvider, useAppContext };
