/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AvailabilityTypeEnum = "Availability_NoPermanentWorkSchedule" | "Availability_TemporaryAvailability" | "%future added value";
export type CurrencyEnum = "aud" | "cad" | "eur" | "gbp" | "nzd" | "usd" | "%future added value";
export type EmploymentRateEditPastEnum = "disabled" | "enabled" | "%future added value";
export type EmploymentTypeFixedShiftRestrictionOptionEnum = "not_applicable" | "optional" | "required" | "%future added value";
export type SwapDropFilteringEnum = "default" | "jtc" | "%future added value";
export type SwapSortingEnum = "default" | "skill_level" | "%future added value";
export type BusinessInput = {
    accentColor1?: string | null | undefined;
    aggregateToStartDay?: boolean | null | undefined;
    allowManagerToApproveOwnPunch?: boolean | null | undefined;
    allowOnlyBusinessOwnersInWebui?: boolean | null | undefined;
    analyticsEnabled?: boolean | null | undefined;
    analyticsServerUrl?: string | null | undefined;
    anonymousShiftDropToUser?: boolean | null | undefined;
    anonymousShiftSwapToUser?: boolean | null | undefined;
    approvalNeededForCommendation?: boolean | null | undefined;
    approvalNeededForDisciplinaryAction?: boolean | null | undefined;
    approvalNeededForRoleRateChange?: boolean | null | undefined;
    approvalRequests?: ApprovalRequestsConfigInput | null | undefined;
    assignEmployeeConfirmationText?: string | null | undefined;
    autoAcceptRosteredShifts?: boolean | null | undefined;
    autoRejectTimeFromUnavailabilityStart?: AutoRejectTimeInput | null | undefined;
    autoRejectUnavailabilityEvents?: boolean | null | undefined;
    availabilityChangeDuringPublishedScheduleEnabled?: boolean | null | undefined;
    availabilityConsentRequired?: boolean | null | undefined;
    availabilityCoverageHorizonDays?: number | null | undefined;
    availabilityEmployeeComments?: boolean | null | undefined;
    availabilityErrorOnAnyShiftConflict?: boolean | null | undefined;
    availabilityErrorOnMinNoticeDays?: number | null | undefined;
    availabilityErrorOnRosteredShiftConflict?: boolean | null | undefined;
    availabilityManagerComments?: boolean | null | undefined;
    availabilityMaxCycleWeeks?: number | null | undefined;
    availabilityTypesEnabled?: Array<AvailabilityTypeEnum> | null | undefined;
    availabilityUseConsecutiveDaysOffWaived?: boolean | null | undefined;
    availabilityUseMaxDurationPerWeek?: boolean | null | undefined;
    availabilityWarnOnMinNoticeDays?: number | null | undefined;
    biometricDisclaimer?: string | null | undefined;
    biometricExpirationExemptJtcCodes?: Array<string> | null | undefined;
    biometricsExpirationDays?: unknown | null | undefined;
    breakTypeManagementEnabled?: boolean | null | undefined;
    businessName?: string | null | undefined;
    businessTerms?: unknown | null | undefined;
    canAdjustForecast?: boolean | null | undefined;
    chatEnabled?: boolean | null | undefined;
    clockInThreshold?: number | null | undefined;
    clockOutThreshold?: number | null | undefined;
    clockTrackingEnabled?: boolean | null | undefined;
    coBrandingEnabled?: boolean | null | undefined;
    commendationEmploymentNotifications?: boolean | null | undefined;
    commendationEnabled?: boolean | null | undefined;
    contractsConsentEnabled?: boolean | null | undefined;
    contractsUseEmploymentType?: boolean | null | undefined;
    contractsUseMinDurationPerWeek?: boolean | null | undefined;
    crossScheduleComplianceChecksEnabled?: boolean | null | undefined;
    csvReportDisclaimerEnabled?: boolean | null | undefined;
    currencyType?: CurrencyEnum | null | undefined;
    darAnalyticsSyncThreshold?: number | null | undefined;
    darDataOnlyPastSlicesEnabled?: boolean | null | undefined;
    darEnabled?: boolean | null | undefined;
    darFields?: Array<BusinessDarFieldInput> | null | undefined;
    dataLakeGlobalId?: string | null | undefined;
    dataLakeId?: string | null | undefined;
    datapassAffectingMetadata?: Array<string> | null | undefined;
    datapassTrackingEnabled?: boolean | null | undefined;
    dateFormat?: string | null | undefined;
    defaultPayrollCutoffTime?: string | null | undefined;
    defaultScheduleDayEndTime?: string | null | undefined;
    defaultScheduleDayStartTime?: string | null | undefined;
    defaultScheduleSnapshotDuration?: number | null | undefined;
    defaultScheduleSnapshotStartDate?: string | null | undefined;
    deletePrimaryJobTitlesEnabled?: boolean | null | undefined;
    disableEmployeeLeaveRequest?: boolean | null | undefined;
    disciplinaryActionEmploymentNotifications?: boolean | null | undefined;
    disciplinaryActionEnabled?: boolean | null | undefined;
    displayWarningUnavailabilityEvents?: boolean | null | undefined;
    dropMobileDisclaimer?: string | null | undefined;
    dropMobileDisclaimerEnabled?: boolean | null | undefined;
    durationFormat?: string | null | undefined;
    dynamicFieldsLayout?: DynamicFieldsLayoutInput | null | undefined;
    earlyShiftStartThreshold?: number | null | undefined;
    earningsEstimator?: string | null | undefined;
    emplTypeGmhSettings?: EmplTypeGmhSettingsUpdateInput | null | undefined;
    emplTypeTimeOffComplianceEnabled?: EmplTypeTimeOffComplianceEnabledInput | null | undefined;
    employeeAvailabilityEditableByManager?: boolean | null | undefined;
    employeeDraftsEnabled?: boolean | null | undefined;
    employeeMultiRateEnabled?: boolean | null | undefined;
    employmentCodePreprocessor?: unknown | null | undefined;
    employmentExternalManagedFlags?: EmploymentExternalManagedFlagsUpdateInput | null | undefined;
    employmentPerformanceReviewDetail?: EmploymentPerformanceReviewDetailInput | null | undefined;
    employmentPerformanceReviewEnabled?: boolean | null | undefined;
    employmentRateEditEnabled?: boolean | null | undefined;
    employmentRateEditPast?: EmploymentRateEditPastEnum | null | undefined;
    employmentTypeFixedShiftRestriction?: EmploymentTypeFixedShiftRestrictionUpdateInput | null | undefined;
    enableMiscPay?: boolean | null | undefined;
    enablePastShiftOperation?: boolean | null | undefined;
    enableShiftDifferentials?: boolean | null | undefined;
    feedbackCommentEnabled?: boolean | null | undefined;
    feedbackReasonEnabled?: boolean | null | undefined;
    firstDayOfWeek?: number | null | undefined;
    firstNameCharCount?: number | null | undefined;
    firstNameSuffix?: string | null | undefined;
    fixedShiftsBreakDurationEnabled?: boolean | null | undefined;
    fixedShiftsEnabled?: boolean | null | undefined;
    fixedShiftsRoleId?: string | null | undefined;
    footerDisclaimer?: string | null | undefined;
    forecastRequiresApproval?: boolean | null | undefined;
    globalBusinessId?: string | null | undefined;
    globalImageId?: string | null | undefined;
    graphqlQueryBatchingEnabled?: boolean | null | undefined;
    highlightOverstaffing?: boolean | null | undefined;
    homeStoreTransferManagerCommentsEnabled?: boolean | null | undefined;
    idleTimeoutEnabled?: boolean | null | undefined;
    idleTimeoutLength?: number | null | undefined;
    image?: string | null | undefined;
    inviteFooter?: string | null | undefined;
    inviteHeader?: string | null | undefined;
    inviteMessage?: string | null | undefined;
    inviteUserConfirmationText?: string | null | undefined;
    isFake?: boolean | null | undefined;
    jobTitlesEnabled?: boolean | null | undefined;
    kpiAvgHourlyRateEnabled?: boolean | null | undefined;
    kpiSalesPerEmployeeHourEnabled?: boolean | null | undefined;
    lastNameCharCount?: number | null | undefined;
    lastNameSuffix?: string | null | undefined;
    liveTimeClockViewEnabled?: boolean | null | undefined;
    localeSuffix?: string | null | undefined;
    mailerLocales?: unknown | null | undefined;
    managerAppEnabled?: boolean | null | undefined;
    managerAssignUsersToSchedulesEnabled?: boolean | null | undefined;
    managerInviteUsersEnabled?: boolean | null | undefined;
    managerTerminateUsersEnabled?: boolean | null | undefined;
    mandatoryReasonsForEmploymentStatus?: MandatoryReasonsForEmploymentStatusInput | null | undefined;
    markAsAbsentEnabled?: boolean | null | undefined;
    markAsAbsentEnabledToUser?: boolean | null | undefined;
    maxShiftDuration?: number | null | undefined;
    meetingMaxPerYear?: number | null | undefined;
    meetingMinimumDuration?: unknown | null | undefined;
    meetingTitles?: Array<string> | null | undefined;
    meetingsEnabled?: boolean | null | undefined;
    militaryTime?: boolean | null | undefined;
    minSharingAge?: number | null | undefined;
    monthlyCostLoadingEnabled?: boolean | null | undefined;
    nicknameReplacesOnlyFirstName?: boolean | null | undefined;
    notificationsOnTimeClockAppEnabled?: boolean | null | undefined;
    payCycleStartDate?: string | null | undefined;
    payDates?: Array<number> | null | undefined;
    payDisclaimer?: string | null | undefined;
    payFrequency?: string | null | undefined;
    payPeriodDailyReportSplitNames?: boolean | null | undefined;
    payPeriodReviewEnabled?: boolean | null | undefined;
    payPeriodSelfApprovalEnabled?: boolean | null | undefined;
    populateScheduleReplanningEnabled?: boolean | null | undefined;
    punchAdjustmentConsentEnabled?: boolean | null | undefined;
    punchEditEmployeeCommentsEnabled?: boolean | null | undefined;
    punchEditForceChangesLegalDisclaimerEnabled?: boolean | null | undefined;
    punchEditLegalDisclaimer?: string | null | undefined;
    punchEditManagerCommentsEnabled?: boolean | null | undefined;
    punchSlipMode?: string | null | undefined;
    punchTrackingEnabled?: boolean | null | undefined;
    quarterlyAggregationEnabled?: boolean | null | undefined;
    rateType?: unknown | null | undefined;
    registrationNumber?: string | null | undefined;
    regularBusinessInviteEnabled?: boolean | null | undefined;
    reportColumnsConfigs?: Array<ReportColumnsConfigInput> | null | undefined;
    reportDisclaimerEnabled?: boolean | null | undefined;
    reportDisclaimerText?: string | null | undefined;
    reportSettings?: unknown | null | undefined;
    restScreenTheme?: string | null | undefined;
    roleJobTitles?: unknown | null | undefined;
    rostered?: boolean | null | undefined;
    roundingStrategy?: string | null | undefined;
    scheduleWarningsFilterEnabled?: boolean | null | undefined;
    schoolCalendarsEnabled?: boolean | null | undefined;
    shareRequiresApproval?: boolean | null | undefined;
    sharedStoreShiftsEnabled?: boolean | null | undefined;
    shiftAutoAcceptNotificationEnabled?: boolean | null | undefined;
    shiftCanOverlapLeaveRequest?: boolean | null | undefined;
    shiftCanOverlapUnavailability?: boolean | null | undefined;
    shiftCostBreakdownEnabled?: boolean | null | undefined;
    shiftDropEnabled?: boolean | null | undefined;
    shiftDropThreshold?: number | null | undefined;
    shiftMultiRateEnabled?: boolean | null | undefined;
    shiftSwapEnabled?: boolean | null | undefined;
    shiftSwapNeedsApproval?: boolean | null | undefined;
    shortDescription?: string | null | undefined;
    showAvatarIcon?: boolean | null | undefined;
    showBreaksToUser?: boolean | null | undefined;
    showColleaguesToUser?: boolean | null | undefined;
    showEarnings?: boolean | null | undefined;
    showEarningsToUser?: boolean | null | undefined;
    showNickname?: boolean | null | undefined;
    showShiftRolesToUser?: boolean | null | undefined;
    showSystemRolesAndPermissions?: boolean | null | undefined;
    ssoBusinessInviteEnabled?: boolean | null | undefined;
    storeHoursEnabled?: boolean | null | undefined;
    storeTransferOnHireDateEnabled?: boolean | null | undefined;
    swapDropFiltering?: SwapDropFilteringEnum | null | undefined;
    swapMobileDisclaimer?: string | null | undefined;
    swapMobileDisclaimerEnabled?: boolean | null | undefined;
    swapSorting?: SwapSortingEnum | null | undefined;
    targetsScreenEnabled?: boolean | null | undefined;
    terminateUserConfirmationText?: string | null | undefined;
    timeClockAppBusinessImageUri?: string | null | undefined;
    timeClockAppFetchScheduleJobTitles?: boolean | null | undefined;
    timeClockAppMinTimeBetweenBreaks?: unknown | null | undefined;
    timeClockAppPinConfig?: TimeClockAppPinConfigInput | null | undefined;
    timeClockAppSyncIntervalInSeconds?: number | null | undefined;
    timeClockAppWorkflowConfig?: unknown | null | undefined;
    timeClockRoleRateChangeAuthThreshold?: number | null | undefined;
    timeOffDuringPublishedScheduleEnabled?: boolean | null | undefined;
    timeOffEmployeeCommentsEnabled?: boolean | null | undefined;
    timeOffManagerCommentsEnabled?: boolean | null | undefined;
    timeOffRequestDuringBlackoutEnabled?: boolean | null | undefined;
    timeOffShiftNotificationsEnabled?: boolean | null | undefined;
    timeOffShiftsEnabled?: boolean | null | undefined;
    timekeepingDefaultToWeekView?: boolean | null | undefined;
    timekeepingPrepopulateNewEntry?: boolean | null | undefined;
    timekeepingReadonly?: boolean | null | undefined;
    timekeepingRolesVisible?: boolean | null | undefined;
    timeoutThreshold?: number | null | undefined;
    topLevelForecastMeasurements?: Array<AnalyticsTopLevelMeasurementInput> | null | undefined;
    unassignEmployeeConfirmationText?: string | null | undefined;
    unassignedShiftDefaultRateEnabled?: boolean | null | undefined;
    unshareOnTermination?: boolean | null | undefined;
    useLegacyActuals?: boolean | null | undefined;
    voluntaryLateClockOutEnabled?: boolean | null | undefined;
    warningCodeMap?: unknown | null | undefined;
    warningTimeFromUnavailabilityStart?: AutoWarnTimeInput | null | undefined;
    webuiViewOptions?: unknown | null | undefined;
};
export type ApprovalRequestsConfigInput = {
    employmentPerformanceReview?: EmploymentPerformanceReviewRequestConfigInput | null | undefined;
    employmentRateChange?: EmploymentRateChangeRequestConfigInput | null | undefined;
};
export type EmploymentPerformanceReviewRequestConfigInput = {
    autoApprovalEnabled?: boolean | null | undefined;
};
export type EmploymentRateChangeRequestConfigInput = {
    autoApprovalEnabled?: boolean | null | undefined;
};
export type AutoRejectTimeInput = {
    unit?: string | null | undefined;
    value?: number | null | undefined;
};
export type BusinessDarFieldInput = {
    description?: string | null | undefined;
    fieldCode: string;
    name: string;
    valueType: string;
};
export type DynamicFieldsLayoutInput = {
    groups: Array<DynamicFieldsLayoutGroupInput>;
    views: Array<DynamicFieldsLayoutViewInput>;
};
export type DynamicFieldsLayoutGroupInput = {
    fields: Array<DynamicFieldsLayoutGroupFieldInput>;
    label: string;
    name: string;
};
export type DynamicFieldsLayoutGroupFieldInput = {
    metadataTypeName: string;
    width: number;
};
export type DynamicFieldsLayoutViewInput = {
    groups: Array<string>;
    name: string;
};
export type EmplTypeGmhSettingsUpdateInput = {
    casual?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null | undefined;
    fullTime?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null | undefined;
    partTime?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null | undefined;
    permanent?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null | undefined;
};
export type EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput = {
    enabled?: boolean | null | undefined;
    minimumDurationError?: unknown | null | undefined;
    minimumDurationWarning?: unknown | null | undefined;
};
export type EmplTypeTimeOffComplianceEnabledInput = {
    casual: boolean;
    fullTime: boolean;
    partTime: boolean;
    permanent: boolean;
};
export type EmploymentExternalManagedFlagsUpdateInput = {
    availability?: boolean | null | undefined;
    contract?: boolean | null | undefined;
    hireHistory?: boolean | null | undefined;
    homeStore?: boolean | null | undefined;
    jtc?: boolean | null | undefined;
    nickname?: boolean | null | undefined;
    personalDetails?: boolean | null | undefined;
    roleAndRate?: boolean | null | undefined;
    school?: boolean | null | undefined;
    sharedStore?: boolean | null | undefined;
    skillLevel?: boolean | null | undefined;
    timeOff?: boolean | null | undefined;
};
export type EmploymentPerformanceReviewDetailInput = {
    adhocReviewEnabled?: boolean | null | undefined;
    adjustJtc?: boolean | null | undefined;
    autoPublish?: boolean | null | undefined;
    initialReviewEnabled?: boolean | null | undefined;
    initialReviewPeriodMonths?: unknown | null | undefined;
    regularReviewPeriodMonths?: unknown | null | undefined;
    regularReviewStartDate?: unknown | null | undefined;
    reviewDueReminderDays?: unknown | null | undefined;
};
export type EmploymentTypeFixedShiftRestrictionUpdateInput = {
    casual?: EmploymentTypeFixedShiftRestrictionOptionEnum | null | undefined;
    fullTime?: EmploymentTypeFixedShiftRestrictionOptionEnum | null | undefined;
    partTime?: EmploymentTypeFixedShiftRestrictionOptionEnum | null | undefined;
    permanent?: EmploymentTypeFixedShiftRestrictionOptionEnum | null | undefined;
};
export type MandatoryReasonsForEmploymentStatusInput = {
    active?: boolean | null | undefined;
    leaveOfAbsence?: boolean | null | undefined;
    suspended?: boolean | null | undefined;
    terminated?: boolean | null | undefined;
};
export type ReportColumnsConfigInput = {
    categories: Array<ReportCategoryInput>;
    reportName: string;
};
export type ReportCategoryInput = {
    columns: Array<ReportColumnInput>;
    name: string;
};
export type ReportColumnInput = {
    displayName: string;
    name: string;
};
export type TimeClockAppPinConfigInput = {
    max: number;
    min: number;
};
export type AnalyticsTopLevelMeasurementInput = {
    isCurrency: boolean;
    isPrimary: boolean;
    label: string;
    name: string;
};
export type AutoWarnTimeInput = {
    unit?: string | null | undefined;
    value?: number | null | undefined;
};
export type PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutationVariables = {
    input: BusinessInput;
    id: string;
};
export type PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutationResponse = {
    readonly updateBusiness: {
        readonly id: string;
        readonly employmentPerformanceReviewEnabled: boolean;
        readonly employmentPerformanceReviewDetail: {
            readonly autoPublish: boolean;
            readonly adjustJtc: boolean;
            readonly reviewDueReminderDays: unknown;
            readonly regularReviewStartDate: unknown;
            readonly regularReviewPeriodMonths: number;
            readonly initialReviewEnabled: boolean;
            readonly initialReviewPeriodMonths: number;
            readonly adhocReviewEnabled: boolean;
        };
        readonly approvalRequests: {
            readonly employmentPerformanceReview: {
                readonly autoApprovalEnabled: boolean;
            };
        };
        readonly feedbackReasonEnabled: boolean;
        readonly feedbackCommentEnabled: boolean;
        readonly commendationEnabled: boolean;
        readonly approvalNeededForCommendation: boolean;
        readonly commendationEmploymentNotifications: boolean;
        readonly disciplinaryActionEnabled: boolean;
        readonly approvalNeededForDisciplinaryAction: boolean;
        readonly disciplinaryActionEmploymentNotifications: boolean;
    };
};
export type PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation = {
    readonly response: PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutationResponse;
    readonly variables: PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutationVariables;
};



/*
mutation PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation(
  $input: BusinessInput!
  $id: ID!
) {
  updateBusiness(id: $id, input: $input) {
    id
    employmentPerformanceReviewEnabled
    employmentPerformanceReviewDetail {
      autoPublish
      adjustJtc
      reviewDueReminderDays
      regularReviewStartDate
      regularReviewPeriodMonths
      initialReviewEnabled
      initialReviewPeriodMonths
      adhocReviewEnabled
    }
    approvalRequests {
      employmentPerformanceReview {
        autoApprovalEnabled
      }
    }
    feedbackReasonEnabled
    feedbackCommentEnabled
    commendationEnabled
    approvalNeededForCommendation
    commendationEmploymentNotifications
    disciplinaryActionEnabled
    approvalNeededForDisciplinaryAction
    disciplinaryActionEmploymentNotifications
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Business",
    "kind": "LinkedField",
    "name": "updateBusiness",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentPerformanceReviewEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentPerformanceReviewDetail",
        "kind": "LinkedField",
        "name": "employmentPerformanceReviewDetail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "autoPublish",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adjustJtc",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reviewDueReminderDays",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regularReviewStartDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regularReviewPeriodMonths",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "initialReviewEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "initialReviewPeriodMonths",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adhocReviewEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApprovalRequestsConfig",
        "kind": "LinkedField",
        "name": "approvalRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmploymentPerformanceReviewRequestConfig",
            "kind": "LinkedField",
            "name": "employmentPerformanceReview",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "autoApprovalEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feedbackReasonEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "feedbackCommentEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commendationEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvalNeededForCommendation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commendationEmploymentNotifications",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disciplinaryActionEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "approvalNeededForDisciplinaryAction",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "disciplinaryActionEmploymentNotifications",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "da4958c20144a15e4f984a85183dc9a0",
    "id": null,
    "metadata": {},
    "name": "PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation PerformanceSettingsQueries_UpdateBusinessPerformanceSettingsMutation(\n  $input: BusinessInput!\n  $id: ID!\n) {\n  updateBusiness(id: $id, input: $input) {\n    id\n    employmentPerformanceReviewEnabled\n    employmentPerformanceReviewDetail {\n      autoPublish\n      adjustJtc\n      reviewDueReminderDays\n      regularReviewStartDate\n      regularReviewPeriodMonths\n      initialReviewEnabled\n      initialReviewPeriodMonths\n      adhocReviewEnabled\n    }\n    approvalRequests {\n      employmentPerformanceReview {\n        autoApprovalEnabled\n      }\n    }\n    feedbackReasonEnabled\n    feedbackCommentEnabled\n    commendationEnabled\n    approvalNeededForCommendation\n    commendationEmploymentNotifications\n    disciplinaryActionEnabled\n    approvalNeededForDisciplinaryAction\n    disciplinaryActionEmploymentNotifications\n  }\n}\n"
  }
};
})();
(node as any).hash = '2892a46580af37d56869ed47cf9e7493';
export default node;
