import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import without from "lodash/without";
import * as yup from "yup";
import { FormikContext, FormikContextType } from "formik";
import FormLayout from "../../../../../common/Form/FormLayout";
import Field from "../../../../../common/Form/Field";
import {
  presetMetadataGroupNames,
  StringFieldType,
} from "../../../../../common/Form/models";
import { MetadataUtility } from "../../../../Employment/EmploymentMetadata/MetadataUtility";
import SortableMetadataTable from "./SortableMetadataTable";
import {
  useDynamicFieldsLayout,
  useMetadataTypesForLayouts,
} from "../../MetadataLayoutQueries";
import { concatenateStrings } from "../../../../../../utils/utility";
import {
  DynamicFieldsLayoutGroup,
  DynamicFieldsLayout,
} from "../../../../../../data/generated/stack_internal_schema";
import { useModal } from "../../../../../../contexts/ModalContext";
import { ConfirmationModal } from "../../../../../common/ConfirmationModal";

type Props = {
  onClose: () => void;
  onOk: () => void;
  dynamicBusinessLayout: DynamicFieldsLayout;
  group?: DynamicFieldsLayoutGroup | null;
};

const StyledModal = styled(Modal)`
  .modal-title {
    font-size: 20px;
  }
  .modal-content,
  .modal-body {
    width: 742px;
  }
`;

export default function EditGroupsModal({
  onClose,
  onOk,
  group,
  dynamicBusinessLayout,
}: Props) {
  const theme = useTheme();
  const { showModal } = useModal();
  const { t } = useTranslation("metadata-types");
  const [
    { groups },
    {
      addGroup,
      removeGroup,
      isMetadataTypeVisibleInGroup,
      validateUpdateGroupName,
    },
  ] = useDynamicFieldsLayout();
  const isCreate = group == null;

  const { getMetadataTypesFromFieldLayouts } = useMetadataTypesForLayouts()[1];

  const validationRules = yup.object({
    name: yup
      .string()
      .required()
      .label(t("metadataLayout.groups.editModal.fields.group.label"))
      .test(
        "duplicate_group_name",
        t("metadataLayout.groups.editModal.errors.duplicateGroupName"),
        (name: string) => {
          return validateUpdateGroupName(group?.name, name);
        },
      ),
    label: yup
      .string()
      .required()
      .label(t("metadataLayout.groups.editModal.fields.displayName.label")),
    fields: yup.array().of(
      yup.object().shape({
        metadataTypeName: yup
          .string()
          .min(1)
          .required()
          .label(
            t("metadataLayout.groups.editModal.fields.metadataName.label"),
          ),
        width: yup
          .number()
          .min(1)
          .max(12)
          .required()
          .label(
            t("metadataLayout.groups.editModal.fields.metadataWidth.label"),
          ),
      }),
    ),
  });

  const viewsUsingGroup = MetadataUtility.getViewsForGroup(
    group,
    dynamicBusinessLayout.views,
  ).map((view) => view.name);

  const onRemove = () => {
    if (group) {
      showModal(
        <ConfirmationModal
          onClose={onClose}
          title={t("metadataLayout.groups.removeModal.title", {
            groupName: group.label,
          })}
          okClicked={() => {
            removeGroup(
              group,
              () => {
                onClose();
              },
              () => {
                onClose();
              },
            );
          }}
        >
          {t("metadataLayout.groups.removeModal.body")}
          <br />
          {viewsUsingGroup.length > 0 && (
            <>
              <br />
              {t("metadataLayout.groups.removeModal.affectedViews", {
                viewNames: viewsUsingGroup.join(", "),
              })}
            </>
          )}
        </ConfirmationModal>,
      );
    }
  };

  const onSave = (form: Partial<DynamicFieldsLayoutGroup>) => {
    addGroup(
      group,
      {
        ...(group ?? {}),
        ...form,
      } as DynamicFieldsLayoutGroup,
      () => {
        onClose();
      },
      () => {
        onClose();
      },
    );
  };

  return (
    <StyledModal backdrop="static" centered onHide={onClose} show>
      <Modal.Header closeButton>
        <Modal.Title>
          {isCreate
            ? t("metadataLayout.groups.editModal.title")
            : t("metadataLayout.groups.editModal.title_edit")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormLayout<DynamicFieldsLayoutGroup>
          onSave={onSave}
          isCreate={isCreate}
          propertyList={[]}
          validationRules={validationRules}
          base={
            group ??
            ({
              fields: [
                // Blank row displayed by default
                {
                  metadataTypeName: "",
                  width: 4,
                },
              ],
            } as DynamicFieldsLayoutGroup)
          }
          formId="edit-group-form"
          hidePrompt
        >
          <FormikContext.Consumer>
            {({ values }: FormikContextType<DynamicFieldsLayoutGroup>) => {
              const { fields: updatedFields } = values;

              const removedFields = (group?.fields ?? []).filter((field) => {
                return (
                  updatedFields.find(
                    (updatedField) =>
                      updatedField.metadataTypeName === field.metadataTypeName,
                  ) == null
                );
              });

              // get a list of fields to display required warnings
              const otherGroups = group ? without(groups, group) : groups;
              const warningFields = getMetadataTypesFromFieldLayouts(
                removedFields,
              ).filter((i) => {
                return (
                  i &&
                  MetadataUtility.metadataTypeShouldBeVisible(i) &&
                  !isMetadataTypeVisibleInGroup(i, otherGroups)
                );
              });

              return (
                <>
                  {warningFields.length > 0 && (
                    <Alert variant="warning">
                      {t("metadataLayout.warnings.requiredFields", {
                        fields: warningFields.map((x) => x.name).join(", "),
                      })}
                    </Alert>
                  )}
                  <Row>
                    <Field
                      xs={4}
                      md={4}
                      lg={4}
                      label={t(
                        "metadataLayout.groups.editModal.fields.group.label",
                      )}
                      dataListOptions={presetMetadataGroupNames}
                      fieldKey="name"
                      schemaFieldType={StringFieldType}
                    />
                    <Field
                      xs={4}
                      md={4}
                      lg={4}
                      label={t(
                        "metadataLayout.groups.editModal.fields.displayName.label",
                      )}
                      fieldKey="label"
                      schemaFieldType={StringFieldType}
                    />
                    {!isCreate && viewsUsingGroup.length > 0 && (
                      <Col className="d-flex" xs={4} md={4} lg={4}>
                        <FontAwesomeIcon
                          className="mt-1 mr-2"
                          icon={faInfoCircle}
                          color={theme.grey500}
                          size="xs"
                        />
                        <p>
                          {t("metadataLayout.groups.editModal.infoMessage", {
                            viewNames: concatenateStrings(viewsUsingGroup),
                          })}
                        </p>
                      </Col>
                    )}
                  </Row>
                  <SortableMetadataTable />
                </>
              );
            }}
          </FormikContext.Consumer>
        </FormLayout>
      </Modal.Body>
      <Modal.Footer
        className={isCreate ? "justify-content-end" : "justify-content-between"}
      >
        {!isCreate && (
          <Button variant="link" onClick={onRemove}>
            {t("translation:form.actions.remove")}
          </Button>
        )}
        <div>
          <Button variant="link" className="mr-4" onClick={onClose}>
            {t("translation:form.actions.cancel")}
          </Button>
          <Button variant="primary" type="submit" form="edit-group-form">
            {t("translation:form.actions.save")}
          </Button>
        </div>
      </Modal.Footer>
    </StyledModal>
  );
}
