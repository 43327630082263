import React, { PureComponent } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { SelectComponentsProps } from "react-select/src/Select";
import { BaseOption } from "../../../data/models/common";
import defaultTheme from "../../../styles/theme";
import DropdownIndicator from "./DropdownIndicator";

type Props<T> = SelectComponentsProps & {
  options: BaseOption<T, any>[];
  selectableOptions?: BaseOption<T, any>[];
  value: T;
  name: string;
  onChange: (newValue: T | null | undefined) => void;
  disabled?: boolean;
  allowCreate?: boolean;
};

type State<T> = {
  options: BaseOption<T, any>[];
};

const customStyles = {
  control: (base: any, props: SelectComponentsProps) => {
    return {
      ...base,
      height: 35,
      minHeight: 35,
      minWidth: props.selectProps?.selectProps?.minWidth ?? 160,
      maxWidth: props.selectProps?.selectProps?.maxWidth ?? undefined,
      ...(props?.selectProps?.className?.includes("invalid")
        ? { borderColor: defaultTheme.error }
        : {}),
    };
  },
  menu: (base: any, props: SelectComponentsProps) => {
    return {
      ...base,
      minWidth: props.selectProps?.selectProps?.minWidth ?? 160,
      maxWidth: props.selectProps?.selectProps?.maxWidth ?? undefined,
    };
  },
};

export default class DynamicSelect<T> extends PureComponent<
  Props<T>,
  State<T>
> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      options: [],
    };
  }

  getOptionFromValue = (value: T) => {
    return this.state.options.find((i) => i.value === value);
  };

  static getDerivedStateFromProps(props: Readonly<Props<any>>) {
    // Any time base changes, update state.
    const { value, allowCreate, options } = props;

    if (allowCreate) {
      if (value != null) {
        const found = options.find((i) => i.value === value);
        if (!found) {
          const newOption = {
            value,
            label: `${value}`,
          };

          return {
            options: [...options, newOption],
          };
        }
      }
    }

    return {
      options: [...options],
    };
  }

  render() {
    const {
      value,
      name,
      onChange,
      disabled,
      allowCreate,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      options: propOptions,
      selectableOptions,
      defaultValue,
      ...rest
    } = this.props;

    const { options } = this.state;

    // fallback to default value
    const selectedValue = this.getOptionFromValue(value) ?? defaultValue;

    if (allowCreate) {
      return (
        <CreatableSelect
          {...rest}
          defaultValue={defaultValue}
          isClearable
          onChange={(val) => {
            onChange(val == null ? null : (val as BaseOption<T, any>).value);
          }}
          onCreateOption={(val) => {
            onChange(val == null ? null : (val as any));
          }}
          options={selectableOptions ?? options}
          value={selectedValue}
          name={name}
          isDisabled={disabled}
          styles={customStyles}
          isSearchable
          classNamePrefix="react-select"
          components={{ DropdownIndicator, IndicatorSeparator: null }}
        />
      );
    }

    // overwrite onChange event
    return (
      <Select
        {...rest}
        options={selectableOptions ?? options}
        onChange={(val) => {
          onChange(val == null ? null : (val as BaseOption<T, any>).value);
        }}
        value={selectedValue}
        name={name}
        isDisabled={disabled}
        styles={customStyles}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
      />
    );
  }
}
