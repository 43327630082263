import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery, useMutation } from "react-relay";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import * as yup from "yup";
import { FormikHelpers } from "formik";
import get from "lodash/get";
import omit from "lodash/omit";
import {
  PeopleSettingsQueries_GetBusinessPeopleSettings_Query,
  PeopleSettingsQueries_GetBusinessPeopleSettings_QueryResponse,
} from "./__generated__/PeopleSettingsQueries_GetBusinessPeopleSettings_Query.graphql";
import {
  GetBusinessMetadataTypesQuery,
  GetBusinessPeopleSettingsQuery,
  GetBusinessRolesQuery,
  UpdateBusinessPeopleSettingsMutation,
} from "./PeopleSettingsQueries";
import FormLayout from "../../../common/Form/FormLayout";
import {
  employmentFixedShiftTypeOptions,
  IProperty,
  timeUnitOptions,
} from "../../../common/Form/models";
import DynamicInputGroup from "../../../common/Form/DynamicInputGroup";
import { getSettingsByGroup } from "../../../common/Form/formUtilities";
import properties from "../../../../data/csv-settings/people-settings.json";
import FormLayoutFooter from "../../../common/Form/FormLayoutFooter";
import { EmploymentExternalManagedFlagsUpdateInput } from "../../mutations/__generated__/UpdateBusinessMutation.graphql";
import { useModal } from "../../../../contexts/ModalContext";
import { ConfirmationModal } from "../../../common/ConfirmationModal";
import SelectionGroup from "../../../common/Form/SelectionGroup";
import { PeopleSettingsQueries_GetBusinessRoles_Query } from "./__generated__/PeopleSettingsQueries_GetBusinessRoles_Query.graphql";
import DynamicSelect from "../../../common/Form/DynamicSelect";
import Switch from "../../../common/Form/Switch";
import MultiSelectList, {
  MultiSelectType,
} from "../../../common/Form/MultiSelectList";
import { availabilityTypeOptions } from "../../../../data/models/common";
import BusinessEmploymentTerminationReason from "../BusinessEmploymentTerminationReason";
import { useTimeOffShiftComponentRules } from "../../../../hooks/useTimeOffShiftFieldComponentRules";
import { PeopleSettingsQueries_GetBusinessMetadataTypes_Query } from "./__generated__/PeopleSettingsQueries_GetBusinessMetadataTypes_Query.graphql";

type PeopleSettingsInputType =
  PeopleSettingsQueries_GetBusinessPeopleSettings_QueryResponse["businesses"]["nodes"][number];

type Props = {
  businessId: string;
};

export default function PeopleSettingsProfileForm({ businessId }: Props) {
  const { t } = useTranslation("people-settings");
  const { showModal, hideModal } = useModal();
  const [peopleSettings, savePeopleSettings] =
    usePeopleSettingsData(businessId);
  const employmentTypeFixedShiftRestrictionComponentRules =
    useEmploymentTypeFixedShiftRestrictionSettings(businessId);

  const emplTypeTimeOffComplianceEnabledComponentRules =
    useTimeOffShiftComponentRules();

  const [emplTypeGmhComponentRules] = useEmploymentTypeGMHSettings();

  const filteredProperties = properties;

  const dataPassComponentRules = useDatapassComponentRules(
    businessId,
    peopleSettings,
  );

  const validationRules = yup.object({
    warningTimeFromUnavailabilityStart: yup
      .object()
      .when("displayWarningUnavailabilityEvents", {
        is: true,
        then: yup.object({
          value: yup
            .number()
            .min(1)
            .label(
              t(
                "translation:property.displayWarningUnavailabilityEvents.value",
              ),
            ),
        }),
      }),
    autoRejectTimeFromUnavailabilityStart: yup
      .object()
      .when("autoRejectUnavailabilityEvents", {
        is: true,
        then: yup.object({
          value: yup
            .number()
            .min(1)
            .label(
              t(
                "translation:property.autoRejectTimeFromUnavailabilityStart.value",
              ),
            ),
        }),
      }),
  });

  const onSaveClick = (
    changes: Partial<PeopleSettingsInputType>,
    errorHandler: (err: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: PeopleSettingsInputType,
    helpers?: FormikHelpers<PeopleSettingsInputType>,
  ) => {
    const displayWarning = get(changes, "displayWarningUnavailabilityEvents");
    const autoReject = get(changes, "autoRejectUnavailabilityEvents");
    if (
      (displayWarning == null &&
        peopleSettings?.displayWarningUnavailabilityEvents === false) ||
      displayWarning === false
    ) {
      changes = omit(changes, "warningTimeFromUnavailabilityStart");
    }

    changes = omit(changes, "timeClockAppBusinessImageId");

    if (
      (autoReject == null &&
        peopleSettings?.autoRejectUnavailabilityEvents === false) ||
      autoReject === false
    ) {
      changes = omit(changes, "autoRejectTimeFromUnavailabilityStart");
    }

    const saveCallback = (dismissModal: boolean) => {
      savePeopleSettings({
        variables: {
          id: businessId,
          input: changes,
        },
        onCompleted() {
          if (dismissModal) {
            hideModal();
          }
          toast(t("form.toast.save"));
        },
        onError(error: Error) {
          hideModal();
          errorHandler(error);
        },
      });
    };

    // If an externally managed flag has been changed from false to true, then warn the user that
    // pending requests will be cancelled.
    if (
      shouldShowExternallyManagedWarning(
        peopleSettings?.employmentExternalManagedFlags,
        changes?.employmentExternalManagedFlags,
      )
    ) {
      showModal(
        <ConfirmationModal
          onClose={() => {
            hideModal();
            helpers?.setSubmitting(false);
          }}
          okClicked={() => saveCallback(true)}
          title={t("form.warningModal.title")}
          okText={t("form.warningModal.actions.ok")}
        >
          {t("form.warningModal.body")}
        </ConfirmationModal>,
      );
    } else {
      saveCallback(false);
    }
  };

  const componentRules = {
    employmentExternalManagedFlags: {
      hideLabel: true,
      hideDescription: true,
    },
    fixedShiftsEnabled: {
      xs: 12,
      md: 12,
      lg: 12,
      component: Switch,
      label: undefined,
      hideLabel: true,
      componentProps: {
        boldLabels: true,
        onLabel: t("translation:switch.enable"),
        offLabel: t("translation:switch.disable"),
      },
    },
    displayWarningUnavailabilityEvents: {
      xs: 12,
      md: 12,
      lg: 12,
    },
    warningTimeFromUnavailabilityStart: {
      hideLabel: true,
    },
    "warningTimeFromUnavailabilityStart.unit": {
      component: DynamicSelect,
      componentProps: {
        options: timeUnitOptions,
        defaultValue: null,
      },
      disabled: (values: PeopleSettingsInputType) =>
        !values?.displayWarningUnavailabilityEvents,
      hideLabel: false,
    },
    "warningTimeFromUnavailabilityStart.value": {
      disabled: (values: PeopleSettingsInputType) =>
        !values?.displayWarningUnavailabilityEvents,
      hideLabel: false,
    },
    autoRejectUnavailabilityEvents: {
      md: 12,
      xs: 12,
      lg: 12,
    },
    autoRejectTimeFromUnavailabilityStart: {
      hideLabel: true,
      md: 8,
      xs: 8,
      lg: 8,
    },
    "autoRejectTimeFromUnavailabilityStart.unit": {
      component: DynamicSelect,
      componentProps: {
        options: timeUnitOptions,
        defaultValue: null,
      },
      disabled: (values: PeopleSettingsInputType) =>
        !values?.autoRejectUnavailabilityEvents,
      hideLabel: false,
    },
    "autoRejectTimeFromUnavailabilityStart.value": {
      disabled: (values: PeopleSettingsInputType) =>
        !values?.autoRejectUnavailabilityEvents,
      hideLabel: false,
    },
    timeOffRequestDuringBlackoutEnabled: {
      component: Switch,
      label: undefined,
      lg: 12,
      md: 12,
      xs: 12,
      componentProps: {
        boldLabels: true,
        onLabel: t("translation:switch.enable"),
        offLabel: t("translation:switch.disable"),
      },
    },
    timeOffDuringPublishedScheduleEnabled: {
      component: Switch,
      label: undefined,
      lg: 12,
      md: 12,
      xs: 12,
      componentProps: {
        boldLabels: true,
        onLabel: t("translation:switch.enable"),
        offLabel: t("translation:switch.disable"),
      },
    },
    availabilityChangeDuringPublishedScheduleEnabled: {
      component: Switch,
      label: undefined,
      lg: 12,
      md: 12,
      xs: 12,
      componentProps: {
        boldLabels: true,
        onLabel: t("translation:switch.enable"),
        offLabel: t("translation:switch.disable"),
      },
    },
    availabilityTypesEnabled: {
      component: MultiSelectList,
      componentProps: {
        selectableOptions: availabilityTypeOptions,
        defaultValue: null,
      },
    },
    ...employmentTypeFixedShiftRestrictionComponentRules,
    // Ignore this, each setting of mandatoryReasonsForEmploymentStatus is handled separately
    mandatoryReasonsForEmploymentStatus: {
      hideLabel: true,
      hideDescription: true,
    },
    "mandatoryReasonsForEmploymentStatus.terminated": {
      hideLabel: true,
      hideDescription: true,
      lg: 12,
      md: 12,
      xs: 12,
      component: (props: any) => (
        <Suspense fallback="">
          <BusinessEmploymentTerminationReason {...props} />
        </Suspense>
      ),
    },
    ...emplTypeTimeOffComplianceEnabledComponentRules,
    ...emplTypeGmhComponentRules,
    ...dataPassComponentRules,
  };

  return (
    <FormLayout<PeopleSettingsInputType>
      isCreate={false}
      base={peopleSettings}
      onSave={onSaveClick}
      propertyList={[]}
      validationRules={validationRules}
      componentRules={componentRules}
    >
      <Card body className="mb-5">
        <DynamicInputGroup
          fields={getSettingsByGroup(filteredProperties as IProperty[])}
        />
      </Card>
      <FormLayoutFooter />
    </FormLayout>
  );
}

function usePeopleSettingsData(businessId: string) {
  const [savePeopleSettingsMutation] = useMutation(
    UpdateBusinessPeopleSettingsMutation,
  );

  const { businesses } =
    useLazyLoadQuery<PeopleSettingsQueries_GetBusinessPeopleSettings_Query>(
      GetBusinessPeopleSettingsQuery,
      {
        ids: [businessId],
      },
      {
        fetchPolicy: "network-only",
      },
    );

  const data = businesses.nodes[0];

  return [data, savePeopleSettingsMutation] as const;
}

function useBusinessRoles(businessId: string) {
  const { businessRoles } = useLazyLoadQuery<
    Exclude<PeopleSettingsQueries_GetBusinessRoles_Query, null>
  >(
    GetBusinessRolesQuery,
    {
      businessId,
    },
    {
      fetchPolicy: "network-only",
    },
  );

  return businessRoles.nodes as ReadonlyArray<{
    readonly id: string;
    readonly businessRoleName: string;
    readonly replaceByAos: boolean;
  }>;
}

function shouldShowExternallyManagedWarning(
  initialExternallyManagedFlags?: EmploymentExternalManagedFlagsUpdateInput,
  externallyManagedFlags?: EmploymentExternalManagedFlagsUpdateInput,
) {
  if (externallyManagedFlags && initialExternallyManagedFlags) {
    // Check the initial flags and determine if one of them was previously false, but has been set to true
    return Object.keys(externallyManagedFlags).some((key) => {
      const flagKey = key as keyof EmploymentExternalManagedFlagsUpdateInput;
      return (
        !initialExternallyManagedFlags[flagKey] &&
        externallyManagedFlags[flagKey]
      );
    });
  }

  return false;
}

function useEmploymentTypeGMHSettings() {
  const { t } = useTranslation("people-settings");
  const componentRules = {
    contractsUseMinDurationPerWeek: {
      component: Switch,
      hideLabel: true,
      label: undefined,
      componentProps: {
        boldLabels: false,
        onLabel: t("translation:switch.enable"),
        offLabel: t("translation:switch.disable"),
      },
    },
  };
  return [componentRules];
}

function useEmploymentTypeFixedShiftRestrictionSettings(businessId: string) {
  const { t } = useTranslation("people-settings");
  const businessRoles = useBusinessRoles(businessId);

  const selectionGroupRules = {
    component: SelectionGroup,
    componentProps: {
      options: employmentFixedShiftTypeOptions,
      formCheckType: "radio",
      disabledTooltipText: t("form.fixedShiftsEmploymentTypesDisabled"),
    },
    disabled: (values: PeopleSettingsInputType) =>
      !values?.contractsUseEmploymentType,
  };

  return {
    fixedShiftsBreakDurationEnabled: {
      xs: 8,
      md: 8,
      lg: 8,
    },
    fixedShiftsRoleId: {
      component: DynamicSelect,
      boldLabel: true,
      componentProps: {
        options: businessRoles.map((businessRole) => ({
          label: businessRole.businessRoleName,
          value: businessRole.id,
        })),
        isClearable: true,
        defaultValue: null,
      },
    },
    employmentTypeFixedShiftRestriction: {
      component: null,
      hideError: true,
      hideDescription: true,
      boldLabel: true,
      hideGroupName: true,
      label: "Employment Type Configurations",
    },
    "employmentTypeFixedShiftRestriction.partTime": selectionGroupRules,
    "employmentTypeFixedShiftRestriction.fullTime": selectionGroupRules,
    "employmentTypeFixedShiftRestriction.casual": selectionGroupRules,
    "employmentTypeFixedShiftRestriction.permanent": selectionGroupRules,
  };
}

function useDatapassComponentRules(
  businessId: string,
  initialData: PeopleSettingsInputType,
) {
  const { t } = useTranslation("people-settings");

  const { metadataTypes } = useLazyLoadQuery<
    Exclude<PeopleSettingsQueries_GetBusinessMetadataTypes_Query, null>
  >(
    GetBusinessMetadataTypesQuery,
    {
      businessId,
    },
    {
      fetchPolicy: "network-only",
    },
  );

  const metadataPillOptions = metadataTypes.nodes.map((x) => ({
    label: x.displayName ?? x.name,
    value: x.name,
  }));

  // There's a chance the user has deleted a metadata type. We'll still display it in the list of currently selected
  // options to assist client support
  const allMetadataPillOptions = initialData.datapassAffectingMetadata.reduce(
    (acc: { label: string; value: string }[], curr: string) => {
      const isRemovedOption = acc.find((x) => x.value === curr) == null;
      if (curr && isRemovedOption) {
        acc.push({
          label: t("form.removedMetadataTypeLabel", {
            metadataTypeName: curr,
          }),
          value: curr,
        });
      }
      return acc;
    },
    metadataPillOptions,
  );

  return {
    datapassTrackingEnabled: {
      component: Switch,
      label: undefined,
      hideLabel: true,
      componentProps: {
        boldLabels: true,
        onLabel: t("translation:switch.enable"),
        offLabel: t("translation:switch.disable"),
      },
    },
    datapassAffectingMetadata: {
      component: MultiSelectList,
      disabled: (values: PeopleSettingsInputType) =>
        !values?.datapassTrackingEnabled,
      componentProps: {
        allOptions: allMetadataPillOptions,
        selectableOptions: metadataPillOptions,
        menuPlacement: "top",
        multiStyle: MultiSelectType.Pill,
        minSelectHeight: 90,
        hideDropdownIndicator: true,
      },
    },
  };
}
