import * as yup from "yup";
import i18next from "i18next";
import {
  DynamicFieldsLayoutViewInput,
  DynamicFieldsLayoutGroup,
  DynamicFieldsLayout,
  DynamicFieldsLayoutView,
  EmploymentMetadata,
  MetadataType,
  MetadataTypeDataTypeEnum,
} from "../../../../data/generated/stack_internal_schema";
import {
  BooleanFieldType,
  IntegerFieldType,
  JsonFieldType,
  StringFieldType,
} from "../../../common/Form/models";
import DynamicSelect from "../../../common/Form/DynamicSelect";
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import DateTimePicker from "../../../common/Form/DateTimePicker";
import { PillboxVariant, PillboxVariantEnum } from "../../../common/Pillbox";

export class MetadataUtility {
  static getYupSchemaForMetadataType(metadataType: MetadataType) {
    switch (metadataType.dataType) {
      case MetadataTypeDataTypeEnum.String:
        return yup.string().trim().nullable();
      case MetadataTypeDataTypeEnum.Number:
        return yup.number().nullable();
      case MetadataTypeDataTypeEnum.Date:
        return yup
          .date()
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr));
      case MetadataTypeDataTypeEnum.Boolean:
        return yup.boolean().default(false);
      case MetadataTypeDataTypeEnum.Timestamp:
        return yup
          .date()
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr));
      default:
      case MetadataTypeDataTypeEnum.Object:
        return yup
          .mixed()
          .isValidJson(metadataType.displayName as string)
          .default({});
    }
  }

  static isStringOrNumber(metadataType: MetadataType | undefined) {
    switch (metadataType?.dataType) {
      case MetadataTypeDataTypeEnum.String:
      case MetadataTypeDataTypeEnum.Number:
        return true;
      default:
        return false;
    }
  }

  static applyYupRulesToMetadataType(
    metadataType: MetadataType,
    forceRequired = false,
  ) {
    let yupField = MetadataUtility.getYupSchemaForMetadataType(metadataType);

    const isRequired = metadataType.required || forceRequired;

    if (yupField) {
      if (MetadataUtility.isSelectMetadataType(metadataType)) {
        const validValues = [
          ...MetadataUtility.getSafeValidValues(metadataType.validValues),
        ];

        // If the metadata type isn't required, add null to oneOf, since nullable doesn't work here for some reason
        if (!isRequired) {
          validValues.push(null);
        }
        yupField = yupField.oneOf(validValues);
      }
      if (
        MetadataUtility.isStringOrNumber(metadataType) &&
        metadataType.maximum != null
      ) {
        yupField = (
          yupField as yup.StringSchema<string> | yup.NumberSchema<number>
        ).max(metadataType.maximum);
      }
      if (
        MetadataUtility.isStringOrNumber(metadataType) &&
        metadataType.minimum != null
      ) {
        yupField = (
          yupField as yup.StringSchema<string> | yup.NumberSchema<number>
        ).min(metadataType.minimum);
      }
      if (isRequired) {
        yupField = yupField.required();
      }
      if (metadataType.displayName && metadataType.displayName.length > 1) {
        yupField = yupField.label(metadataType.displayName);
      }
    }

    return yupField;
  }

  static getFieldPropsForMetadataType(
    metadataType: MetadataType,
  ): MetadataFieldWithProps {
    const createFieldWithProps = (fieldProps: Record<string, unknown>) => {
      return {
        metadataType,
        field: {
          fieldKey: metadataType.name,
          md: 6,
          lg: 3,
          label: MetadataUtility.getDisplayName(metadataType),
          description: metadataType.description as string | undefined,
          tooltipText: metadataType.internalAccess
            ? `${metadataType.name} (Internal Only)`
            : metadataType.name,
          ...fieldProps,
        },
      };
    };

    const createSelectField = () => {
      return createFieldWithProps({
        component: DynamicSelect,
        description: metadataType.description as string | undefined,
        componentProps: {
          isClearable: !metadataType.required,
          allowCreate: true,
          options: MetadataUtility.getSafeValidValues(
            metadataType.validValues,
          ).map((validValue: string | number) => {
            return {
              label: validValue,
              value: validValue,
            };
          }),
        },
      });
    };

    // Only strings or numbers can be select fields
    if (MetadataUtility.isSelectMetadataType(metadataType)) {
      return createSelectField();
    }

    switch (metadataType.dataType) {
      case MetadataTypeDataTypeEnum.Number:
        return createFieldWithProps({
          schemaFieldType: IntegerFieldType,
        });
      case MetadataTypeDataTypeEnum.Boolean:
        return createFieldWithProps({
          schemaFieldType: BooleanFieldType,
        });
      case MetadataTypeDataTypeEnum.Date:
        return createFieldWithProps({
          schemaFieldType: StringFieldType,
          component: DateTimePicker,
          componentProps: {
            displayFormat: "dd/MM/yyyy",
            size: "large",
          },
        });
      case MetadataTypeDataTypeEnum.Object:
        return createFieldWithProps({
          schemaFieldType: JsonFieldType,
          md: 6,
          lg: 6,
        });
      case MetadataTypeDataTypeEnum.Timestamp:
        return createFieldWithProps({
          schemaFieldType: StringFieldType,
          component: DateTimePicker,
          componentProps: {
            showTimeSelect: true,
            displayFormat: "dd/MM/yyyy hh:mm a",
            size: "large",
          },
        });
      case MetadataTypeDataTypeEnum.String:
      default:
        return createFieldWithProps({
          schemaFieldType: StringFieldType,
        });
    }
  }

  static isSelectMetadataType(metadataType: MetadataType) {
    if (MetadataUtility.isStringOrNumber(metadataType)) {
      return metadataType.validValues?.length > 0;
    }
    return false;
  }

  static getBadgeStatusForEmploymentMetadata(
    employmentMeta?: Pick<EmploymentMetadata, "startTime" | "endTime">,
  ): { text: string; variant: PillboxVariant } {
    const { startTime, endTime } = employmentMeta ?? {};

    const now = Date.now();

    const start = new Date(startTime).valueOf();
    const end = new Date(endTime).valueOf();

    if (now >= start && (!endTime || now <= end)) {
      return {
        text: i18next.t("translation:pillbox.active"),
        variant: PillboxVariantEnum.Active,
      };
    }
    if (start > now) {
      return {
        text: i18next.t("translation:pillbox.upcoming"),
        variant: PillboxVariantEnum.Upcoming,
      };
    }

    return {
      text: i18next.t("translation:pillbox.completed"),
      variant: PillboxVariantEnum.Completed,
    };
  }

  static getDisplayName(metadataType: MetadataType) {
    return metadataType.displayName ?? metadataType.name;
  }

  static getSafeValidValues(validValues: any[] | null) {
    return validValues != null && Array.isArray(validValues) ? validValues : [];
  }

  static getViewsForGroup(
    group: DynamicFieldsLayoutGroup | undefined | null,
    views: DynamicFieldsLayoutView[],
  ) {
    if (!group) {
      return [];
    }
    return views.reduce(
      (acc: DynamicFieldsLayoutView[], view: DynamicFieldsLayoutView) => {
        if (view.groups.includes(group.name)) {
          acc.push(view);
        }
        return acc;
      },
      [],
    );
  }

  static getMetadataTypesInAnyGroup(
    businessDynamicFieldsLayout: DynamicFieldsLayout,
  ) {
    return businessDynamicFieldsLayout.groups
      .filter((group) =>
        businessDynamicFieldsLayout.views.some((view) =>
          view.groups.includes(group.name),
        ),
      )
      .reduce((acc: string[], curr) => {
        const fieldNames = curr.fields.map((x) => x.metadataTypeName);
        return [...acc, ...fieldNames];
      }, []);
  }

  static getMetadataFieldsWithNames(
    metadataTypeFields: MetadataFieldWithProps[],
    names: string[] | undefined | null,
  ) {
    return metadataTypeFields.filter((metadataField) => {
      return (names ?? []).includes(metadataField.metadataType.name);
    });
  }

  static getTimeboxedMetadataMappedData(
    metadataType: MetadataType,
    employmentMetadata: EmploymentMetadata[],
  ): MetadataTimeboxedField {
    return {
      metadataType,
      timeboxedData: employmentMetadata.filter(
        (meta) => meta.metadataTypeId === metadataType.id,
      ),
    };
  }

  // Check if a metadata type is included in a visible group (which is also included in a view)
  static isMetadataTypeVisibleInGroup = (
    metadataType: MetadataType,
    groups: DynamicFieldsLayoutGroup[] = [],
    views: DynamicFieldsLayoutViewInput[] = [],
  ) => {
    return groups.some((group) => {
      const isGroupInAView = views.some((view) =>
        view.groups.includes(group.name),
      );
      return (
        isGroupInAView &&
        group.fields.some(
          (field) => field.metadataTypeName === metadataType.name,
        )
      );
    });
  };

  static metadataTypeShouldBeVisible = (metadataType: MetadataType) => {
    return metadataType.required && !metadataType.internalAccess;
  };
}

export type MetadataTimeboxedField = {
  metadataType: MetadataType;
  timeboxedData: EmploymentMetadata[];
};

export type MetadataFieldWithProps = Record<string, unknown> & {
  metadataType: MetadataType;
  field: {
    fieldKey: string;
    md: number;
    lg: number;
    label: string;
    tooltipText: string;
    description?: string | undefined;
  };
};
